import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreModule} from '../core/core.module';
import {RouterModule} from '@angular/router';
import {Principal} from '../core/auth/principal.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from '../core/auth/auth.interceptor';
import {ServicesModule} from '../shared/services/services.module';
import {SharedComponentsModule} from '../shared/components/components.module';
import {AppComponent} from './app.component';
import {MainComponent} from './main.component';
import {appRoutes} from './app.route';
import {MaskSelectComponent} from './mask-select.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    MaskSelectComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    ServicesModule,
    SharedComponentsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(
      appRoutes,
      {
    useHash: true,
    relativeLinkResolution: 'legacy'
}
    )
  ],
  providers: [
    Principal,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
