import { Directive, ElementRef, HostListener, Input } from

'@angular/core';

@Directive({
  selector: '[isEllipsisActive]',
  exportAs: 'outOfBound'
})
export class IsEllipsisActiveDirective {
  @Input('isEllipsisActive') outOfBound = false;

  constructor(private elementRef: ElementRef) {}

  @HostListener('mouseenter')
  onMouseEnter(): void {
    setTimeout(() => {
      const element = this.elementRef.nativeElement;
      if (element.parentElement.offsetWidth < element.parentElement.scrollWidth) { //no need for .parentElement in most cases, here it is only because of MatCheckbox label wrapping
        this.outOfBound = false;
      }
      else {
        this.outOfBound = true;
      }
    }, 0);
  }
}
