import {KeyLabel} from '../key-label.model';
import {SortUtils} from '../../utils/sort-utils';

export enum ReportMetric {

  // general
  HOUR = 'HOUR',
  YEAR = 'YEAR',
  TAXONOMY = 'TAXONOMY',
  DEVICE_PLACEMENT = 'DEVICE_PLACEMENT',
  ACTIVITY_STATUS = 'ACTIVITY_STATUS',
  DEMAND_PARTNER = 'DEMAND_PARTNER',
  PUBLISHER = 'PUBLISHER',
  PUBLISHER_CODE = 'PUBLISHER_CODE',
  SITE = 'SITE',
  CONTENT_TYPE = 'CONTENT_TYPE',
  WIDGET_TYPE = 'WIDGET_TYPE',
  WIDGET_SIZE = 'WIDGET_SIZE',
  WIDGET = 'WIDGET',
  MONTH = 'MONTH',
  DAY = 'DAY',
  IO_CPM = 'IO_CPM',
  BIDDER = 'BIDDER',
  AD_UNIT = 'AD_UNIT',
  BRIDGE_AD_UNIT_CREATION_DATE = 'BRIDGE_AD_UNIT_CREATION_DATE',
  NETWORK_REVENUE = 'NETWORK_REVENUE',
  NETWORK_MARGIN = 'NETWORK_MARGIN',
  DFP_AD_REQUESTS_COST = 'DFP_AD_REQUESTS_COST',
  DFP_VIEWABLE_IMPRESSIONS_RATE = 'DFP_VIEWABLE_IMPRESSIONS_RATE',
  PARTNER = 'PARTNER',
  PUBLISHER_STATUS = 'PUBLISHER_STATUS',
  AD_UNIT_STATUS = 'AD_UNIT_STATUS',
  AD_UNIT_ACTIVITY_STATUS = 'AD_UNIT_ACTIVITY_STATUS',
  S2S = 'S2S',
  DAY_OF_WEEK = 'DAY_OF_WEEK',
  UNIT_CONFIG_TYPE = 'UNIT_CONFIG_TYPE',
  ADHESION = 'ADHESION',
  UNIT_BUILD_ON = 'UNIT_BUILD_ON',

  // KPI
  ECPM = 'ECPM',
  AD_IMPRESSION_RATIO = 'AD_IMPRESSION_RATIO',
  RCPM = 'RCPM',
  MARGIN = 'MARGIN',
  FILLED = 'FILLED',
  REFRESH_RATE = 'REFRESH_RATE',

  // stats
  UNIT_IMPRESSIONS = 'UNIT_IMPRESSIONS',
  PIXEL_IMPRESSIONS = 'PIXEL_IMPRESSIONS',
  DFP_DOWNLOADED_IMPRESSIONS = 'DFP_DOWNLOADED_IMPRESSIONS',
  FILLED_IMPRESSIONS = 'FILLED_IMPRESSIONS',
  AD_REQUESTS = 'AD_REQUESTS',
  WIDGET_REQUESTS = 'WIDGET_REQUESTS',
  EBDA_IMPRESSIONS = 'EBDA_IMPRESSIONS',
  UNIT_CONFIG_MAX_REFRESH = 'UNIT_CONFIG_MAX_REFRESH',
  UNIT_CONFIG_REFRESH_RATE = 'UNIT_CONFIG_REFRESH_RATE',
  UNIT_CONFIG_VIEW_IN_MAX_REFRESH = 'UNIT_CONFIG_VIEW_IN_MAX_REFRESH',
  UNIT_CONFIG_VIEW_IN_REFRESH_RATE = 'UNIT_CONFIG_VIEW_IN_REFRESH_RATE',
  UNIT_CONFIG_VIEW_OUT_MAX_REFRESH = 'UNIT_CONFIG_VIEW_OUT_MAX_REFRESH',
  UNIT_CONFIG_VIEW_OUT_REFRESH_RATE = 'UNIT_CONFIG_VIEW_OUT_REFRESH_RATE',
  CONSTANT_BID = 'CONSTANT_BID',
  OVERBID = 'OVERBID',

  // discrepancy
  REVENUE_DISCREPANCY = 'REVENUE_DISCREPANCY',
  REVENUE_DISCREPANCY_AMOUNT = 'REVENUE_DISCREPANCY_AMOUNT',
  AD_REQUEST_DISCREPANCY = 'AD_REQUEST_DISCREPANCY',
  FILLED_DISCREPANCY = 'FILLED_DISCREPANCY',

  // financial
  PROFIT = 'PROFIT',
  PUBLISHER_REVENUE = 'PUBLISHER_REVENUE',
  REVENUE = 'REVENUE',
  BIDDER_COST = 'BIDDER_COST',
  EBDA_REVENUE = 'EBDA_REVENUE',
  PASSBACK_REVENUE = 'PASSBACK_REVENUE',
  PUBLISHER_SHARE_REVENUE = 'PUBLISHER_SHARE_REVENUE',
  PUBLISHER_IO_REVENUE = 'PUBLISHER_IO_REVENUE',
  REVENUE_SHARE_PERCENT = 'REVENUE_SHARE_PERCENT',
  NET_PROFIT = 'NET_PROFIT',
  PARTNER_PROFIT = 'PARTNER_PROFIT',
  PARTNER_AD_SERVING_FEE = 'PARTNER_AD_SERVING_FEE',
  PARTNER_NET_PROFIT = 'PARTNER_NET_PROFIT',
  RCOST_RATIO = 'RCOST_RATIO',

  // engagement
  PLAYS = 'PLAYS',
  CLICKS = 'CLICKS',
  IN_VIEW_ADX = 'IN_VIEW_ADX',
  IN_VIEW_DFP = 'IN_VIEW_DFP',
  DISPLAY_DURATION_RELATIVE = 'DISPLAY_DURATION_RELATIVE',
  DISPLAY_DURATION = 'DISPLAY_DURATION',
  VISIBILITY = 'VISIBILITY',

  // exchange
  EX_DOMAIN = 'EX_DOMAIN',
  // EX_REQUESTS_COOKIE = 'EX_REQUESTS_COOKIE',
  EX_REVENUE = 'EX_REVENUE',
  EX_FILLED_IMPRESSIONS = 'EX_FILLED_IMPRESSIONS',
  EX_REQUESTS = 'EX_REQUESTS',
  EX_DP_REQUESTS = 'EX_DP_REQUESTS',
  EX_DP_BIDS = 'EX_DP_BIDS',
  EX_DP_REQUESTS_COOKIE = 'EX_DP_REQUESTS_COOKIE',
  EX_DP_REQUESTS_ERR = 'EX_DP_REQUESTS_ERR',
  // EX_FIRST_IMPRESSION_REVENUE = 'EX_FIRST_IMPRESSION_REVENUE',
  // EX_OVERBID_REVENUE = 'EX_OVERBID_REVENUE',
  EX_OVERBID_COST = 'EX_OVERBID_COST',
  EX_CONSTANT_BID_REVENUE = 'EX_CONSTANT_BID_REVENUE',
  EX_WON_BIDS = 'EX_WON_BIDS',
  EX_LOST_BIDS = 'EX_LOST_BIDS',
  EX_BID_RATIO = 'EX_BID_RATIO',
  EX_WIN_RATIO = 'EX_WIN_RATIO',
  EX_LOST_RATIO = 'EX_LOST_RATIO',
  EX_BID_REQUESTS_RATIO = 'EX_BID_REQUESTS_RATIO',
  EX_BID_FILL_RATE = 'EX_BID_FILL_RATE',
  EX_RENDER_RATE = 'EX_RENDER_RATE',
  EX_RPM = 'EX_RPM',
  EX_BIDDER_FIRST_IMPRESSION_AD_REQUESTS = 'EX_BIDDER_FIRST_IMPRESSION_AD_REQUESTS',
  EX_BIDDER_FIRST_IMPRESSION_FILLED_IMPRESSIONS = 'EX_BIDDER_FIRST_IMPRESSION_FILLED_IMPRESSIONS',
  EX_BIDDER_FIRST_IMPRESSION_BIDS_WON = 'EX_BIDDER_FIRST_IMPRESSION_BIDS_WON',
  EX_BIDDER_FIRST_IMPRESSION_REVENUE = 'EX_BIDDER_FIRST_IMPRESSION_REVENUE',

// other
  USING_CONFIANT = 'USING_CONFIANT',

  // TS metrics
  TS_REVENUE = 'TS_REVENUE',
  TS_IMPRESSIONS = 'TS_IMPRESSIONS',
  TS_RPM = 'TS_RPM',
  TOTAL_REQUESTS = 'TOTAL_REQUESTS',
  TOTAL_REVENUE = 'TOTAL_REVENUE',
  TOTAL_UNIT_IMPRESSIONS = 'TOTAL_UNIT_IMPRESSIONS',
  TOTAL_FILLED_IMPRESSIONS = 'TOTAL_FILLED_IMPRESSIONS',
  TOTAL_RPM = 'TOTAL_RPM',
  TOTAL_ECPM = 'TOTAL_ECPM',
}

export enum ReportMetricFormat {
  STRING, NUMBER, DECIMAL, DATE_TIME, PERCENTAGE, PERCENTAGE_PLAIN, CURRENCY, BOOLEAN
}

export class ReportMetricDetails extends KeyLabel<ReportMetric> {
  field: string;
  _selected: boolean;
  description: string;
  format: ReportMetricFormat = ReportMetricFormat.STRING;

  constructor(key: ReportMetric, label: string, field: string, description?: string) {
    super(key, label);
    this.field = field;
    this.description = description;
  }

  ofFormat(type: ReportMetricFormat): ReportMetricDetails {
    this.format = type;
    return this;
  }

}

export const ReportMetricsAll = () => [
  // general
  new ReportMetricDetails(ReportMetric.DAY, 'Day', 'day'),
  new ReportMetricDetails(ReportMetric.HOUR, 'Hour', 'time'),
  new ReportMetricDetails(ReportMetric.MONTH, 'Month', 'month'),
  new ReportMetricDetails(ReportMetric.YEAR, 'Year', 'year'),
  new ReportMetricDetails(ReportMetric.WIDGET, 'Widget', 'campaignId'),
  new ReportMetricDetails(ReportMetric.WIDGET_TYPE, 'Widget type', 'widgetName'),
  new ReportMetricDetails(ReportMetric.WIDGET_SIZE, 'Widget size', 'widgetSize'),
  new ReportMetricDetails(ReportMetric.CONTENT_TYPE, 'Content type', 'contentType'),
  new ReportMetricDetails(ReportMetric.AD_UNIT, 'Ad unit', 'adUnitName'),
  new ReportMetricDetails(ReportMetric.IO_CPM, 'IO CPM', 'ioCpm').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.SITE, 'Site', 'siteName'),
  new ReportMetricDetails(ReportMetric.PUBLISHER, 'Publisher', 'publisherName'),
  new ReportMetricDetails(ReportMetric.PUBLISHER_CODE, 'Publisher code', 'publisherCode'),
  new ReportMetricDetails(ReportMetric.DEMAND_PARTNER, 'Demand Partner', 'demandPartner'),
  new ReportMetricDetails(ReportMetric.ACTIVITY_STATUS, 'Status', 'activityStatus'),
  new ReportMetricDetails(ReportMetric.DEVICE_PLACEMENT, 'Device Placement', 'devicePlacement'),
  new ReportMetricDetails(ReportMetric.TAXONOMY, 'Taxonomy', 'taxonomy'),
  new ReportMetricDetails(ReportMetric.BIDDER, 'Bidder', 'bidder').ofFormat(ReportMetricFormat.BOOLEAN),
  new ReportMetricDetails(ReportMetric.PARTNER, 'Partner', 'partnerName').ofFormat(ReportMetricFormat.STRING),
  new ReportMetricDetails(ReportMetric.PUBLISHER_STATUS, 'Publisher Status', 'publisherStatus'),
  new ReportMetricDetails(ReportMetric.AD_UNIT_STATUS, 'Ad Unit Status', 'adUnitStatus'),
  new ReportMetricDetails(ReportMetric.AD_UNIT_ACTIVITY_STATUS, 'Ad Unit Activity Status', 'adUnitActivityStatus'),
  new ReportMetricDetails(ReportMetric.S2S, 'S2S', 's2s').ofFormat(ReportMetricFormat.BOOLEAN),
  new ReportMetricDetails(ReportMetric.DAY_OF_WEEK, 'Day of the Week', 'dayOfWeek'),
  new ReportMetricDetails(ReportMetric.UNIT_CONFIG_TYPE, 'Unit Config Type', 'unitConfigType'),
  new ReportMetricDetails(ReportMetric.ADHESION, 'Adhesion', 'adhesion').ofFormat(ReportMetricFormat.BOOLEAN),,
  new ReportMetricDetails(ReportMetric.UNIT_BUILD_ON, 'Latest Unit Config Build Date', 'unitBuildOn').ofFormat(ReportMetricFormat.DATE_TIME),

  // kpi
  new ReportMetricDetails(ReportMetric.MARGIN, 'Margin', 'margin', 'YL Profit / Total Revenue').ofFormat(ReportMetricFormat.PERCENTAGE),
  new ReportMetricDetails(ReportMetric.FILLED, 'Fill %', 'filled', 'Total Filled Impressions / Total Requests * 100').ofFormat(ReportMetricFormat.PERCENTAGE),
  new ReportMetricDetails(ReportMetric.RCPM, 'RCPM', 'rcpm', 'Revenue / Ad Requests * 1000').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.ECPM, 'ECPM', 'ecpm', 'Revenue / Filled Impressions * 1000').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.REFRESH_RATE, 'Refresh rate', 'refreshRate', 'Ad Requests / Impressions').ofFormat(ReportMetricFormat.DECIMAL),
  new ReportMetricDetails(ReportMetric.AD_IMPRESSION_RATIO, 'Ad Impression Ratio', 'adImpressionRatio').ofFormat(ReportMetricFormat.DECIMAL),

  // stats
  new ReportMetricDetails(ReportMetric.UNIT_IMPRESSIONS, 'Unit impressions', 'unitImpressions', 'Total number of Ads shown to the visitor (Rendered Ads)').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.AD_REQUESTS, 'Ad requests', 'adRequests', 'Total number of Ad Requests to Ad Server').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.EBDA_IMPRESSIONS, 'EBDA Impressions', 'dfpEbdaImpressions').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.WIDGET_REQUESTS, 'Widget requests', 'widgetRequests').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.FILLED_IMPRESSIONS, 'Filled impressions', 'filledImpressions', 'Total number of Ads (From DPs)').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.DFP_DOWNLOADED_IMPRESSIONS, 'Dfp dwnl. impressions', 'dfpDownloadedImpressions').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.PIXEL_IMPRESSIONS, 'Pixel impressions', 'pixels').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.DFP_VIEWABLE_IMPRESSIONS_RATE, 'DFP in view %', 'dfpViewableImpressionsRate', 'Metric imported from GAM').ofFormat(ReportMetricFormat.PERCENTAGE_PLAIN),
  new ReportMetricDetails(ReportMetric.DFP_AD_REQUESTS_COST, 'DFP ad requests cost', 'dfpAdRequestsCost', '$0.0000168 per 1 Ad Request').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.UNIT_CONFIG_MAX_REFRESH, 'Max Refresh (total)', 'unitConfigMaxRefresh', 'Regular ad unit’s Max Refresh for a single User session').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.UNIT_CONFIG_REFRESH_RATE, 'Refresh Rate (regular)', 'unitConfigRefreshRate', 'Refresh rate of Regular ad unit (how many seconds will Ad be on page before refresh)').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.UNIT_CONFIG_VIEW_IN_MAX_REFRESH, 'Max Refresh (in view)', 'unitConfigViewInMaxRefresh', 'Activefill ad unit’s Max Refresh while being in view').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.UNIT_CONFIG_VIEW_IN_REFRESH_RATE, 'Refresh Rate (in view)', 'unitConfigViewInRefreshRate', 'Activefill ad unit’s Refresh rate while being in view').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.UNIT_CONFIG_VIEW_OUT_MAX_REFRESH, 'Max Refresh (out of view)', 'unitConfigViewOutMaxRefresh', 'Activefill ad unit’s Max Refresh while being out of view').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.UNIT_CONFIG_VIEW_OUT_REFRESH_RATE, 'Refresh Rate (out of view)', 'unitConfigViewOutRefreshRate', 'Activefill ad unit’s Refresh rate while being out of view').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.CONSTANT_BID, 'Constant bid ($)', 'constantBid').ofFormat(ReportMetricFormat.PERCENTAGE_PLAIN),
  new ReportMetricDetails(ReportMetric.OVERBID, 'Overbid (%)', 'overbid').ofFormat(ReportMetricFormat.NUMBER),

  // financial
  new ReportMetricDetails(ReportMetric.REVENUE, 'Revenue', 'revenue', 'Total revenue generated, Gross revenue').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.PROFIT, 'YL profit', 'netRevenue', 'Network revenue - Partner profit - Reseller revenue share - Exchange overbid cost').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.PUBLISHER_REVENUE, 'Pub. revenue', 'publisherNetRevenue', 'Total Unit Impressions * IO CPM / 1000 + Total Revenue * Revenue share %').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.BIDDER_COST, 'Bidder cost', 'bidderCost').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.EBDA_REVENUE, 'EBDA Revenue', 'dfpEbdaRevenue').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.PASSBACK_REVENUE, 'Passback revenue', 'passbackRevenue').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.PUBLISHER_SHARE_REVENUE, 'Pub. Share revenue', 'revShareGross').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.PUBLISHER_IO_REVENUE, 'Pub. IO revenue', 'publisherGrossRevenue').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.REVENUE_SHARE_PERCENT, 'Revenue share %', 'revSharePercent', 'Configured on AU level, % that goes to Publisher').ofFormat(ReportMetricFormat.PERCENTAGE),
  new ReportMetricDetails(ReportMetric.NET_PROFIT, 'YL net profit', 'netProfit', 'YL Profit - DFP ad requests cost').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.PARTNER_PROFIT, 'Partner profit', 'partnerProfit', 'Network Revenue * Partner Revenue Share').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.PARTNER_AD_SERVING_FEE, 'Partner ad serving fee', 'partnerAdServingFee', 'Defined on Partner configuration (if applicable)').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.PARTNER_NET_PROFIT, 'Partner net profit', 'partnerNetProfit', 'Partner profit - Partner ad serving fee').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.RCOST_RATIO, 'RCost Ratio', 'rcostRatio', 'DFP ad requests cost / (Partner Profit + YL Profit)').ofFormat(ReportMetricFormat.PERCENTAGE),

  // engagement
  new ReportMetricDetails(ReportMetric.CLICKS, 'Clicks', 'clicks', 'Number of times user clicked on CTA button (i.e. "deezer button")').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.PLAYS, 'Plays', 'plays').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.IN_VIEW_DFP, 'In View DFP', 'inViewDfp').ofFormat(ReportMetricFormat.PERCENTAGE_PLAIN),
  new ReportMetricDetails(ReportMetric.IN_VIEW_ADX, 'In View ADX', 'inViewAdx').ofFormat(ReportMetricFormat.PERCENTAGE_PLAIN),
  new ReportMetricDetails(ReportMetric.DISPLAY_DURATION, 'Display Duration', 'displayDuration').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.DISPLAY_DURATION_RELATIVE, 'Display Duration Rel.', 'displayDurationRelative').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.VISIBILITY, 'Visibility', 'visibility').ofFormat(ReportMetricFormat.NUMBER),

  // discrepancy
  new ReportMetricDetails(ReportMetric.FILLED_DISCREPANCY, 'Filled discrepancy', 'filledDiscrepancy').ofFormat(ReportMetricFormat.PERCENTAGE),
  new ReportMetricDetails(ReportMetric.REVENUE_DISCREPANCY, 'Revenue discrepancy (%)', 'revenueDiscrepancy').ofFormat(ReportMetricFormat.PERCENTAGE),
  new ReportMetricDetails(ReportMetric.REVENUE_DISCREPANCY_AMOUNT, 'Revenue discrepancy amount', 'revenueDiscrepancyAmount').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.AD_REQUEST_DISCREPANCY, 'Ad request discrepancy', 'adRequestDiscrepancy').ofFormat(ReportMetricFormat.NUMBER),

  // exchange
  new ReportMetricDetails(ReportMetric.EX_DOMAIN, 'Exchange domain', 'exDomain').ofFormat(ReportMetricFormat.STRING),
  new ReportMetricDetails(ReportMetric.EX_REVENUE, 'Exchange revenue', 'exRevenue').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.EX_RPM, 'Exchange RPM', 'exRpm', 'Exchange revenue / Exchange impressions * 1000').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.EX_DP_BIDS, 'Exchange DP bids', 'exDpBids').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.EX_FILLED_IMPRESSIONS, 'Exchange filled impressions', 'exFilledImpressions').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.EX_REQUESTS, 'Exchange requests', 'exRequests').ofFormat(ReportMetricFormat.NUMBER),
  // new ReportMetricDetails(ReportMetric.EX_REQUESTS_COOKIE, 'Exchange req w/ user id', 'exRequestsCookie').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.EX_DP_REQUESTS, 'Exchange DP requests', 'exDpRequests').ofFormat(ReportMetricFormat.NUMBER),
  // new ReportMetricDetails(ReportMetric.EX_DP_REQUESTS_COOKIE, 'Exchange DP req w/ user id', 'exDpRequestsCookie').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.EX_DP_REQUESTS_ERR, 'Exchange DP requests err', 'exDpRequestsErr').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.EX_CONSTANT_BID_REVENUE, 'Exchange Constant Bid Revenue', 'exConstantBidRevenue').ofFormat(ReportMetricFormat.CURRENCY),
  //  new ReportMetricDetails(ReportMetric.EX_OVERBID_REVENUE, 'Exchange overbid Revenue', 'exOverbidRevenue').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.EX_OVERBID_COST, 'Exchange overbid cost', 'exOverbidCost').ofFormat(ReportMetricFormat.CURRENCY),
  // new ReportMetricDetails(ReportMetric.EX_FIRST_IMPRESSION_REVENUE, 'Exchange First Impression Revenue', 'exFirstImpressionRevenue').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.EX_WON_BIDS, 'Exchange won bids', 'exWonBids', 'Number of won DP bids').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.EX_LOST_BIDS, 'Exchange lost bids', 'exLostBids', 'Bid Responses - Won bids').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.EX_BID_RATIO, 'Exchange bid ratio', 'exBidRatio', 'Bid responses / Bid requests').ofFormat(ReportMetricFormat.PERCENTAGE),
  new ReportMetricDetails(ReportMetric.EX_WIN_RATIO, 'Exchange win ratio', 'exWinRatio', 'Won bids / Bid responses').ofFormat(ReportMetricFormat.PERCENTAGE),
  new ReportMetricDetails(ReportMetric.EX_LOST_RATIO, 'Exchange lost ratio', 'exLostRatio', 'Lost bids / Bid responses').ofFormat(ReportMetricFormat.PERCENTAGE),
  new ReportMetricDetails(ReportMetric.EX_BID_REQUESTS_RATIO, 'Exchange bid requests ratio', 'exBidRequestsRatio', 'Bid requests / Ad requests').ofFormat(ReportMetricFormat.PERCENTAGE),
  new ReportMetricDetails(ReportMetric.EX_BID_FILL_RATE, 'Exchange bid fill rate', 'exBidFillRate', 'Ad Impressions / Bid Requests').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.EX_RENDER_RATE, 'Exchange render rate (%)', 'exRenderRate', 'Filled impressions / Won bids * 100').ofFormat(ReportMetricFormat.PERCENTAGE),
  new ReportMetricDetails(ReportMetric.EX_BIDDER_FIRST_IMPRESSION_AD_REQUESTS, 'Exchange bidder 1st imp. requests', 'exBidderFirstImpressionAdRequests', '').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.EX_BIDDER_FIRST_IMPRESSION_FILLED_IMPRESSIONS, 'Exchange bidder 1st imp. filled impressions', 'exBidderFirstImpressionFilledImpressions', '').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.EX_BIDDER_FIRST_IMPRESSION_BIDS_WON, 'Exchange bidder 1st imp. won bids', 'exBidderFirstImpressionBidsWon', '').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.EX_BIDDER_FIRST_IMPRESSION_REVENUE, 'Exchange bidder 1st imp. revenue', 'exBidderFirstImpressionRevenue', '').ofFormat(ReportMetricFormat.CURRENCY),

// other
  new ReportMetricDetails(ReportMetric.USING_CONFIANT, 'Confiant', 'usingConfiant'),

  // TS metrics
  new ReportMetricDetails(ReportMetric.TS_REVENUE, 'TS Revenue', 'tagSequencerRevenue', 'Total revenue generated from Tag Sequencer').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.TS_IMPRESSIONS, 'TS Impressions', 'tagSequencerImpressions', 'Total number of Ads rendered from Tag Sequencer').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.TS_RPM, 'TS RPM', 'tagSequencerRpm', 'TS Revenue / TS Impressions * 1000').ofFormat(ReportMetricFormat.CURRENCY),

  // Total metrics
  new ReportMetricDetails(ReportMetric.TOTAL_REQUESTS, 'Total Requests', 'totalRequests', 'Ad Requests + Exchange Bidder 1st Imp. Requests').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.TOTAL_REVENUE, 'Total Revenue', 'totalRevenue', 'Revenue + TS Revenue').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.TOTAL_UNIT_IMPRESSIONS, 'Total Unit Impressions', 'totalUnitImpressions', 'Unit Impressions').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.TOTAL_FILLED_IMPRESSIONS, 'Total Filled Impressions', 'totalFilledImpressions', 'Filled Impressions + TS Impressions').ofFormat(ReportMetricFormat.NUMBER),
  new ReportMetricDetails(ReportMetric.TOTAL_RPM, 'Total RPM', 'totalRpm', 'Total Revenue / Total Impressions * 1000').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.TOTAL_ECPM, 'Total ECPM', 'totalEcpm', 'Total Revenue / Total Filled Impressions * 1000').ofFormat(ReportMetricFormat.CURRENCY),

  //csci
  new ReportMetricDetails(ReportMetric.NETWORK_REVENUE, 'Network revenue', 'networkRevenue', 'Total Revenue - Pub. revenue').ofFormat(ReportMetricFormat.CURRENCY),
  new ReportMetricDetails(ReportMetric.NETWORK_MARGIN, 'Network margin', 'networkMargin', 'Network revenue / Total Revenue'),

].sort(SortUtils.propertyComparatorString('label'));

export const ReportMetricsDetailsMap = (): { [key: string]: ReportMetricDetails } => ReportMetricsAll().reduce((prev, curr) => {
  prev[curr.key] = curr;
  return prev;
}, {});

export const ReportMetricLabels = Object.freeze(ReportMetricsAll().reduce((prev, curr) => {
  prev[curr.key] = curr.label;
  return prev;
}, {}));

