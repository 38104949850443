import {Component, Input} from '@angular/core';
import {LoginService} from '../../../core/login/login.service';
import {Router} from '@angular/router';

@Component({
  selector: 'cs-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {

  @Input()
  public pageTitle: string;
  @Input()
  public titleNext: string;
  @Input()
  public backBtnRouteUrl: string;
  // @Input()
  // public toolbarActions: boolean;
  // @Input()
  // public toolbarInfo: boolean;
  @Input()
  public toolbarClass: string;

  constructor(private loginService: LoginService, private router: Router) {
  }

  public logout() {
    this.loginService.logout(true);
  }

  goBackRoute() {
    this.router.navigateByUrl(this.backBtnRouteUrl);
  }

}
