import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Principal} from '../principal.service';
import {Observable} from 'rxjs';
import {RolePermissionService} from './role-permission.service';

@Injectable()
export class PermissionCanActivate implements CanActivate {

  constructor(private principal: Principal,
              private router: Router,
              private permissions: RolePermissionService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>
    | Promise<boolean>
    | boolean {
    const permissions = route.data['permissions'];
    console.log('permissions can activate:: %o', permissions);

    if (permissions) {
      const allowed = this.permissions.hasPermissions(permissions);
      if (!allowed) {
        console.log('forbidden route');
        this.router.navigateByUrl('/forbidden');
      }
      return allowed;
    }
    return true;
  }

}
