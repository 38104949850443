import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {LoginService} from './login.service';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../auth/user.model';
import {AuthService} from '../auth/auth.service';
import {SnackBarService} from '../services/snackbar.service';
import {GlobalSpinnerService} from '../services/global-spinner.service';
import {Principal} from '../auth/principal.service';
import { ServerErrorUtils } from 'src/app/shared/utils/server-error-utils';

@Component({
  selector: 'cs-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  @ViewChild('username') vc: ElementRef;

  user: User = new User();
  registerLinkVisible = false;
  private returnUrl: any | string;

  constructor(private loginService: LoginService,
              private router: Router,
              private route: ActivatedRoute,
              private authService: AuthService,
              private principal: Principal,
              private snackBarService: SnackBarService,
              private globalSpinnerService: GlobalSpinnerService) {
    // this.user = <User>{
    //   email: 'test@consumersciences.com',
    //   password: 'cstest',
    //   name: 'Test'
    // };
  }

  ngOnInit() {
    this.loginService.logout();
    this.route.queryParams.subscribe(next => {
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || null;
    });  }

  public login() {
    this.globalSpinnerService.showLoader();
    this.loginService.login(this.user)
      .then((user: User) => {
        this.navigate(['/dashboard']);
        this.globalSpinnerService.hideLoader();
      }).catch((error) => {
      console.error(error);
      this.globalSpinnerService.hideLoader();
      const messages = JSON.parse(error.error)
      if (messages) {
        this.snackBarService.error(messages.message);
      } else {
        this.snackBarService.error('Invalid credentials');
      }
    });
  }

  private navigate(location) {
    if (this.returnUrl) {
      this.router.navigateByUrl(this.returnUrl);
    } else {
      this.router.navigate(location);
    }
  }

}
