export class PageSortRequest {
  page?: number;
  size?: number;
  sortDirection?: string;
  sortProperty?: string;
}


export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}
