import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {Principal} from '../principal.service';
import {RolePermission, RolePermissionService} from './role-permission.service';

@Directive({
  selector: '[csHasPermission]'
})
export class HasPermissionDirective {

  private permissions: RolePermission[];

  constructor(private principal: Principal, private service: RolePermissionService,
              private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {
  }

  @Input()
  set csHasPermission(value: string | string[]) {
    this.permissions = typeof value === 'string' ? [<RolePermission> value] : <RolePermission[]> value;
    this.updateView();
  }

  private updateView(): void {
    this.viewContainerRef.clear();
    if (this.service.hasPermissions(this.permissions)) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
