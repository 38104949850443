import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Publisher} from '../../domain/publisher.model';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {PublisherListFilter, PublishersService} from '../../services/publishers.service';

@Component({
  selector: 'cs-publisher-multiselect-search',
  template: `
    <mat-form-field floatLabel="never" class="w-100">
      <input matInput placeholder="{{'Search publisher'}}"
             aria-label="Search"
             [formControl]="publisherFilterCtrl">
    </mat-form-field>
    <div class="publisher-multiselect-list-controls d-flex justify-content-between">
      <button mat-button color="primary" (click)="selectNone(); $event.stopPropagation();">
        Deselect all
      </button>
      <button mat-button color="primary" (click)="selectAll(); $event.stopPropagation();">
        Select all
      </button>
    </div>
    <div class="publisher-multiselect-list d-flex flex-column">
      <mat-checkbox
        *ngFor="let pub of filteredPublishers"
        (change)="onChange(pub, $event.checked) "
        [checked]="pub._selected"
        class="w-100 pb-0">
        {{pub.name}}
      </mat-checkbox>
    </div>
  `,
  styles: [`
    .publisher-multiselect-list {
      margin: 0 0 0 -1rem;
      padding: 1rem;
      width: auto;
      height: 200px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    ::ng-deep .dropdown .publisher-multiselect-list {
      margin: 0 -1rem;
      padding: 1rem 1rem 0;
      width: 250px;
    }
  `]
})
export class PublisherMultiselectSearchComponent implements OnInit, AfterViewInit {

  @Input()
  set selectedPublisherIds(selectedPublisherIds: string[]) {
    this.setSelectedPublisherIds(selectedPublisherIds);
  }

  _selectedPublisherIds: string[];

  @Output()
  selectionChange = new EventEmitter<Publisher[]>();

  @Output()
  selectionInitial = new EventEmitter<Publisher[]>();

  publishers: Publisher[] = [];
  filteredPublishers: Publisher[];
  publisherFilterCtrl = new FormControl();

  constructor(private service: PublishersService) {

  }

  ngOnInit() {
    this.service.listPublishers({size: 99999} as PublisherListFilter).subscribe(next => {
      this.publishers = next.content;
      this.reset();
      this.sortFilteredPublishers();
    });
  }

  ngAfterViewInit() {
    this.publisherFilterCtrl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(next => {
        if (next) {
          this.filteredPublishers = this.publishers.filter(p => p.name.toLowerCase().includes(next.toLowerCase()));
        } else {
          this.filteredPublishers = this.publishers.concat();
          this.sortFilteredPublishers();
        }
      });
  }

  onChange(pub, checked) {
    pub._selected = checked;
    // this._selectedPublisherIds = this.publishers.filter(p => p._selected).map(p => p.id);
    this.emit();
    // this.sortFilteredPublishers();
  }

  selectAll() {
    this.publishers.forEach(p => p._selected = true);
    this.emit();
    this.sortFilteredPublishers();
  }

  selectNone() {
    this.publishers.forEach(p => p._selected = false);
    this.emit();
    this.sortFilteredPublishers();
  }

  sortFilteredPublishers() {
    if (this.filteredPublishers) {
      this.filteredPublishers.sort((a1, a2) => {
        if (a1['_selected'] > a2['_selected']) { return -1; }
        if (a1['_selected'] < a2['_selected']) { return 1; }
        if (a1['name'].toLowerCase() < a2['name'].toLowerCase()) { return -1; }
        if (a1['name'].toLowerCase() > a2['name'].toLowerCase()) { return 1; }
      });
    }
  }

  reset() {
    this.setPublishersSelected(this._selectedPublisherIds);
    this.filteredPublishers = this.publishers.concat();
    this.selectionInitial.emit(this.getSelectedPublishers());
    this.publisherFilterCtrl.setValue(null);
    this.sortFilteredPublishers();
  }

  private emit() {
    this.selectionChange.emit(this.getSelectedPublishers());
  }

  private getSelectedPublishers() {
    return this.publishers.filter(p => p._selected);
  }

  setPublishersSelected(ids) {
    if (this.publishers) {
      this.publishers.forEach(p => p._selected = ids && ids.includes(p.id));
    }
  }

  setSelectedPublisherIds(ids) {
    this._selectedPublisherIds = ids;
    this.setPublishersSelected(this._selectedPublisherIds);
    this.sortFilteredPublishers();
  }

}
