<h3 class="mat-dialog-title">
  Demand partners
  <div class="row">
    <div class="col">
      <a target="_blank" class="" matTooltip="Edit ad unit" (click)="$event.stopPropagation()" [routerLink]="'/ad-units/edit/'+adUnit.id">
        <i class="far fa-share-square"></i>
      </a>
    </div>
  </div>
</h3>

<mat-dialog-content class="mt-n2">
  <mat-table #table [dataSource]="dataSource" matSort matSortActive="demandPartner" matSortDirection="asc">
    <ng-container matColumnDef="demandPartner">
      <mat-header-cell *matHeaderCellDef>Demand partner</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label">Name:</span>
        <span class="text-truncate">
        <strong>{{element.config.demandPartner}}</strong>
      </span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="domainStatuses">
      <mat-header-cell *matHeaderCellDef>Domain statuses</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label">Domain statuses:</span>
        <span class="hand" (click)="openDomainPreviewDialog(element.config.demandPartner)">{{element.approved}} / {{element.rejected}} / {{element.total}}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="adsTxtCondensed">
      <mat-header-cell *matHeaderCellDef>Ads.txt status</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label">Ads.txt:</span>
        <span *ngIf="element.adsTxtCondensed"><i class="far fa-check text-success"></i></span>
        <span *ngIf="!element.adsTxtCondensed"><i class="far fa-times text-danger"></i></span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label">Status:</span>
        {{ DemandPartnerApprovalStatusLabels[element.config.approvalStatus] }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="dpUnitId">
      <mat-header-cell *matHeaderCellDef>DP unit id</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label">DP unit id:</span>
        {{ element.config.dpUnitId }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="siteId">
      <mat-header-cell *matHeaderCellDef>Site id</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label">Site id:</span>
        {{ element.config.siteId }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="bidFloor">
      <mat-header-cell *matHeaderCellDef>Bid Floor</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label">Floor</span>
        {{ element.config.bidFloor | number : '1.2-2' }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="bidAdjustment">
      <mat-header-cell *matHeaderCellDef>Bid adjustment</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label">Bid adjustment</span>
        {{ element.config.bidAdjustment | number : '1.2-2' }}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns"></mat-row>
  </mat-table>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-center">
  <button mat-raised-button color="" class="mr-1" [mat-dialog-close]="false">Close</button>
</mat-dialog-actions>
