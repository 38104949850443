import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginService} from './login/login.service';
import {LoginComponent} from './login/login.component';
import {LogoutComponent} from './login/logout.component';
import {ForgotPasswordComponent} from './components/forgot-password.component';
import {ResetPasswordComponent} from './components/reset-password.component';
import {MaterialModule} from './material.module';
import {PageNotFoundComponent} from './components/page-not-found.component';
import {SnackBarService} from './services/snackbar.service';
import {GlobalSpinnerService} from './services/global-spinner.service';
import {GlobalSpinnerComponent} from './services/global-spinner.component';
import {HttpClientModule} from '@angular/common/http';
import {AuthGuard} from './guards/auth-guard.service';
import {CommonModule} from '@angular/common';
import {AdminGuard} from './guards/admin-guard.service';
import {PublisherGuard} from './guards/publisher-guard.service';
import {UserResolver} from './resolvers/user.resolver';
import {PublisherResolver} from './resolvers/publisher.resolver';
import {AppRoleGuard} from './guards/app-role-guard.service';
import {HasPermissionDirective} from './auth/rbac/has-permission.directive';
import {HasRolePermissionDirective} from './auth/rbac/has-role-permission.directive';
import {RolePermissionService} from './auth/rbac/role-permission.service';
import {PermissionCanActivate} from './auth/rbac/permission-can-activate.service';
import {RoleCanActivate} from './auth/rbac/role-can-activate.service';
import {RoleCanLoad} from './auth/rbac/role-can-load.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
  ],
  declarations: [
    LoginComponent,
    LogoutComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PageNotFoundComponent,
    GlobalSpinnerComponent,
    HasPermissionDirective,
    HasRolePermissionDirective
  ],
  providers: [
    LoginService,
    SnackBarService,
    AuthGuard,
    AppRoleGuard,
    UserResolver,
    PublisherResolver,
    AdminGuard,
    PublisherGuard,
    GlobalSpinnerService,
    RolePermissionService,
    PermissionCanActivate,
    RoleCanActivate,
    RoleCanLoad
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    LoginComponent,
    LogoutComponent,
    PageNotFoundComponent,
    GlobalSpinnerComponent,
    MaterialModule,
    HasPermissionDirective,
    HasRolePermissionDirective
  ],
  entryComponents: []
})
export class CoreModule {
}
