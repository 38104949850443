import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {SnackBarService} from '../../../../core/services/snackbar.service';
import {MatTableDataSource} from '@angular/material/table';
import {GlobalSpinnerService} from '../../../../core/services/global-spinner.service';
import {MatSort} from '@angular/material/sort';
import {AdUnit} from '../../../domain/ad-unit.model';
import {DemandPartnerApprovalStatusLabels} from 'src/app/shared/domain/ad-unit-demand-partner.model';
import {AdUnitsService} from '../../../services/ad-units.service';
import {AdUnitDpDetails} from '../../../domain/ad-unit-dp-details.model';
import {ChangeLogDialogComponent} from '../../../../modules/change-log/change-log-dialog/change-log-dialog.component';
import {AdUnitDomainAdsTxtPreviewDialogComponent} from '../ad-unit-domain-ads-txt-preview/ad-unit-domain-ads-txt-preview-dialog.component';

@Component({
  selector: 'cs-domain-ads-txt-details-preview-dialog',
  templateUrl: './ad-unit-dp-ads-txt-details-preview-dialog.component.html',
  styleUrls: ['./ad-unit-dp-ads-txt-details-preview-dialog.component.scss']
})
export class AdUnitDpAdsTxtDetailsPreviewDialogComponent implements AfterViewInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  adUnit: AdUnit;
  dataSource = new MatTableDataSource<AdUnitDpDetails>();
  columns = ['demandPartner', 'domainStatuses', 'adsTxtCondensed', 'status', 'dpUnitId', 'siteId', 'bidFloor', 'bidAdjustment'];
  DemandPartnerApprovalStatusLabels = DemandPartnerApprovalStatusLabels;

  constructor(public dialogRef: MatDialogRef<AdUnitDpAdsTxtDetailsPreviewDialogComponent>,
              public snackBarService: SnackBarService,
              private matDialog: MatDialog,
              private sbs: SnackBarService,
              private gss: GlobalSpinnerService,
              private adUnitService: AdUnitsService,
              @Inject(MAT_DIALOG_DATA) public data: { adUnit: AdUnit }) {
    this.adUnit = data.adUnit;
  }

  ngAfterViewInit() {
    this.dialogRef.updateSize('1200px');
    this.dataSource.sort = this.sort;
    this.adUnitService.getAdUnitDpDetails(this.adUnit.id).subscribe(next => {
      this.dataSource.data = next;
    });
  }

  openDomainPreviewDialog(demandPartnerName: string) {
    this.matDialog.open(AdUnitDomainAdsTxtPreviewDialogComponent, <MatDialogConfig>{
      data: {
        adUnit: this.adUnit,
        demandPartnerName: demandPartnerName
      }
    });
    console.log('Ad unit');
    console.log(this.adUnit);
  }

}
