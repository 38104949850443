import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {ReportsService} from './reports.service';
import {UsersService} from './users.service';
import {AdUnitsService} from './ad-units.service';
import {PublishersService} from './publishers.service';
import {PubDashServiceHttp} from './pub-dash-service-http.service';
import {NetworksService} from './networks.service';
import {ConfiantService} from './confiant.service';
import {WidgetTypesService} from './widget-types.service';
import {AdsTxtService} from './ads-txt.service';
import {DemandPartnersService} from './demand-partners.service';
import {FinancesService} from './finances.service';
import {ChangeLogService} from './change-log.service';
import {PartnersService} from './partners.service';
import {DashboardService} from './dashboard.service';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule
  ],
  providers: [
    ReportsService,
    UsersService,
    AdUnitsService,
    PubDashServiceHttp,
    PublishersService,
    NetworksService,
    ConfiantService,
    WidgetTypesService,
    DemandPartnersService,
    AdsTxtService,
    FinancesService,
    ChangeLogService,
    PartnersService,
    DashboardService
  ],
  exports: []
})
export class ServicesModule {
}
