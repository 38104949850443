import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {FinanceExpenses, FinanceExpensesFilter} from '../domain/finance-expenses.model';
import {Observable} from 'rxjs';
import {Page} from '../domain/page.model';
import {HttpUtils} from '../utils/http-utils';

@Injectable({
  providedIn: 'root',
})
export class FinancesService {

  constructor(private httpClient: HttpClient) {
  }

  expenses(filter: FinanceExpensesFilter): Observable<Page<FinanceExpenses>> {
    return this.httpClient.get<Page<FinanceExpenses>>(`${environment.serviceUrl}/expenses`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  createExpenses(expense: FinanceExpenses): Observable<FinanceExpenses> {
    return this.httpClient.post<FinanceExpenses>(`${environment.serviceUrl}/expenses`, expense);
  }

}
