import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthService} from '../auth/auth.service';
import {Principal} from '../auth/principal.service';
import {UserRole} from '../auth/user-role.model';
import {Observable} from 'rxjs';

@Injectable()
export class AppRoleGuard implements CanActivate {

  constructor(private authService: AuthService,
              private router: Router,
              private principal: Principal) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> {
    return new Observable(subscriber => {
      this.authService.identity()
        .subscribe(
          user => {
            const isMultiUser = this.principal.hasAuthority(UserRole.CS_ADMIN) && this.principal.hasAuthority(UserRole.CS_PUBLISHER);
            const isMultiPublisher = this.principal.hasAuthority(UserRole.CS_PUBLISHER) && this.principal.getUser().publisherIds.length > 1;

            const contextChosen = false; // todo load from cache
            if ((isMultiUser || isMultiPublisher) && !contextChosen) {
              // todo redirect to chooser
              subscriber.next(this.router.createUrlTree(['/chooser']));
            } else {
              subscriber.next(true);
            }
            subscriber.complete();
            return true;
          },
          (error) => {
            console.error(error);
            subscriber.next(false);
            subscriber.complete();
          }
        );
    });
  }
}
