import {AdsTxtStatus} from './adstxt-status.model';
import {KeyLabel} from './key-label.model';

export class PublisherDomain {
  id?: PublisherDomainId;
  lastUpdatedOn?: string;
  createdOn?: string;
  adsTxtRequiredRecords?: number;
  adsTxtMissingRecords?: number;
  confiantEnabled?: boolean;
  confiantPropertyId?: string;
  confiantWrapperId?: string;
  adstxtStatus?: AdsTxtStatus;
  status: PublisherDomainStatus;
  adsTxtCondensed?: boolean;
  activityStatus?: PublisherDomainActivityStatus;
  management?: PublisherDomainManagement;

  static of(publisherId: string, url: string) {
    const d = new PublisherDomain();
    d.id = new PublisherDomainId();
    d.id.publisherId = publisherId;
    d.id.url = url;
    return d;
  }
}

export class PublisherDomainId {
  url: string;
  publisherId: string;
}

export enum PublisherDomainStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED'
}

export const PublisherDomainStatusLabels = {
  ACTIVE: 'Active',
  ARCHIVED: 'Archived'
};

export const PublisherDomainStatusLabelKL = () =>
  (Object.keys(PublisherDomainStatusLabels).map(key => new KeyLabel<PublisherDomainStatus>
    (<PublisherDomainStatus>key, PublisherDomainStatusLabels[key])));

export enum PublisherDomainActivityStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export const PublisherDomainActivityStatusLabels = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
};

export enum PublisherDomainManagement {
  RESELLER = 'RESELLER',
  OWNED_AND_OPERATED = 'OWNED_AND_OPERATED'
}

export const PublisherDomainManagementLabels = {
  RESELLER: 'Reseller',
  OWNED_AND_OPERATED: 'Owned and Operated'
};

export const PublisherDomainManagementKL = () =>
  (Object.keys(PublisherDomainManagementLabels).map(key => new KeyLabel<PublisherDomainManagement>
  (<PublisherDomainManagement>key, PublisherDomainManagementLabels[key])));
