import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import _ from 'lodash';

@Component({
  selector: 'cs-report-name-template',
  templateUrl: './report-name-template.component.html',
  styleUrls: ['./report-name-template.component.scss']
})
export class ReportNameTemplateComponent implements OnInit {

  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredTags: Observable<string[]>;
  tags: string[] = [];
  allTags: string[] = ['Date', 'Time', 'Text'];
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @Input()
  fileNameText: string;
  @Output() fileNameTextChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  fileNameTemplate: string;
  @Output() fileNameTemplateChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => (tag ? this._filter(tag) : this.allTags.slice())),
    );
  }

  remove(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.allTags.push(this.tags[index]);
      this.tags.splice(index, 1);
      this.fileNameTemplateChanged();
    }
  }

  selected(event: MatAutocompleteSelectedEvent, el: HTMLInputElement): void {
    this.tags.push(event.option.viewValue);
    this.allTags = this.allTags.filter(item => item !== event.option.viewValue);
    this.tagCtrl.setValue(null);
    el.value = '';
    this.fileNameTemplateChanged();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allTags.filter(tag => tag.toLowerCase().includes(filterValue));
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.tags, event.previousIndex, event.currentIndex);
    this.fileNameTemplateChanged();
  }

  ngOnInit(): void {
    // Parse initial data in case of edit mode
    if (this.fileNameTemplate) {
      this.tags = this.fileNameTemplate.split('_').map(t => _.capitalize(t));
      this.allTags = _.difference(this.allTags, this.tags);
    }
  }

  fileNameTextChanged() {
    this.fileNameTextChange.emit(this.fileNameText);
  }

  fileNameTemplateChanged() {
    this.fileNameTemplate = this.tags.map(tag => tag.toLowerCase()).join('_');
    this.fileNameTemplateChange.emit(this.fileNameTemplate);
  }
}
