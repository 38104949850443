import {Injectable} from '@angular/core';
import {User} from './user.model';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Publisher} from '../../shared/domain/publisher.model';
import {UserRole} from './user-role.model';
import {Router, UrlTree} from '@angular/router';
import {PrincipalUtils} from './principal.utils';
import {PrincipalMask} from './principal-mask.model';

@Injectable({providedIn: 'root'})
export class Principal {

  userFromToken: User;
  public user: User;
  public publisher: Publisher;
  accessToken: string;

  mask: PrincipalMask = new PrincipalMask();

  constructor(private router: Router) {
  }

  setToken(token: string) {
    try {
      this.userFromToken = new JwtHelperService().decodeToken(token);
    } catch (e) {
      console.error('user could not be extracted from token');
    }
    this.accessToken = token;
  }

  getToken() {
    return this.accessToken;
  }

  cleanup() {
    console.log('principal cleanup');
    this.userFromToken = null;
    this.user = null;
    this.publisher = null;
    this.accessToken = null;
    this.setMask();
  }

  setUser(user: User) {
    this.user = user;
    console.log('setUser: %o', user);
    if (user) {
      const mask = localStorage.getItem('mask');
      if (mask) {
        console.log('mask found in local cache %s', mask);
        this.mask = JSON.parse(mask);
        this.validateMask();
      } else {
        console.log('no mask in local cache');
      }
    }
  }

  getUser() {
    return this.user;
  }

  hasAuthority(authority: string) {
    return this.hasAnyAuthority([authority]);
  }

  hasAnyAuthority(authorities: string[]) {
    if (!authorities || !this.user || !this.user.roles) {
      return false;
    }

    for (let i = 0; i < authorities.length; i++) {
      if (this.user.roles.indexOf(authorities[i]) !== -1) {
        return true;
      }
    }

    return false;
  }

  isAuthenticated() {
    return this.getToken() != null;
  }

  canAccess(app: 'admin' | 'publisher' | 'master'): boolean | UrlTree {
    if (!this.hasAnyAuthority(PrincipalUtils.MAIN_ROLES)) {
      alert('wrong user setup, please contact our support');
      return false;
    }

    if (!PrincipalUtils.isMasked(this)) {
      this.tryToSetDefaultMask();
    }

    if (!PrincipalUtils.APP_MAP[app].includes(this.mask.role)) {
      return this.router.createUrlTree([this.getRedirectPath()]);
    }

    return true;
  }

  setMask(role?: UserRole, publisherId?, partnerId?) {
    this.mask = new PrincipalMask();
    this.mask.role = role;
    this.mask.publisherId = publisherId;
    this.mask.partnerId = partnerId;
    localStorage.setItem('mask', JSON.stringify(this.mask));
    console.log('setMask=%s', JSON.stringify(this.mask));
  }

  private tryToSetDefaultMask() {
    console.log('tryToSetDefaultMask');
    const mainRoles = PrincipalUtils.filterMainRoles(this.user.roles);

    // set defaults
    if (mainRoles.length === 1) {
      const maskRole = <UserRole> mainRoles[0];
      switch (maskRole) {
        case UserRole.CS_PUBLISHER:
          if (this.user.publisherIds.length === 1) {
            this.setMask(maskRole, this.user.publisherIds[0]);
          }
          break;
        case UserRole.CS_PARTNER:
          if (this.user.partner && this.user.partner.id) {
            this.setMask(maskRole, null, this.user.partner.id);
          }
          break;
        case UserRole.CS_ADMIN:
          this.setMask(maskRole, null);
          break;
      }
    } else if (mainRoles.length === 2 && mainRoles.includes(UserRole.CS_ADMIN)) {
      this.setMask(UserRole.CS_ADMIN, null);
    }
  }

  getRedirectPath() {
    if (!PrincipalUtils.isMasked(this)) {
      return '/select';
    }
    switch (this.mask.role) {
      case UserRole.CS_ADMIN:
        return '/';
      case UserRole.CS_PUBLISHER:
        return `/p/${this.mask.publisherId}`;
    }
  }

  private validateMask() {
    const roleOk = this.user.roles.includes(this.mask.role);
    const pubOk = !this.mask.publisherId || this.user.publisherIds && this.user.publisherIds.includes(this.mask.publisherId);
    const partnerOk = !this.mask.partnerId || this.user.partnerIds && this.user.partnerIds.includes(this.mask.partnerId);
    if (!roleOk || !pubOk || !partnerOk) {
      this.mask = new PrincipalMask();
    }
  }

  isYappa() {
    return this.user.roles.includes(UserRole.CS_PARTNER)
      && this.user.partnerNames && this.user.partnerNames.includes('Yappa');
  }

  isNewsmaxTV() {
    return this.user.roles.includes(UserRole.CS_PARTNER)
      && this.user.partnerNames && this.user.partnerNames.includes('NewsmaxTV');
  }

  isRecrue() {
    return this.user.roles.includes(UserRole.CS_PARTNER)
      && this.user?.partner && this.user?.partner?.name === 'Recrue';
  }

  isPartner() {
    return this.user.roles.includes(UserRole.CS_PARTNER);
  }

  isAdmin() {
    return this.user.roles.includes(UserRole.CS_ADMIN);
  }
}
