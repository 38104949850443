export class KeyLabel<T = any> {

  key: T;
  label: string;

  constructor(key: T, label: string) {
    this.key = key;
    this.label = label;
  }
}
