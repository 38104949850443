import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'cs-cs-widget',
  template: `
    <div class="cs-widget mat-card" [ngClass]="{'minimized': minimized, 'expanded': expanded}">
      <div class="mat-card-header d-flex justify-content-between">
        <h4 class="mat-card-title">
          <ng-content select="cs-widget-title"></ng-content>
        </h4>
        <ng-content select="cs-widget-header-center"></ng-content>
        <div class="cs-card-controls">
          <ng-content select="cs-widget-actions"></ng-content>
          <div [hidden]="!menuVisible">
            <mat-menu #appMenu="matMenu">
              <button mat-menu-item (click)="minimize(false)" *ngIf="minimized">Maximise</button>
              <button mat-menu-item (click)="minimize(true)" *ngIf="!minimized">Minimize</button>
              <button mat-menu-item (click)="expand(false)" *ngIf="expanded">Collapse half width</button>
              <button mat-menu-item (click)="expand(true)" *ngIf="!expanded">Expand full width</button>
            </mat-menu>
            <button mat-icon-button disableRipple="true" [matMenuTriggerFor]="appMenu">
              <i class="far fa-ellipsis-v"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="cs-card-content">
        <ng-content select="cs-widget-content"></ng-content>
      </div>
      <div class="cs-card-footer">
        <ng-content select="cs-widget-footer"></ng-content>
      </div>
    </div>
  `,
  host: {
    '[class.cs-widget-expanded]': 'expanded'
  },
  styleUrls: ['./cs-widget.component.scss']
})
export class CsWidgetComponent {

  @ViewChild('contentWidget') contentWidget: ElementRef;

  @Output()
  resize: EventEmitter<VoidFunction> = new EventEmitter();

  @Input()
  minimized: boolean;

  @Input()
  menuVisible = true;

  @Input()
  expanded: boolean;

  constructor() {
  }

  minimize(minimize) {
    this.minimized = minimize;
    this.resize.emit();
  }

  expand(expand) {
    this.expanded = expand;
    this.resize.emit();
  }
}

@Component({
  selector: 'cs-widget-content',
  template: `
    <ng-content></ng-content>`,
  host: {'class': 'cs-widget-content'}
})
export class CsWidgetContentComponent {
}

@Component({
  selector: 'cs-widget-actions',
  template: `
    <ng-content></ng-content>`,
  host: {'class': 'cs-widget-actions'}
})
export class CsWidgetActionsComponent {
}

@Component({
  selector: 'cs-widget-footer',
  template: `
    <ng-content></ng-content>`,
  host: {'class': 'cs-widget-footer'}
})
export class CsWidgetFooterComponent {
}

@Component({
  selector: 'cs-widget-title',
  template: `
    <ng-content></ng-content>`,
  host: {'class': 'cs-widget-title d-flex flex-wrap'}
})
export class CsWidgetTitleComponent {
}
@Component({
  selector: 'cs-widget-header-center',
  template: `
    <ng-content></ng-content>`,
  host: {'class': 'cs-widget-header-center'}
})
export class CsWidgetHeaderCenterComponent {
}
export class CsWidgetSettings {
  minimized: boolean;
  expanded: boolean;

  constructor(minimized?: boolean, expanded?: boolean) {
    this.minimized = minimized;
    this.expanded = expanded;
  }
}
