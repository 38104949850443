import { KeyLabel } from '../../shared/domain/key-label.model';

export enum UserRole {
  CS_PUBLISHER = 'CS_PUBLISHER',
  CS_PARTNER = 'CS_PARTNER',
  CS_ADMIN = 'CS_ADMIN',
}

export const UserRoleLabels = {
  CS_PUBLISHER: 'Publisher',
  CS_PARTNER: 'Partner',
  CS_ADMIN: 'Admin',
};

export const UserRoleKL = () =>
  (Object.keys(UserRole).map(key => new KeyLabel(<UserRole>key, UserRoleLabels[key])));


export enum UserStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED'
}

export const UserStatusLabels = {
  ACTIVE: 'Active',
  ARCHIVED: 'Archived'
};

export const UserStatusKL = () =>
  (Object.keys(UserStatus).map(key => new KeyLabel(<UserStatus>key, UserStatusLabels[key])));
