import {Component, Inject, OnInit} from '@angular/core';
import {Publisher} from '../../domain/publisher.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cs-publisher-multiselect-search-dialog',
  template: `
    <h1 mat-dialog-title>Select publisher</h1>
    <div mat-dialog-content>
      <cs-publisher-multiselect-search
        #pubMultiselectSearch
        [selectedPublisherIds]="selectedPublisherIds"
        (selectionInitial)="selectedPublishers = $event"
        (selectionChange)="selectedPublishers = $event"
        class="cs-publisher-multiselect-search d-block">
      </cs-publisher-multiselect-search>
    </div>
    <div mat-dialog-actions class="d-flex justify-content-end">
      <button mat-button (click)="apply()">Apply</button>
      <button mat-button color="warn" mat-dialog-close cdkFocusInitial>Close</button>
    </div>
  `,
})
export class PublisherMultiselectSearchDialogComponent {

  selectedPublishers: Publisher[];
  selectedPublisherIds: string[];

  constructor(public dialogRef: MatDialogRef<PublisherMultiselectSearchDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  apply() {
    this.dialogRef.close(this.selectedPublishers);
  }

}
