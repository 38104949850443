import { Component, Input } from '@angular/core';

@Component({
  selector: 'cs-tooltip-icon',
  template: `
    <i class="fas fa-info-circle hand" [matTooltip]="text" *ngIf="text"></i>
  `,
})
export class TooltipIconComponent {

  @Input()
  public text: string;

}
