<h3 class="mat-dialog-title">
  {{adUnit.name}}<br />({{demandPartnerName}})
</h3>
<mat-dialog-content class="mt-n2">
  <mat-table #table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">
    <ng-container matColumnDef="domain">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Domain</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label">Domain</span>
        <span class="hand" (click)="updateComplianceStatus(element.url)" [ngClass]="determineClass(element)">
          {{element.url}}
        </span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="adsTxtMissingRecords">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Ads.txt details</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label">Ads.txt details</span>
        <span *ngIf="element.foundRecords?.length && !element.missingRecords?.length" class="text-success">Completed</span>
        <span *ngIf="!element.foundRecords?.length && !element.missingRecords?.length">
          <i class="far fa-times text-danger"></i>
        </span>
        <div *ngIf="element.missingRecords?.length">
          <span class="text-danger">Missing {{element.missingRecords.length}}</span>
          <span class="link ml-2" (click)="showAdsTxtDetails(element.url)">
            <i class="far fa-file-alt"></i>
          </span>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="adsTxtCondensed">
      <mat-header-cell *matHeaderCellDef>Condensed</mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell--has-sort">
        <span class="mobile-label">Condensed:</span>
        <span *ngIf="element.adsTxtCondensed"><i class="far fa-check text-success"></i></span>
        <span *ngIf="!element.adsTxtCondensed"><i class="far fa-times text-muted"></i></span>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columns sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns"></mat-row>
  </mat-table>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-center">
  <button mat-raised-button color="" class="mr-1" [mat-dialog-close]="false">Close</button>
</mat-dialog-actions>
