import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {CsTableColumn} from './cs-table-column.model';

@Component({
  selector: 'cs-mat-table-col-vis',
  template: `
		<div class="filter-dropdown">
			<button mat-mini-fab color="primary" (click)="opened = !opened" matTooltip="Columns"
			        class="mat-elevation-z0">
				<i class="fas fa-align-justify fa-rotate-90"></i>
			</button>
			<span class="dropdown" [class.opened]="opened">
        <h6 class="dropdown-title">{{header}}</h6>
        <span class="dropdown-content d-flex flex-column">
          <mat-checkbox color="primary" (change)="onChange($event.checked , true)" [checked]="selectAll">
            Select all
          </mat-checkbox>
          <mat-checkbox color="primary"
                        *ngFor="let col of _columns"
		          (change)="col.visible = $event.checked; onChange()"
		          [checked]="col.visible">
            {{col.header}}
          </mat-checkbox>
        </span>
      </span>
		</div>
  `,
  styleUrls: ['./mat-table-col-vis.component.scss']
})
export class MatTableColVisComponent implements OnInit {

  opened: boolean;
  @Input() public header: string;

  @Input('columns')
  set columns(columns: CsTableColumn[]) {
    this._columns = columns;
  }

  @Input('visibleColumns')
  set visibleColumns(visibleColumns: string[]) {
    if (this._columns && visibleColumns) {
      this._columns.forEach(c => c.visible = visibleColumns.includes(c.key));
    }
  }

  @Output()
  change = new EventEmitter<string[]>();

  _columns = [];
  selectAll;

  constructor(private _eref: ElementRef) {
  }

  ngOnInit(): void {
    this._columns.forEach(c => typeof c.visible === 'undefined' ? c.visible = true : null);
    // this.columns.sort(SortUtils.propertyComparatorString('header'));
    this.onChange(!this._columns.length);
  }

  close() {
    this.opened = false;
  }

  onChange($event?, selectAll?: boolean) {
    // this.columns.forEach(c => {
    //   c.visible = this.visibleColumns.includes(c.key);
    // });
    if (selectAll) {
      this._columns.map(c => c.visible = !!$event);
      // this._columns.map(c => c.visible = true);
    }
    this.selectAll = $event;
    setTimeout(() => {
      this.change.emit(this._columns.filter(c => c.visible).map(c => c.key));
    });
  }

  @HostListener('document:click', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    const insideWidget = this._eref.nativeElement.contains(e.target);
    const optionClick = (<Element>e.target).className === 'mat-option-text';
    const backdropClick = (<Element>e.target).className === 'cdk-overlay-backdrop';
    if (!insideWidget && !optionClick && !backdropClick) {
      this.opened = false;
    }
  }
}
