import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import {DateRangeType, DateRangeTypeLabelKV} from '../../domain/rb/date-range-type.enum';
import {DateRangeHolder} from '../../domain/rb/date-range-holder.model';
import {MomentDateUtils} from '../../utils/moment-date-utils';
import * as moment from 'moment';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY'
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'cs-date-range-chooser',
  template: `
    <div class="dateChooser">
      <div class="custom-date-container">
        <mat-form-field appearance="outline" class="w-100">
	        <mat-label>Date from</mat-label>
          <input matInput [max]="dateToPicker || today"
                 (dateChange)="dateFromChange($event)"
                 [matDatepicker]="dateFromPickerRef"
                 [(ngModel)]="dateFromPicker"
                 placeholder="">
          <mat-datepicker-toggle matSuffix [for]="dateFromPickerRef"></mat-datepicker-toggle>
          <mat-datepicker #dateFromPickerRef></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100">
	        <mat-label>Date to</mat-label>
          <input matInput [min]="dateFromPicker"
                 [max]="today"
                 (dateChange)="dateToChange($event)"
                 [matDatepicker]="dateToPickerRef"
                 [(ngModel)]="dateToPicker"
                 placeholder="">
          <mat-datepicker-toggle matSuffix [for]="dateToPickerRef"></mat-datepicker-toggle>
          <mat-datepicker #dateToPickerRef></mat-datepicker>
        </mat-form-field>
        <button mat-button color="primary" class="w-100"
                [hidden]="autoSubmitCustomDate"
                (click)="onSelect()">Select
        </button>
      </div>

      <div class="date-range-container">
        <button mat-button color="primary" class=""
                *ngFor="let dateRange of dateRangeTypes"
                [class.selected]="dateRangeType === dateRange.key"
                (click)="onRange(dateRange.key)">
          {{dateRange.value}}
        </button>
      </div>
    </div>
  `,
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class DateRangeChooserComponent implements OnInit {

  @Input()
  public dateFrom;
  @Input()
  public dateTo;
  @Input()
  public maxDate;
  @Input()
  private minDate;
  @Input()
  public allowedDateRangeTypes: DateRangeType[] = <DateRangeType[]>Object.keys(DateRangeType);
  @Input()
  autoSubmitCustomDate: boolean;
  @Input()
  public maxRangeBetweenDates: number;

  @Output()
  dateRangeChanged = new EventEmitter<DateRangeHolder>();

  today = MomentDateUtils.today();

  dateRangeType: DateRangeType;

  public dateRangeTypes = DateRangeTypeLabelKV();
  dateFromPicker: Date;
  dateToPicker: Date;

  constructor() {
  }

  ngOnInit(): void {
    const dateRangeTypeLabelKV = DateRangeTypeLabelKV();
    this.dateRangeTypes = this.allowedDateRangeTypes.map(t => dateRangeTypeLabelKV.find(v => v.key === t));
    this.dateRangeType = !this.dateFrom || !this.dateTo ? DateRangeType.TODAY : null;
    this.onRange(this.dateRangeType, false);
  }

  dateToChange(event: MatDatepickerInputEvent<Date>) {
    if (this.maxRangeBetweenDates) {
      this.checkForMaxRangeBetweenDates(event.value, 'dateTo');
    }

    if (this.autoSubmitCustomDate) {
      this.onSelect();
    }
  }

  dateFromChange(event: MatDatepickerInputEvent<Date>) {
    if (this.maxRangeBetweenDates) {
      this.checkForMaxRangeBetweenDates(event.value, 'dateFrom');
    }

    if (this.autoSubmitCustomDate) {
      this.onSelect();
    }
  }
  // prevent user to select range bigger than maxRangeBetweenDates
  checkForMaxRangeBetweenDates(date: Date, dateChanged: 'dateFrom' | 'dateTo') {
    const changedDateFormated = MomentDateUtils.format(date);
    const notChangedDateFormated = (dateChanged === 'dateFrom') ? MomentDateUtils.format(this.dateToPicker) : MomentDateUtils.format(this.dateFromPicker);
    const range = MomentDateUtils.getDurationInDays(changedDateFormated, notChangedDateFormated);
    if (range > this.maxRangeBetweenDates + 0.5) {
      if (dateChanged === 'dateFrom') {
        this.dateToPicker = moment(MomentDateUtils.nDaysFromDate(changedDateFormated, this.maxRangeBetweenDates)).toDate();
        this.dateTo = MomentDateUtils.nDaysFromDate(changedDateFormated, this.maxRangeBetweenDates);
      } else {
        this.dateFromPicker = moment(MomentDateUtils.nDaysFromDate(changedDateFormated, -this.maxRangeBetweenDates)).toDate();
        this.dateFrom = MomentDateUtils.nDaysFromDate(changedDateFormated, -this.maxRangeBetweenDates);
      }
    }
  }

  onSelect() {
    const dateFrom = MomentDateUtils.format(this.dateFromPicker, 'YYYY-MM-DD');
    const dateTo = MomentDateUtils.format(this.dateToPicker, 'YYYY-MM-DD');
    this.onCustomDateRangeSelected(dateFrom, dateTo);
  }

  onCustomDateRangeSelected(dateFrom, dateTo, emit = true) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.dateFromPicker = moment(this.dateFrom).toDate();
    this.dateToPicker = moment(this.dateTo).toDate();
    this.dateRangeType = null;
    if (emit) {
      this.dateRangeChanged.emit(new DateRangeHolder(this.dateFrom, this.dateTo, this.dateRangeType));
    }
  }

  onRange(type: DateRangeType, emit = true) {
    this.dateRangeType = type;
    const datesFromRange = MomentDateUtils.getDatesFromRange(type);
    if (datesFromRange) {
      this.dateFrom = datesFromRange.dateFrom;
      this.dateTo = datesFromRange.dateTo;
    }
    this.dateFromPicker = moment(this.dateFrom).toDate();
    this.dateToPicker = moment(this.dateTo).toDate();

    if (emit) {
      this.dateRangeChanged.emit(new DateRangeHolder(this.dateFrom, this.dateTo, type));
    }
  }

  goToNextDay() {
    const newDate = moment(this.dateTo).add(1, 'days').format('YYYY-MM-DD');
    this.onCustomDateRangeSelected(newDate, newDate);
  }

  isPrevDayEnabled() {
    return !this.minDate || this.minDate < this.dateFrom;
  }

  isNextDayEnabled() {
    return !this.maxDate || this.maxDate > this.dateTo;
  }

  goToPrevDay() {
    const newDate = moment(this.dateFrom).add(-1, 'days').format('YYYY-MM-DD');
    this.onCustomDateRangeSelected(newDate, newDate);
  }

  setDates(dates: DateRangeHolder) {
    if (dates.dateRangeType) {
      this.onRange(dates.dateRangeType, false);
    } else {
      this.onCustomDateRangeSelected(dates.dateFrom, dates.dateTo, false);
    }
  }
}

