import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {SortUtils} from '../../utils/sort-utils';
import {DemandPartner} from '../../domain/demand-partner.model';
import {DemandPartnersService} from '../../services/demand-partners.service';

@Component({
  selector: 'cs-demand-partner-autocomplete',
  template: `
    <mat-form-field [floatLabel]="floatLabel || 'always'" class="w-100" [ngClass]="formFieldClass" appearance="outline">
      <mat-label *ngIf="floatLabel != 'never'">Choose demand partner</mat-label>
      <input matInput
             #name
             aria-label="Demand partner"
             [matAutocomplete]="auto"
             [formControl]="filterCtrl"
             placeholder="{{floatLabel != 'never' ? 'Search' : 'Choose demand partner'}}">
      <i class="fas fa-spinner fa-spin" matSuffix [hidden]="!serverSearching"></i>
      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="optionSelected($event)"
                        [displayWith]="display">
        <mat-option *ngFor="let item of filteredItems | async" [value]="item">
          {{item.displayName}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>`
})
export class DemandPartnerAutocompleteComponent implements OnInit {

  @Input()
  public placeholder: string;

  @Input()
  public floatLabel: string;

  @Input()
  public formFieldClass: string;

  @Input()
  public demandPartnerName: string;

  @Input()
  public params: string;

  @Output()
  demandPartnerSelected = new EventEmitter<DemandPartner>();

  @ViewChild('name', {static: true}) nameFilter: ElementRef;

  filteredItems: Subject<DemandPartner[]>;
  filterCtrl = new FormControl();
  selectedItem;
  demandPartners: DemandPartner[] = [];
  filteredPartnersIds: string[] = [];

  serverSearching = false;

  constructor(private service: DemandPartnersService) {
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(next => {
        this.filteredItems.next([]);
        if (!next && this.selectedItem) {
          this.selectedItem = undefined;
          this.emit();
        }

        if (next && typeof next !== 'object') {
          this.serverSearching = true;
          this.filterCtrl.disable();
          this.demandPartners.length === 0 ? this.getDpDemandPartners() : this.searchDp();
        }
      });
  }

  ngOnInit(): void {
    if (this.demandPartnerName) {
      const dp = DemandPartner.of(this.demandPartnerName);
      this.selectedItem = dp;
      this.filterCtrl.setValue(dp);
      this.emit();
    }
  }

  getDpDemandPartners() {
    this.service.get(this.params || '').subscribe(
      response => {
        this.demandPartners = response;
        this.searchDp();
      },
      error => {
        console.log(error);
      }
    );
  }

  searchDp() {
    if (this.demandPartners.length > 0) {
      let search = this.demandPartners.filter(dp => dp.name.toLowerCase().includes(this.filterCtrl.value.toLowerCase()));
      search = search.filter(item => !this.filteredPartnersIds.includes(item.name));
      this.filteredItems.next(search.sort(SortUtils.propertyComparatorString('name')).splice(0, 10));
    }

    this.filterCtrl.enable();
    this.nameFilter.nativeElement.focus();
    this.serverSearching = false;
  }

  display(dp?: DemandPartner) {
    return dp ? dp.name : undefined;
  }

  optionSelected(dp) {
    this.selectedItem = dp.option.value;
    this.filteredItems.next([]);
    this.emit();
  }

  resetInput() {
    this.selectedItem = null;
    this.filterCtrl.setValue(null);
  }

  updateFilteredPartners(items) {
    this.filteredPartnersIds = items.map(item => item.name);
  }

  private emit() {
    this.demandPartnerSelected.emit(this.selectedItem);
  }
}
