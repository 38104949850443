import {KeyLabel} from '../key-label.model';
export enum ReportDataSource {
  DATE_PERIOD_DEPENDENT = 'DATE_PERIOD_DEPENDENT',
  DFP = 'DFP',
  DP = 'DP',
}
export const ReportDataSourceLabels = {
  DATE_PERIOD_DEPENDENT: 'Combined (DFP & DP)',
  DFP: 'DFP',
  DP: 'DP',
};

export const ReportDataSourceKL = () =>
  (Object.keys(ReportDataSourceLabels).map(
      key => new KeyLabel<ReportDataSource>(ReportDataSource[key], ReportDataSourceLabels[key]))
  );

