import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {User} from '../../core/auth/user.model';
import {Observable} from 'rxjs';
import {HttpUtils} from '../utils/http-utils';
import { Page } from '../domain/page.model';

@Injectable({
  providedIn: 'root',
})
export class UsersService {

  constructor(private httpClient: HttpClient) {
  }

  login(user: User) {
    return this.httpClient.post(`${environment.serviceUrl}/login`, user, {
      observe: 'response',
      responseType: 'text'
    });
  }

  forgotPassword(email: string) {
    return this.httpClient.post(`${environment.serviceUrl}/forgot-password`, {
      email
    });
  }

  me(): Observable<User> {
    return this.httpClient.get<User>(`${environment.serviceUrl}/users/me`);
  }

  list(filter: any, publisherId?: string): Observable<Page<User>> {
    return this.httpClient.get<Page<User>>(`${environment.serviceUrl}/users`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  exportUsers(filter: any, publisherId?: string): Observable<User[]> {
    return this.httpClient.get<User[]>(`${environment.serviceUrl}/users/export`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  create(u: User): Observable<User> {
    return this.httpClient.post<User>(`${environment.serviceUrl}/users`, u);
  }

  update(u: User): Observable<User> {
    return this.httpClient.put<User>(`${environment.serviceUrl}/users`, u);
  }

  archive(u: User): Observable<User> {
    return this.httpClient.put<User>(`${environment.serviceUrl}/users/${u.id}/archive`, {});
  }

  unarchive(u: User): Observable<User> {
    return this.httpClient.put<User>(`${environment.serviceUrl}/users/${u.id}/unarchive`, {});
  }

  confirmPasswordReset(password: PasswordResetData) {
    return this.httpClient.post(`${environment.serviceUrl}/forgot-password/confirm-password-reset`, password);
  }
}

export class PasswordResetData {
  confirmationId: string;
  newPassword: string;
  repeatPassword: string;
}

