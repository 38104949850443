export class DemandPartner {
  name: string;
  displayName: string;
  bidderName: string;
  lastSyncOn: string;
  bidderCode?: string;
  supportsDisplay?: boolean;
  supportsVideo?: boolean;

  static of(dpName: string) {
    const dp = new DemandPartner();
    dp.name = dpName;
    return dp;
  }
}
