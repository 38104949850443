import {Component, OnInit} from '@angular/core';
import {Principal} from '../core/auth/principal.service';
import {Publisher} from '../shared/domain/publisher.model';
import {PublishersService} from '../shared/services/publishers.service';
import {Router} from '@angular/router';
import {UserRole} from '../core/auth/user-role.model';
import {AuthService} from '../core/auth/auth.service';
import {NetworksService} from '../shared/services/networks.service';
import {SnackBarService} from '../core/services/snackbar.service';
import {LoginService} from '../core/login/login.service';

@Component({
  selector: 'cs-main',
  templateUrl: './mask-select.component.html',
  styleUrls: ['./mask-select.component.scss']
})
export class MaskSelectComponent implements OnInit {

  publishers: Publisher[] = [];

  loading = true;

  constructor(public principal: Principal,
              private router: Router,
              private sbs: SnackBarService,
              private authService: AuthService,
              private networkService: NetworksService,
              private service: PublishersService,
              private loginService: LoginService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.authService.identity().subscribe(() => {
      if (this.principal.user.publisherIds && this.principal.user.publisherIds.length) {
        this.service.getPublisherByIds(this.principal.user.publisherIds).subscribe(publishers => {
          this.publishers = publishers;
          this.loading = false;
        }, () => {
          this.sbs.error('Unexpected error occurred');
          this.loading = false;
        });
      }
    });
  }

  selectPublisher(publisherId: string) {
    this.principal.setMask(UserRole.CS_PUBLISHER, publisherId);
    this.router.navigateByUrl(`/p/${publisherId}`);
  }

  mainDashboard() {
    this.principal.setMask(UserRole.CS_ADMIN, null);
    this.router.navigateByUrl('/');
  }

  logout() {
    this.loginService.logout(true);
  }

}

