import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ClipboardService} from 'ngx-clipboard';
import {SnackBarService} from '../../../../core/services/snackbar.service';
import {MatTableDataSource} from '@angular/material/table';
import {PublisherDomain} from '../../../domain/publisher-domain.model';
import {GlobalSpinnerService} from '../../../../core/services/global-spinner.service';
import {PublishersService} from '../../../services/publishers.service';
import {DemandPartnerDbName} from '../../../domain/demand-partner.enum';
import {AdsTxtRecord} from '../../../domain/adstxt-record.model';
import {AdsTxtDiff} from '../../../domain/adstxt-diff';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'cs-domain-ads-txt-details-preview-dialog',
  templateUrl: './domain-ads-txt-details-preview-dialog.component.html',
  styleUrls: ['./domain-ads-txt-details-preview-dialog.component.scss']
})
export class DomainAdsTxtDetailsPreviewDialogComponent implements AfterViewInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  dataSource = new MatTableDataSource<AdsTxtRecord>();
  columns = ['domain', 'publisherAccountId', 'accountType', 'certAuthority', 'comment', 'demandPartnerName', 'condensed', 'priority',
    'ebda', 'primary', 'display', 'video', 'actions'];
  adsTxtUpdatedOn = '';
  demandPartnerDbName = DemandPartnerDbName;
  publisherDomain: PublisherDomain;
  adsTxtDiff: AdsTxtDiff;
  filterValues: Array<string>;
  filter = 'All';
  demandPartnerName = '';

  constructor(public dialogRef: MatDialogRef<DomainAdsTxtDetailsPreviewDialogComponent>,
              public snackBarService: SnackBarService,
              private clipboardService: ClipboardService,
              private sbs: SnackBarService,
              private gss: GlobalSpinnerService,
              private publisherService: PublishersService,
              private matDialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: {domain: PublisherDomain, demandPartnerName?: string, result: AdsTxtDiff }) {
    this.publisherDomain = data.domain;
    this.demandPartnerName = data.demandPartnerName ? data.demandPartnerName : '';
    this.adsTxtDiff = data.result;
    this.filterValues = ['All', 'Found', 'Not Found'];
    this.dataSource.data = [];
    this.dataSource.data.push(...this.adsTxtDiff.found);
    this.dataSource.data.push(...this.adsTxtDiff.notFound);
  }

  ngAfterViewInit() {
    this.dialogRef.updateSize('1200px');
    this.publisherService.getLastAdstxtScanTime().subscribe(dt => {
      this.adsTxtUpdatedOn = dt;
    });
    this.dataSource.sort = this.sort;
    const customFilterFn = (data: AdsTxtRecord, filter: string) => {
      if (this.demandPartnerName && this.demandPartnerDbName[data.demandPartnerName] !== this.demandPartnerName) {
        return false;
      }
      switch (filter) {
        case 'Not Found': return this.adsTxtDiff.notFound.includes(data);
        case 'Found': return this.adsTxtDiff.found.includes(data);
      }
      return true;
    };
    this.dataSource.filterPredicate = customFilterFn;
    this.filterData();
  }

  found(record: AdsTxtRecord) {
    return this.adsTxtDiff.found.includes(record) ? 'found' : 'notfound';
  }

  copyRecord(element: AdsTxtRecord) {
    let contentToCopy = '';
    let comment = '';
    if (element.comment) {
      comment = ' #' + element.comment;
    }
    contentToCopy += element.domain + ',' + element.publisherAccountId + ',' + element.accountType + comment;
    this.clipboardService.copyFromContent(contentToCopy);
    this.snackBarService.success('Copied to clipboard!');
  }

  copyRecords(records: AdsTxtRecord[]) {
    let contentToCopy = '';
    records.forEach(element => {
      let comment = '';
      if (element.comment) {
        comment = ' #' + element.comment;
      }
      contentToCopy += element.domain + ',' + element.publisherAccountId + ',' + element.accountType + comment + '\n';
    });
    this.clipboardService.copyFromContent(contentToCopy);
    this.snackBarService.success('Copied to clipboard!');
  }

  copyMissing() {
    this.copyRecords(this.adsTxtDiff.notFound);
  }

  copyAll() {
    const records = [];
    records.push(...this.adsTxtDiff.found);
    records.push(...this.adsTxtDiff.notFound);
    this.copyRecords(records);
  }

  filterData() {
    this.dataSource.filter = this.filter;
  }

}
