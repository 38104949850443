import {Component} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {User} from '../auth/user.model';
import {GlobalSpinnerService} from '../services/global-spinner.service';
import {SnackBarService} from '../services/snackbar.service';
import {UsersService} from '../../shared/services/users.service';

@Component({
  selector: 'cs-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  public user = new User();

  constructor(private httpClient: HttpClient,
              private router: Router,
              private service: UsersService,
              private gss: GlobalSpinnerService,
              private snackBarService: SnackBarService) {
  }

  public recover() {
    this.gss.showLoader();
    this.service.forgotPassword(this.user.email).subscribe(next => {
      this.gss.hideLoader();
      this.snackBarService.success('Reset instructions have been sent to email!');
      setTimeout(() => {
        this.router.navigate(['login']);
      }, 1000);
    }, () => {
      this.snackBarService.error('Unknown error occurred');
      this.gss.hideLoader();
    });
  }
}
