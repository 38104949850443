<mat-form-field class="w-100" floatLabel="always" appearance="outline">
  <mat-label>File name text</mat-label>
  <input name="fileNameText" matInput maxlength="50" [(ngModel)]="fileNameText" (change)="fileNameTextChanged()"/>
  <mat-hint align="end">*text to include in file name using tags</mat-hint>
</mat-form-field>
<mat-form-field class="chip-list" appearance="outline">
  <mat-label>Template name tags</mat-label>
  <mat-chip-list #chipList aria-label="Tag selection"
                 cdkDropList
                 cdkDropListOrientation="horizontal"
                 (cdkDropListDropped)="drop($event)">
    <mat-chip
      class="chip-box"
      cdkDrag
      *ngFor="let tag of tags"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="remove(tag)">
      {{tag}}
      <button matChipRemove *ngIf="removable" class="tag-remove">
        <i class="far fa-times"></i>
      </button>
    </mat-chip>
    <input
      placeholder="Choose tag..."
      #tagInput
      [formControl]="tagCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event, tagInput)">
    <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
      {{tag}}
    </mat-option>
  </mat-autocomplete>
  <mat-hint align="end">*file name format template, drag tags to reorder</mat-hint>
</mat-form-field>
