<h3 class="mat-dialog-title">
  {{adUnit.name}}
</h3>
<mat-dialog-content class="mt-n2">
  <mat-table #table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">
    <ng-container matColumnDef="domain">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Domain</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label">Domain</span>
        {{element.id.url}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="adsTxtMissingRecords">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Ads.txt details</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label">Ads.txt details</span>
        <span *ngIf="element.adsTxtRequiredRecords && !element.adsTxtMissingRecords" class="text-success">Completed</span>
        <span *ngIf="!element.adsTxtRequiredRecords && !element.adsTxtMissingRecords">No data</span>
        <div *ngIf="element.adsTxtMissingRecords">
          <span class="text-danger">Missing {{element.adsTxtMissingRecords}}</span>
          <span class="link ml-2" (click)="showAdsTxtDetails(element)">
            <i class="far fa-file-alt"></i>
          </span>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="adsTxtCondensed">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Condensed</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label">Condensed</span>
        <span *ngIf="element.adsTxtCondensed"><i class="far fa-check text-success"></i></span>
        <span *ngIf="!element.adsTxtCondensed"><i class="far fa-times text-danger"></i></span>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columns sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns"></mat-row>
  </mat-table>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-center">
  <button mat-raised-button color="" class="mr-1" [mat-dialog-close]="false">Close</button>
</mat-dialog-actions>
