import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {Publisher} from '../domain/publisher.model';
import {Page} from '../domain/page.model';
import {HttpUtils} from '../utils/http-utils';
import {PageSortRequest} from '../domain/page-sort-request.model';
import {PublisherDomain} from '../domain/publisher-domain.model';
import {Network} from '../domain/network.model';

@Injectable({
  providedIn: 'root',
})
export class NetworksService {

  constructor(private httpClient: HttpClient) {
  }

  getNetworksByIds(ids: string[]): Observable<Network[]> {
    return this.httpClient.get<Network[]>(`${environment.serviceUrl}/networks/get-by-ids`, {
      params: HttpUtils.getHttpParams({
        networkIds: ids
      })
    });
  }

}

