import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {MomentDateUtils} from '../../utils/moment-date-utils';
import {DateRangeType} from '../../domain/rb/date-range-type.enum';
import {ReportMetric, ReportMetricsAll} from '../../domain/rb/report-metric.enum';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {DateRangeHolder} from '../../domain/rb/date-range-holder.model';
import {PublisherMultiselectSearchDialogComponent} from '../utils/publisher-multiselect-search-dialog.component';
import {WidgetDailyMetricsChartSettingsDialogComponent} from './widget-daily-metrics-chart-settings-dialog.component';
import {DailyMetricsChartComponent} from './daily-metrics-chart/daily-metrics-chart.component';
import {Principal} from '../../../core/auth/principal.service';

@Component({
  selector: 'cs-widget-daily-metrics-chart',
  templateUrl: './widget-daily-metrics-chart.component.html',
  styleUrls: ['./widget-daily-metrics-chart.component.scss']
})
export class WidgetDailyMetricsChartComponent implements AfterViewInit {

  @ViewChild('dailyChart') dailyChart: DailyMetricsChartComponent;

  @Input()
  menuVisible = false;

  get checkPartnerRole() {
    return this.principalService.isPartner();
  }

  loading = false;

  chartTypes = {
    DAILY_STATS: 'Daily stats',
    HOURLY_STATS: 'Hourly stats',
    BIDDER: 'Bidder / Non-bidder'
  };
  selectedChartType = this.chartTypes.DAILY_STATS;
  selectedPublisherIds = [];

  allowedDateRangeTypes = [
    DateRangeType.TODAY,
    DateRangeType.YESTERDAY,
    DateRangeType.LAST_30_DAYS,
    DateRangeType.LAST_7_DAYS,
    DateRangeType.THIS_WEEK,
    DateRangeType.THIS_MONTH,
  ];

  dailySettings = {
    selectedMetrics: [],
    availableMetricsDaily: [
      ReportMetric.AD_REQUESTS,
      ReportMetric.PROFIT,
      ReportMetric.REFRESH_RATE,
      ReportMetric.FILLED,
      ReportMetric.MARGIN,
      ReportMetric.TOTAL_RPM,
    ],
    dateRange: MomentDateUtils.getDatesFromRange(DateRangeType.LAST_30_DAYS),
    loading: (value) => {
      setTimeout(() => {
        this.loading = value;
      }, 0);
    }
  };

  hourlySettings = {
    selectedMetrics: [
      ReportMetric.UNIT_IMPRESSIONS,
      ReportMetric.REVENUE
    ],
    availableMetricsHourly: [
      ReportMetric.UNIT_IMPRESSIONS,
      ReportMetric.REVENUE,
      ReportMetric.REFRESH_RATE,
      ReportMetric.MARGIN,
    ],
    date: MomentDateUtils.today(),
    loading: (value) => {
      setTimeout(() => {
        this.loading = value;
      }, 0);
    }
  };

  bidderSettings = {
    selectedMetric: ReportMetric.UNIT_IMPRESSIONS,
    availableMetrics: [
      ReportMetric.UNIT_IMPRESSIONS,
      ReportMetric.REVENUE,
      ReportMetric.MARGIN,
      ReportMetric.REFRESH_RATE,
      ReportMetric.TOTAL_RPM
    ],
    availableMetricsDetails: [],
    dateRange: MomentDateUtils.getDatesFromRange(DateRangeType.LAST_30_DAYS),
    loading: (value) => {
      setTimeout(() => {
        this.loading = value;
      }, 0);
    }
  };

  constructor(private matDialog: MatDialog,
              private principalService: Principal) {
    this.bidderSettings.availableMetricsDetails = ReportMetricsAll().filter(m => this.bidderSettings.availableMetrics.includes(m.key));
    if (this.checkPartnerRole) {
      this.dailySettings.availableMetricsDaily = this.dailySettings.availableMetricsDaily.concat([ReportMetric.UNIT_IMPRESSIONS, ReportMetric.REVENUE]);
      this.dailySettings.selectedMetrics = [ReportMetric.UNIT_IMPRESSIONS, ReportMetric.REVENUE];
    } else {
      this.dailySettings.availableMetricsDaily = this.dailySettings.availableMetricsDaily.concat([ReportMetric.TOTAL_REQUESTS, ReportMetric.TOTAL_UNIT_IMPRESSIONS, ReportMetric.TOTAL_REVENUE]);
      this.dailySettings.selectedMetrics = [ReportMetric.TOTAL_REQUESTS, ReportMetric.TOTAL_UNIT_IMPRESSIONS, ReportMetric.TOTAL_REVENUE];
    }
  }

  ngAfterViewInit() {
    this.refreshData();
  }

  setDateRange(dateFrom: string, dateTo: string) {
    this.dailySettings.dateRange.dateFrom = dateFrom;
    this.dailySettings.dateRange.dateTo = dateTo;
    if (this.dailyChart) {
      this.refreshData();
    }
  }

  // Date selector for all diagrams
  onDailyDateRangeChanged(dateRange: DateRangeHolder) {
    this.dailySettings.dateRange = dateRange;
  }

  onHourlyDateSelected(date) {
    this.hourlySettings.date = date;
  }

  onBidderDateRangeChanged(dateRange: DateRangeHolder) {
    this.bidderSettings.dateRange = dateRange;
  }
  // the same metric selector is used for daily and hourly charts
  metricsSelectionChange(metrics: ReportMetric[]) {
    if (this.selectedChartType === this.chartTypes.DAILY_STATS) {
      this.dailySettings.selectedMetrics = metrics;
      this.refreshData();
    } else if (this.selectedChartType === this.chartTypes.HOURLY_STATS) {
      this.hourlySettings.selectedMetrics = metrics;
    }
  }

  onBidderMetricSelectionChanged(metric: ReportMetric) {
    this.bidderSettings.selectedMetric = metric;
  }

  refreshData() {
    if (this.selectedChartType === this.chartTypes.DAILY_STATS) {
      this.dailyChart.loadChartData();
    } else if (this.selectedChartType === this.chartTypes.HOURLY_STATS) {
      // this.hourlyChart.loadChartData();
    } else if (this.selectedChartType === this.chartTypes.BIDDER) {
      // this.bidderChart.loadChartData();
    }
  }

  openPublisherFilterDialog() {
    this.matDialog.open(PublisherMultiselectSearchDialogComponent, {
      data: {
        selectedPublisherIds: this.selectedPublisherIds
      },
      width: '400px'
    }).afterClosed().subscribe(next => {
      if (next) {
        this.selectedPublisherIds = next.map(p => p.id);
        // this.refreshData();
      }
    });
  }

  openSettings() {
    this.matDialog.open(WidgetDailyMetricsChartSettingsDialogComponent, <MatDialogConfig>{
      data: {
        chartTypes: this.chartTypes,
        selectedChartType: this.selectedChartType
      },
      width: '300px',
    }).afterClosed().subscribe(next => {
      if (next && next !== this.selectedChartType) {
        this.selectedChartType = next;
      }
    });
  }

}
