import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Principal} from '../principal.service';
import {Observable} from 'rxjs';

@Injectable()
export class RoleCanActivate implements CanActivate {

  constructor(private principal: Principal) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>
    | Promise<boolean>
    | boolean {
    const roles = route.data['roles'];
    console.log('role can activate:: %o', roles);
    if (roles) {
      const allowedRole = this.principal.hasAnyAuthority(roles);
      if (!allowedRole) {
        console.log('forbidden route');
      }
      return allowedRole;
    }
    return true;
  }

}
