import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PublisherDomainsComplianceModel} from '../../../../../shared/domain/publisher-compliance.model';
import {ComplianceService} from '../../../../../shared/services/compliance.service';
import {take} from 'rxjs/operators';
import {ServerErrorUtils} from '../../../../../shared/utils/server-error-utils';
import {SnackBarService} from '../../../../../core/services/snackbar.service';
import {MatTableDataSource} from '@angular/material/table';
import {
  DpDomainComplianceModel,
  DpDomainComplianceStatus,
  DpDomainComplianceStatusKL
} from '../../../../../shared/domain/demand-partner-compliance.model';
import {GlobalSpinnerService} from '../../../../../core/services/global-spinner.service';
import {AdsTxtDiff} from '../../../../../shared/domain/adstxt-diff';
import {environment} from '../../../../../../environments/environment';
import {Utils} from '../../../../../shared/utils/utils';
import {PublishersService} from '../../../../../shared/services/publishers.service';
import {HttpClient} from '@angular/common/http';
import {PublisherDomain} from '../../../../../shared/domain/publisher-domain.model';
import {DemandPartnerDbName, DemandPartnerDbNameList} from '../../../../../shared/domain/demand-partner.enum';

@Component({
  selector: 'cs-dp-update-compliance-status-dialog',
  templateUrl: './dp-update-compliance-status-dialog.component.html',
  styleUrls: ['./dp-update-compliance-status-dialog.component.scss']
})
export class DpUpdateComplianceStatusDialogComponent implements OnInit, AfterViewInit {

  dataSource = new MatTableDataSource<DpDomainComplianceModel>();
  dpComplianceApproval: DpDomainComplianceModel[];
  adstxtDiff: AdsTxtDiff;
  loading = false;
  columns = ['demandPartnerName', 'status', 'adstxtStatus', 'updatedOn', 'submitted', 'comment'];
  statusKL = DpDomainComplianceStatusKL();
  demandPartnerDbName = DemandPartnerDbName;

  constructor(public dialogRef: MatDialogRef<DpUpdateComplianceStatusDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {domain: PublisherDomainsComplianceModel, disabled: boolean} ,
              private complianceService: ComplianceService,
              private publishersService: PublishersService,
              private sbs: SnackBarService,
              private gss: GlobalSpinnerService) {
  }

  ngOnInit(): void {
    this.getComplianceApproval();
  }

  ngAfterViewInit(): void {
    this.dialogRef.updateSize('800px');
  }

  getComplianceApproval() {
    this.loading = true;
    this.complianceService.complianceApproval(this.data?.domain?.url).pipe(take(1)).subscribe(
      response => {
        this.dataSource.data = this.dpComplianceApproval = response;
        this.getAdstxtDiff();
      }, error => {
        ServerErrorUtils.serverValidationOrMsg(error, this.sbs, 'Error while fetching compliance approvals');
        this.dialogRef.close();
      }
    );
  }

  getAdstxtDiff() {
    if (this.dpComplianceApproval?.length) {
      this.publishersService.domainsAdstxtDiff(PublisherDomain.of(this.dpComplianceApproval[0].publisherId, this.data?.domain?.url))
        .subscribe((next: AdsTxtDiff) => {
          this.loading = false;
          this.adstxtDiff = next;
        }, error => {
          ServerErrorUtils.serverValidationOrMsg(error, this.sbs, 'Error while fetching ads.txt diff');
          this.dialogRef.close();
        });
    } else {
      this.loading = false;
    }
  }

  saveChanges() {
    this.gss.showLoader();
    this.complianceService.updateApprovals(this.dpComplianceApproval).pipe(take(1)).subscribe(
      response => {
        this.gss.hideLoader();
        this.sbs.success('You have successfully updated approvals.');
        this.dialogRef.close(true);
      }, error => {
        this.gss.hideLoader();
        ServerErrorUtils.serverValidationOrMsg(error, this.sbs, 'Error while updating compliance approvals');
        this.dialogRef.close();
      }
    );
  }

  found() {
    if (this.dataSource.data?.length) {
      return this.dataSource.data.filter(x => x.status === DpDomainComplianceStatus.APPROVED).length;
    }
    return 0;
  }

  total() {
    return this.dataSource?.data?.length || 0;
  }

  calculateAdsTxtCondensed(element: DpDomainComplianceModel) {
    let notFound = 0;
    let found = 0;
    for (const d of this.adstxtDiff.notFound) {
      if (this.demandPartnerDbName[d.demandPartnerName] === element.demandPartnerName) {
        notFound++;
      }
    }
    for (const d of this.adstxtDiff.found) {
      if (this.demandPartnerDbName[d.demandPartnerName] === element.demandPartnerName) {
        found++;
      }
    }
    return notFound === 0 && found > 0;
  }
}
