
<mat-toolbar>
	<div class="row no-gutters align-items-center w-100 py-3 py-lg-0">
    <div *ngIf="backBtnRouteUrl" class="mat-toolbar-back" matTooltip="Back">
      <button mat-icon-button color="primary" (click)="goBackRoute()">
        <i class="fas fa-chevron-left"></i>
      </button>
    </div>
		<span *ngIf="pageTitle" class="col-12 col-md d-flex align-items-center justify-content-center justify-content-lg-start"
          [ngClass]="toolbarClass">
			{{pageTitle}}
			<i *ngIf="titleNext" class="fa fa-chevron-right title-seperator" aria-hidden="true"></i>
			<span *ngIf="titleNext">{{titleNext}}</span>
		</span>

    <ng-content></ng-content>

		<div class="toolbar-info col-12 col-lg-auto mt-3 mt-lg-0 d-flex justify-content-center justify-content-lg-end">
			<ng-content select="[toolbar-info]"></ng-content>
		</div>
		<div class="toolbar-actions col-12 col-lg mt-3 mt-lg-0 d-flex justify-content-center justify-content-lg-end">
			<ng-content select="[toolbar-actions]"></ng-content>
		</div>
	</div>
</mat-toolbar>
