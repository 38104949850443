import {Component, Input} from '@angular/core';

@Component({
  selector: 'cs-card-filters',
  template: `
    <div class="cs-card-filters" [ngClass]="innerClass" [hidden]="!_showFilters">
	    <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./cm-card-filters.component.scss']
})
export class CsCardFiltersComponent {
  _showFilters: boolean;

  @Input()
  set showFilters(showFilters: boolean) {
    this._showFilters = showFilters;
  }

  @Input()
  innerClass: string;

  toggle() {
    this._showFilters = !this._showFilters;
  }
}
