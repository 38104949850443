import {Injectable} from '@angular/core';
import {User} from './user.model';
import {Observable, Observer, of, throwError} from 'rxjs';
import {Principal} from './principal.service';
import {Router} from '@angular/router';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {UsersService} from '../../shared/services/users.service';
import {Publisher} from '../../shared/domain/publisher.model';
import {PublishersService} from '../../shared/services/publishers.service';
import {UserRole} from './user-role.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private router: Router,
              private httpClient: HttpClient,
              private usersService: UsersService,
              private publishersService: PublishersService,
              private principal: Principal) {
    const token = localStorage.getItem('authenticationToken');
    console.log(`authentication token from local storage: [${token}]`);
    if (token) {
      principal.setToken(token);
    }
  }

  getToken() {
    return this.principal.getToken();
  }

  isAuthenticated() {
    return this.principal.isAuthenticated();
  }

  login(credentials: User): Observable<User> {
    const self = this;

    return new Observable((observer: Observer<User>) => {
      this.usersService.login(credentials).subscribe(
        (resp: HttpResponse<string>) => {
          try {
            const user = <User> JSON.parse(resp.body);
            const jwt = user.accessToken;
            if (jwt) {
              localStorage.setItem('authenticationToken', jwt);
              this.principal.setToken(jwt);

              this.identity(true).subscribe(
                (u: User) => {
                  observer.next(u);
                },
                () => {
                  observer.error('Error during identity establishment');
                },
                () => observer.complete());
            }
          } catch (e) {
            observer.error('Invalid token');
            observer.complete();
          }
        },
        error => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  logout(): Observable<any> {
    return new Observable((observer) => {
      console.log('clearing authentication token');
      localStorage.removeItem('authenticationToken');
      this.principal.cleanup();
      observer.complete();
    });
  }

  identity(force?: boolean, t?): Observable<User> {
    console.log(t);
    return new Observable(subscriber => {
      const user = this.principal.getUser();
      if (!force && user) {
        subscriber.next(user);
        subscriber.complete();
      } else {
        this.principal.setUser(null);
        this.usersService.me().subscribe(
          newUser => {
            if (newUser.roles.includes(UserRole.CS_ADMIN) && !Object.keys(this.principal.mask).length) {
              this.principal.setMask(UserRole.CS_ADMIN, null);
            }
            this.principal.setUser(newUser);
            subscriber.next(newUser);
            subscriber.complete();
          },
          (error) => subscriber.error(error),
          () => subscriber.complete());
      }
    });
  }

  publisherIdentity(force?: boolean): Observable<Publisher> {
    if (!force && this.principal.publisher) {
      // publisher exists and we didnt force identity - ok resolve it
      return of(this.principal.publisher);
    } else {
      const user = this.principal.getUser();
      if (user) {
        const publisherId = this.principal.mask.publisherId;
        if (!publisherId) {
          this.router.navigate(['login']);
          throw throwError('publisher id does not exist for user !');
        }
        return new Observable(subscriber => {
          this.publishersService.getPublisherById(publisherId)
            .subscribe(
              (publisher: Publisher) => {
                this.principal.publisher = publisher;
                subscriber.next(publisher);
              },
              () => subscriber.error(false),
              () => subscriber.complete());
        });
      } else {
        // ok resolve user and then try getting publisher identity again
        return new Observable(subscriber => {
          this.identity(true).subscribe(u => {
              this.publisherIdentity(true).subscribe(publisher => {
                  subscriber.next(publisher);
                },
                error => subscriber.error(error),
                () => subscriber.complete());
            },
            error => subscriber.error(error));
        });
      }
    }
  }
}
