<mat-sidenav-container>

  <mat-sidenav-content>
    <div class="mobile-top-bar d-flex align-items-center d-md-none"
         (scroll)="onScrollEvent($event)"
         [ngClass]=" {'fixed' : (scrollOffset > 0)} ">
      <div class="col p-0 d-flex" (click)="sidenav.toggle()">
        <button mat-icon-button>
          <i class="fas fa-bars"></i>
        </button>
      </div>
      <div class="col p-0 text-right">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <i class="far fa-ellipsis-v"></i>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item *ngFor="let pub of publishers" (click)="openPubDashboard(pub.id)">
            <span>{{pub.name}}</span>
          </button>
          <mat-divider *ngIf="publishers"></mat-divider>
          <button mat-menu-item (click)="logout()" class="mb-n2">
            <i class="far fa-sign-out"></i>
            <span>Sign out</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <main class="main">
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>

  <mat-sidenav [mode]="(screenWidth > 768) ? 'side' : 'over'"
               [class.sideOpened]="sidenav.opened"
               [class.sideClosed]="!sidenav.opened"
               #sidenav>
    <div class="sidenav-header"
         (click)="(screenWidth < 992) ? sidenav.close() : ''">
      <!--<div class="user-icon mr-2" matTooltip="{{principal.user?.name}} - {{network?.name}}">-->
        <!--<i class="fal fa-user-circle"></i>-->
      <!--</div>-->
      <div class="user">
        <p class="sidenav-header-username" title="{{principal.user?.name}}">
          {{principal.user?.name}} <br/>
          <small>YieldLift</small>
        </p>
      </div>
      <button mat-icon-button color="primary" [matMenuTriggerFor]="menu" class="d-none d-md-block">
        <i class="far fa-ellipsis-v"></i>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item *ngFor="let pub of publishers" (click)="openPubDashboard(pub.id)">
          <span>{{pub.name}}</span>
        </button>
        <mat-divider *ngIf="publishers"></mat-divider>
        <button mat-menu-item (click)="logout()" class="mb-n2">
          <i class="far fa-sign-out"></i>
          <span>Sign out</span>
        </button>
      </mat-menu>
      <button mat-icon-button color="primary" (click)="sidenav.toggle()" class="d-none d-md-block">
        <i class="fas fa-bars"></i>
      </button>
    </div>
    <ul class="sidenav-menu" (click)="(screenWidth < 768) ? sidenav.close() : ''">
      <li *csHasPermission="[RolePermission.DASHBOARD_MODULE]">
        <a routerLink="/dashboard"
           routerLinkActive="active">
          <i class="fal fa-tachometer-alt" matTooltip="Dashboard"></i>
          <span>Dashboard</span>
        </a>
      </li>
      <li *csHasPermission="[RolePermission.REPORTING_MODULE]">
        <a routerLink="/reports"
           routerLinkActive="active">
          <i class="fal fa-chart-bar" matTooltip="Report builder"></i>
          <span>Reporting</span>
        </a>
      </li>
      <li *csHasPermission="[RolePermission.ANALYTICS_MODULE]">
        <a routerLink="/analytics"
           routerLinkActive="active">
          <i class="fal fa-analytics" matTooltip="Analytics"></i>
          <span>Analytics</span>
        </a>
      </li>
      <li *csHasPermission="[RolePermission.PARTNERS_MODULE]">
        <a routerLink="/partners"
           routerLinkActive="active">
          <i class="fal fa-user-friends" matTooltip="Partners"></i>
          <span>Partners</span>
        </a>
      </li>
      <li *csHasPermission="[RolePermission.PUBLISHERS_MODULE]">
        <a routerLinkActive="active"
           routerLink="/publishers">
          <i class="fal fa-user-tie" matTooltip="Publishers"></i>
          <span>Publishers</span>
        </a>
      </li>
      <li *csHasPermission="[RolePermission.AD_UNITS_MODULE]">
        <a routerLinkActive="active"
           routerLink="/dp-units">
          <i class="fal fa-ad" matTooltip="Ad units"></i>
          <span>Ad units</span>
        </a>
      </li>
      <li *csHasPermission="[RolePermission.DEMAND_PARTNERS_MODULE]">
        <a routerLinkActive="active"
           routerLink="/demand-partners">
          <i class="fal fa-handshake" matTooltip="Publishers"></i>
          <span>Demand partners</span>
        </a>
      </li>
      <li *csHasPermission="[RolePermission.WIDGET_TYPES_MODULE]">
        <a routerLinkActive="active"
           routerLink="/widget-types">
          <i class="fal fa-magic" matTooltip="Widget types"></i>
          <span>Widget types</span>
        </a>
      </li>
      <li *csHasPermission="[RolePermission.ADS_TXT_MODULE]">
        <a routerLinkActive="active"
           routerLink="{{checkRoutePermission()}}">
          <i class="fal fa-file-alt" matTooltip="Ads.txt"></i>
          <span>Ads.txt</span>
        </a>
      </li>
      <li *csHasPermission="[RolePermission.DISCREPANCIES_MODULE]">
        <a routerLink="/discrepancies"
           routerLinkActive="active">
          <i class="fal fa-exclamation-triangle" matTooltip="Discrepancies"></i>
          <span>Discrepancies</span>
        </a>
      </li>
      <li *csHasPermission="[RolePermission.FINANCES_MODULE]">
        <a routerLink="/finances"
           routerLinkActive="active">
          <i class="fal fa-money-bill-alt" matTooltip="Finances"></i>
          <span>Finances</span>
        </a>
      </li>
      <li *csHasPermission="[RolePermission.CHANGELOG_MODULE]">
        <a routerLink="/changelog"
           routerLinkActive="active">
          <i class="fal fa-clipboard-list" matTooltip="Change log"></i>
          <span>Change log</span>
        </a>
      </li>
      <li *csHasPermission="[RolePermission.USERS_MODULE]">
        <a routerLink="/users"
           routerLinkActive="active">
          <i class="fal fa-users" matTooltip="Users"></i>
          <span>Users</span>
        </a>
      </li>
    </ul>
  </mat-sidenav>
</mat-sidenav-container>
