import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpUtils} from '../utils/http-utils';
import {ReportResponse} from '../domain/rb/report-response.model';
import {Principal} from '../../core/auth/principal.service';
import {AdUnitsListFilter} from './ad-units.service';
import {AdUnit} from '../domain/ad-unit.model';
import {Page} from '../domain/page.model';
import {PublisherDomainListFilter} from './publishers.service';
import {PublisherDomain} from '../domain/publisher-domain.model';
import {AdsTxtRecord} from '../domain/adstxt-record.model';

@Injectable({
  providedIn: 'root',
})
export class PubDashServiceHttp {

  constructor(private httpClient: HttpClient, private principal: Principal) {
  }

  public dashboard(dateFrom: string, dateTo: string, dailyBreakdown = false): Observable<ReportResponse> {
    return this.httpClient.get<ReportResponse>(`${environment.serviceUrl}/pub-dash/dashboard`, {
      params: HttpUtils.getHttpParams({
        dateFrom,
        dateTo,
        dailyBreakdown,
        publisherId: this.principal.publisher.id
      })
    });
  }

  public dashboardDomain(dateFrom: string, dateTo: string, dailyBreakdown = false): Observable<ByDomainReportResponse> {
    return this.httpClient.get<ByDomainReportResponse>(`${environment.serviceUrl}/pub-dash/dashboard-domain`, {
      params: HttpUtils.getHttpParams({
        dateFrom,
        dateTo,
        dailyBreakdown,
        publisherId: this.principal.publisher.id
      })
    });
  }

  listAdUnits(filter: AdUnitsListFilter): Observable<Page<AdUnit>> {
    return this.httpClient.get<Page<AdUnit>>(`${environment.serviceUrl}/pub-dash/ad-units`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  listDomains(filter: PublisherDomainListFilter): Observable<Page<PublisherDomain>> {
    return this.httpClient.get<Page<PublisherDomain>>(`${environment.serviceUrl}/pub-dash/domains`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  getAdsTxt(pubId: string) {
    return this.httpClient.get<AdsTxtRecord[]>(`${environment.serviceUrl}/pub-dash/adstxt-records`, {
      params: HttpUtils.getHttpParams({
        publisherId: pubId
      })
    });
  }
}

export class ByDomainReportResponse {
  dateStart: string;
  dateEnd: string;
  urls: UnitTypeUrls;
  unitImpressions: number;
  totalUnitImpressions: number;
  adRequests: number;
  revenue: number;
  totalRevenue: number;
  publisherRevenue: number;
  totalRpm: number;
}


export class UnitTypeUrls {
  urls: Url[];
  unitImpressions: number;
  adRequests: number;
  revenue: number;
  publisherRevenue: number;
  totalUnitImpressions: number;
  totalRpm: number;
}

export class Url {
  url: string;
  domainId?: string;
  unitImpressions: number;
  adRequests: number;
  revenue: number;
  publisherRevenue: number;
  units?: Unit[];
  urls?: Url[];
  name?: string;
  totalUnitImpressions: number;
  totalRpm: number;
}

export class Unit {
  id: string;
  name: string;
  unitImpressions: number;
  totalUnitImpressions: number;
  adRequests: number;
  revenue: number;
  publisherRevenue: number;
  revShare: number;
  ioCpm: number;
  url: string;
  units: Unit[];
  totalRpm: number;
}

