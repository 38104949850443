<form #f="ngForm" class="row">
	<div class="col-12 col-md-6 col-lg">
		<mat-form-field  appearance="outline" floatLabel="always" class="w-100">
			<mat-label>Metric</mat-label>
			<mat-select placeholder="Metric" name="metric" [(ngModel)]="predicate.metric">
				<mat-option *ngFor="let metric of metrics" [value]="metric.key">
					{{ metric.label }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="col-12 col-md-6 col-lg-auto">
		<mat-form-field  appearance="outline" floatLabel="always" class="w-100">
			<mat-label>Operator</mat-label>
			<mat-select placeholder="Operator" name="operator" [(ngModel)]="predicate.operator">
				<mat-option *ngFor="let o of operators" [value]="o.key">
					{{ o.label }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="col-12 col-md-6 col-lg-auto">
		<mat-form-field  appearance="outline" floatLabel="always" class="w-100">
			<mat-label>Condition</mat-label>
			<input matInput type="number" placeholder="Condition" name="condition" [(ngModel)]="predicate.condition"
			       (key.enter)="predicate.metric && predicate.condition && predicate.operator ? addPredicate() : null">
		</mat-form-field>
	</div>
	<div class="col-12 col-md-6 col-lg-auto d-flex align-items-center justify-content-center justify-content-md-start">
		<button mat-raised-button
		        color="primary"
		        [disabled]="!predicate.metric || !predicate.condition || !predicate.operator"
		        matTooltip="Add predicate" (click)="addPredicate()"
		        class="iconed large mb-3 mat-elevation-z0">
			<i class="far fa-plus-circle"></i>
			Add condition
		</button>
	</div>
</form>

<div class="row">
	<div class="col col-auto">
		<mat-chip-list #chipList class="mat-chip-list-inline">
			<mat-basic-chip *ngFor="let predicate of predicates"
			                [removable]="true"
			                (removed)="removePredicate(predicate)">
				{{ ReportMetricLabels[predicate.metric]}}
				{{OperatorLabels[predicate.operator]}}
				{{predicate.condition}}
        <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
			</mat-basic-chip>
		</mat-chip-list>
	</div>
</div>
