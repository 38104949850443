import {KeyLabel} from './key-label.model';

export enum DemandPartnerEnum {
  OPENX = 'OPENX',
  ACROSS_33 = 'ACROSS_33',
  APP_NEXUS = 'APP_NEXUS',
  RHYTHMONE = 'RHYTHMONE',
  ADX = 'ADX',
  INDEX = 'INDEX',
  SONOBI = 'SONOBI',
  SONOBI_ORTB = 'SONOBI_ORTB',
  SOVRN = 'SOVRN',
  PUBMATIC = 'PUBMATIC',
  PUBMATIC_ORTB = 'PUBMATIC_ORTB',
  RUBICON = 'RUBICON',
  BREAL_TIME = 'BREAL_TIME',
  AMX = 'AMX',
  YAHOO_SSP = 'YAHOO_SSP',
  ONE_FIFTY_TWO = 'ONE_FIFTY_TWO',
  MINUTE_MEDIA = 'MINUTE_MEDIA',
}

export const DemandPartnerDbName = {
  APP_NEXUS: 'AppNexus',
  OPENX: 'OpenX',
  ADX: 'Adx',
  INDEX: 'Index',
  SOVRN: 'Sovrn',
  SONOBI: 'Sonobi',
  SONOBI_ORTB: 'Sonobi oRTB',
  PUBMATIC: 'PubMatic',
  PUBMATIC_ORTB: 'PubMatic oRTB',
  RUBICON: 'Rubicon',
  ACROSS_33: '33across',
  RHYTHMONE: 'rhythmone',
  BREAL_TIME: 'bRealTime',
  AMX: 'AMX',
  VUUKLE_TS: 'Vuukle (TS)',
  INFOLINKS_TS: 'InfoLinks (TS)',
  INSTICATOR_TS: 'Insticator (TS)',
  ZERGNET_TS: 'ZergNet (TS)',
  ONE_TAG: 'OneTag',
  REVCONTENT_TS_300_250: 'Revcontent (TS) - 300x250 - desktop',
  REVCONTENT_TS_300_250_MOBILE: 'Revcontent (TS) - 300x250 - mobile',
  REVCONTENT_TS_300_600: 'Revcontent (TS) - 300x600',
  REVCONTENT_TS_728_90: 'Revcontent (TS) - 728x90',
  REVCONTENT_TS_320_50: 'Revcontent (TS) - 320x50',
  OPSCO_TS: 'OpsCo (TS) - 300x250',
  OPSCO_TS_728_90: 'OpsCo (TS) - 728x90',
  YAHOO_SSP: 'Yahoo SSP',
  ONE_FIFTY_TWO: 'One Fifty Two Media',
  MINUTE_MEDIA: 'MinuteMedia',
};

export const DemandPartnerDbNameList = Object.values(DemandPartnerDbName);
export const DemandPartnerEnumKL = () => Object.keys(DemandPartnerDbName).map(key => new KeyLabel(key, DemandPartnerDbName[key]));
