import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReportMetric, ReportMetricDetails, ReportMetricsAll} from '../../domain/rb/report-metric.enum';
import {Publisher} from '../../domain/publisher.model';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'cs-report-metric-multiselect-search',
  template: `
    <mat-form-field floatLabel="never" class="w-100">
      <input matInput placeholder="{{'Search metric'}}"
             aria-label="Search"
             [formControl]="filterCtrl">
    </mat-form-field>
    <div class="metrics-multiselect-list-controls d-flex justify-content-between">
      <button mat-button color="primary" (click)="selectNone(); $event.stopPropagation();">
        Deselect all
      </button>
      <button mat-button color="primary" (click)="selectAll(); $event.stopPropagation();">
        Select all
      </button>
    </div>
    <div class="metrics-multiselect-list d-flex flex-column">
      <mat-checkbox
        *ngFor="let item of filteredMetrics"
        (change)="onChange(item, $event.checked) "
        [checked]="item._selected"
        color="primary"
        class="w-100 pb-0">
        {{item.label}}
      </mat-checkbox>
    </div>
  `,
  styles: [`
    .metrics-multiselect-list {
      margin: 0 0 0 -1rem;
      padding: 1rem;
      width: auto;
      height: 200px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    ::ng-deep .dropdown .metrics-multiselect-list {
      margin: 0 -1rem;
      padding: 1rem 1rem 0;
      width: 250px;
    }
  `]
})
export class ReportMetricMultiselectSearchComponent implements OnInit {

  @Input()
  selectedMetrics: ReportMetric[];

  @Output()
  selectionChange = new EventEmitter<ReportMetric[]>();

  @Output()
  selectionInitial = new EventEmitter<Publisher[]>();

  @Input()
  availableMetrics: ReportMetric[] = [];

  filterCtrl = new FormControl();
  filteredMetrics: ReportMetricDetails[] = [];

  allMetrics = [];

  constructor() { }

  ngOnInit() {
    this.filterCtrl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(next => {
        if (next) {
          this.filteredMetrics = this.allMetrics.filter(p => p.label.toLowerCase().includes(next.toLowerCase()));
        } else {
          this.filteredMetrics = this.allMetrics.concat();
          this.sortFilteredMetricsArray();
        }
      });
    this.allMetrics = ReportMetricsAll().concat();
    if (this.availableMetrics) {
      this.allMetrics = this.allMetrics.filter(m => this.availableMetrics.includes(m.key));
    }
    this.filteredMetrics = this.allMetrics.concat();
    this.updatePreselectedMetrics();
    this.sortFilteredMetricsArray();
  }

  selectAll() {
    this.allMetrics.forEach(p => p._selected = true);
    this.emit();
    this.sortFilteredMetricsArray();
  }

  selectNone() {
    this.allMetrics.forEach(p => p._selected = false);
    this.emit();
    this.sortFilteredMetricsArray();
  }

  onChange(metric, checked) {
    metric._selected = checked;
    // this.sortFilteredMetricsArray();
    this.selectionChange.emit(this.getSelectedMetrics());
  }

  private emit() {
    this.selectionChange.emit(this.getSelectedMetrics());
  }

  sortFilteredMetricsArray() {
    if (this.filteredMetrics) {
      this.filteredMetrics.sort((a1, a2) => {
        if (a1['_selected'] > a2['_selected']) { return -1; }
        if (a1['_selected'] < a2['_selected']) { return 1; }
        if (a1['label'].toLowerCase() < a2['label'].toLowerCase()) { return -1; }
        if (a1['label'].toLowerCase() > a2['label'].toLowerCase()) { return 1; }
      });
    }
  }

  private updatePreselectedMetrics() {
    if (this.selectedMetrics) {
      this.allMetrics.forEach(m => m._selected = this.selectedMetrics.includes(m.key));
    }
  }

  private getSelectedMetrics() {
    return this.allMetrics.filter(m => m._selected).map(m => m.key);
  }

  reset() {
    this.allMetrics.forEach(m => m._selected = false);
    this.filterCtrl.setValue(null);
    this.sortFilteredMetricsArray();
  }

}
