import {KeyLabel} from './key-label.model';

export class AdUnitDemandPartner {
  id: string;
  adUnitId: string;
  dpUnitId: string;
  demandPartner: string;
  siteId: string;
  bidAdjustment: number;
  bidFloor: number;
  unitActivated: boolean;
  approvalStatus: DemandPartnerApprovalStatus;
  bidType: BidType;
  creationInProgress?: boolean;
  duplication?: boolean;
}

export enum BidType {
  DISPLAY = 'DISPLAY',
  VIDEO = 'VIDEO'
}

export enum DemandPartnerApprovalStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  SUBMITTED = 'SUBMITTED',
  N_A = 'N_A'
}

export const DemandPartnerApprovalStatusLabels = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  SUBMITTED: 'Submitted',
  N_A: 'N/A'
};

export const BidTypeLabels = {
  DISPLAY: 'Display',
  VIDEO: 'Video'
};

export const DemandPartnerApprovalStatusKL = () =>
  (Object.keys(DemandPartnerApprovalStatus).map(key => new KeyLabel(<DemandPartnerApprovalStatus>key, DemandPartnerApprovalStatusLabels[key])));

export const BidTypeLabelsKL = () =>
  (Object.keys(BidTypeLabels).map(key => new KeyLabel(<BidType>key, BidTypeLabels[key])));

