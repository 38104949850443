import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cs-widget-daily-metrics-chart-settings-dialog',
  template: `
    <h3 mat-dialog-title>Select chart type</h3>
    <mat-dialog-content>
      <div class="d-flex flex-column mb-2">
        <mat-radio-group [(ngModel)]="selectedChartType"  class="radio-group">
          <mat-radio-button *ngFor="let chart of chartTypes" [value]="chart" class="radio-button">{{chart}}</mat-radio-button>
        </mat-radio-group></div>
    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-center">
      <button mat-raised-button mat-dialog-close cdkFocusInitial>Cancel</button>
      <button mat-raised-button color="primary" (click)="confirm()">Save</button>
    </mat-dialog-actions>
  `
})
export class WidgetDailyMetricsChartSettingsDialogComponent {

  chartTypes = [];
  selectedChartType: string;

  constructor(public dialogRef: MatDialogRef<WidgetDailyMetricsChartSettingsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.chartTypes = Object.keys(data.chartTypes).map(item => data.chartTypes[item]);
    this.selectedChartType = data.selectedChartType;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirm() {
    this.dialogRef.close(this.selectedChartType);
  }

}
