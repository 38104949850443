import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {Partner, PartnerListFilter} from '../../domain/partner.model';
import {PartnersService} from '../../services/partners.service';

@Component({
  selector: 'cs-partner-autocomplete',
  template: `
    <mat-form-field [floatLabel]="floatLabel || 'always'" class="w-100" [ngClass]="formFieldClass" appearance="outline">
      <mat-label *ngIf="floatLabel != 'never'">Choose partner</mat-label>
      <input matInput
             #name
             aria-label="Partner"
             [matAutocomplete]="auto"
             [formControl]="filterCtrl"
             placeholder="{{floatLabel != 'never' ? 'Search' : 'Choose partner'}}">
      <i class="fas fa-spinner fa-spin" matSuffix [hidden]="!serverSearching"></i>
      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="optionSelected($event)"
                        [displayWith]="display">
        <mat-option *ngFor="let item of filteredItems | async" [value]="item">
          {{item.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>`
})
export class PartnerAutocompleteComponent implements OnInit {

  @Input()
  public placeholder: string;

  @Input()
  public floatLabel: string;

  @Input()
  public formFieldClass: string;

  @Input()
  public partnerId: string;

  @Input()
  public partnerIds: string[];

  @Input()
  public disabled = false;

  @Output()
  partnerSelected = new EventEmitter<Partner>();

  @ViewChild('name', {static: true}) nameFilter: ElementRef;

  filteredItems: Subject<Partner[]>;
  filterCtrl = new FormControl();
  selectedItem;
  partners: Partner[] = [];
  serverSearching = false;
  selectedPartner: Partner;

  constructor(private service: PartnersService) {
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(next => {
        this.filteredItems.next([]);
        if (!next && this.selectedItem) {
          this.selectedItem = undefined;
          this.emit();
        }

        if (next && typeof next !== 'object') {
          this.serverSearching = true;
          this.filterCtrl.disable();
          service.get(<PartnerListFilter>{name: next, size: 10}).subscribe(resp => {
            this.filteredItems.next(resp.content);
            this.filterCtrl.enable();
            this.nameFilter.nativeElement.focus();
            this.serverSearching = false;
          }, () => {
            this.filterCtrl.enable();
            this.nameFilter.nativeElement.focus();
            this.serverSearching = false;
          });
        }
      });
  }

  ngOnInit(): void {
    if (this.partnerIds && this.partnerIds.length) {
      this.partnerId = this.partnerIds[0];
    }

    if (this.partnerId) {
      this.service.getPartnerById(this.partnerId).subscribe(next => {
        if (next) {
          this.selectedPartner = next;
          this.selectedItem = next;
          this.filterCtrl.setValue(next);
          if (!this.disabled) {
            this.emit();
          }
        }
      });
    }

    if (this.disabled) {
      this.filterCtrl.disable();
    }
  }

  display(p?: Partner) {
    return p ? p.name : undefined;
  }

  optionSelected(p) {
    this.selectedItem = p.option.value;
    this.filteredItems.next([]);
    this.emit();
  }

  resetInput() {
    this.selectedItem = null;
    this.filterCtrl.setValue(null);
  }

  private emit() {
    this.partnerSelected.emit(this.selectedItem);
  }
}
