import {Component, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Component({
  selector: 'cs-dropdown',
  template: `
    <div class="cs-dropdown">
      <span (click)="toggle()" matTooltip="{{tooltip}}" class="cs-dropdown-trigger">
        <ng-content select="cs-dropdown-trigger"></ng-content>
      </span>
      <span class="dropdown" [class.opened]="opened">
        <h6 class="dropdown-title">{{header}}</h6>
        <div class="dropdown-content">
          <ng-content></ng-content>
        </div>
      </span>
    </div>
  `,
  styleUrls: ['./cs-dropdown.component.scss']
})
export class CsDropdownComponent {

  @Input() header: string;
  @Input() tooltip: string;
  @Output() toggleDropdown = new EventEmitter<boolean>();
  opened: boolean;

  constructor(private _eref: ElementRef) { }

  toggle() {
    this.opened = !this.opened;
    this.toggleDropdown.emit(this.opened);
  }

  close() {
    this.opened = false;
    this.toggleDropdown.emit(this.opened);
  }

  @HostListener('document:click', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    try {
      const insideWidget = this._eref.nativeElement.contains(e.target);
      const optionClick = (<Element>e.target).className === 'mat-option-text';
      const backdropClick = (<Element>e.target).className === 'cdk-overlay-backdrop';
      const datepickerCell = (<Element>e.target).className.includes('mat-calendar-body-cell-content');
      const matCalendarClick = (<Element>e.target).className === 'mat-calendar';

      if (!insideWidget && !optionClick && !backdropClick && !datepickerCell && !matCalendarClick) {
        this.opened = false;
        this.toggleDropdown.emit(this.opened);
      }
    } catch (e) {
    }
  }

}

@Component({
  selector: 'cs-dropdown-trigger',
  template: `
    <ng-content></ng-content>`,
})
export class CsDropdownTriggerComponent {

}
