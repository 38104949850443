import {DateRangeType} from './date-range-type.enum';

export class DateRangeHolder {
  dateFrom: string;
  dateTo: string;
  dateRangeType: DateRangeType;

  constructor(dateFrom: string, dateTo: string, dateRangeType?: DateRangeType) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.dateRangeType = dateRangeType;
  }
}
