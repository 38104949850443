import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Component, HostBinding, Inject} from '@angular/core';

@Component({
  selector: 'cs-confirm-dialog',
  template: `
    <h1 mat-dialog-title>
      <i class="fas fa-exclamation-triangle mr-3"></i>
      {{data && data.title || 'Please confirm your action'}}
    </h1>
    <div mat-dialog-content>
      <mat-list class="mat-dialog-data-list">
        <h3 mat-subheader>{{data?.subtitle || 'Are you sure?'}}</h3>
        <mat-list-item>{{data.item}}</mat-list-item>
      </mat-list>
    </div>
    <div mat-dialog-actions class="d-flex justify-content-end">
      <button mat-raised-button mat-dialog-close cdkFocusInitial>Cancel</button>
      <button mat-raised-button color="{{data?.confirmButtonColor || 'warn'}}" [mat-dialog-close]="true">{{data?.confirmButtonText || 'Confirm'}}</button>
    </div>
  `,
})
export class ConfirmDialogComponent {
  @HostBinding('class.mat-dialog-warn') applyClazz = true;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
