import {AfterViewInit, Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ClipboardService} from 'ngx-clipboard';
import {SnackBarService} from '../../../../core/services/snackbar.service';
import {AdUnit} from '../../../domain/ad-unit.model';
import {Utils} from '../../../utils/utils';

@Component({
  selector: 'cs-unit-tag-preview-dialog',
  templateUrl: './unit-tag-preview-dialog.component.html',
  styleUrls: ['./unit-tag-preview-dialog.component.scss']
})
export class UnitTagPreviewDialogComponent implements AfterViewInit {

  adUnit: AdUnit;

  constructor(public dialogRef: MatDialogRef<UnitTagPreviewDialogComponent>,
              public snackBarService: SnackBarService,
              private clipboardService: ClipboardService,
              private sbs: SnackBarService,
              @Inject(MAT_DIALOG_DATA) public data: {adUnit: AdUnit }) {
    this.adUnit = data.adUnit;
  }

  ngAfterViewInit() {
    this.dialogRef.updateSize('500px');
  }

  getUnitTag() {
    return this.adUnit.bidder ?
      JSON.stringify({bidder: 'yieldlift', params: {unitId: this.adUnit.widgetId}}, null, '  ') :
      Utils.generateAdUnitTag(this.adUnit);
  }

  downloadFile() {
    const fileName = this.adUnit.bidder ? 'bidder_config' : 'unit_tag' + '_' + new Date().toLocaleDateString();
    Utils.exportBlob([this.getUnitTag()], fileName);
  }

  copyTagToClipboard() {
    this.clipboardService.copyFromContent(this.adUnit.bidder ?
      JSON.stringify({bidder: 'yieldlift', params: {unitId: this.adUnit.widgetId}}) : this.getUnitTag());
    this.sbs.success('Tag copied to clipboard');
  }
}
