import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AdUnitAutocompleteComponent} from './ad-unit-autocomplete.component';
import {AdUnit} from '../../domain/ad-unit.model';
import {AdUnitsListFilter, AdUnitsService} from '../../services/ad-units.service';
import {Principal} from '../../../core/auth/principal.service';

@Component({
  selector: 'cs-ad-unit-autocomplete-chips',
  template: `
    <cs-ad-unit-autocomplete #ac
                             (adUnitListAcquired)="adUnitListAcquired($event)"
                             [placeholder]="placeholder" [floatLabel]="floatLabel" [formFieldClass]="formFieldClass"
                             (adUnitSelected)="onItemSelected($event)" [ngClass]="innerClass"></cs-ad-unit-autocomplete>
    <mat-chip-list #chipList [ngClass]="items.length > 0 ? 'd-block mb-3 mb-lg-0' : '' ">
      <mat-basic-chip *ngFor="let item of items"
                      [removable]="true"
                      (removed)="remove(item)">
        {{item.name}}
        <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
      </mat-basic-chip>
    </mat-chip-list>
  `,
})
export class AdUnitAutocompleteChipsComponent implements OnInit {

  @ViewChild(AdUnitAutocompleteComponent, {static: true}) ac: AdUnitAutocompleteComponent;

  @Input()
  public placeholder: string;

  @Input()
  public adUnitIds: string[];

  @Input()
  public floatLabel: string;

  @Input()
  public hideLabel: boolean;

  @Input()
  public formFieldClass: string;

  @Input()
  public innerClass: string;

  @Output()
  adUnitSelectionChange = new EventEmitter<AdUnit[]>();

  items: AdUnit[] = [];

  constructor(private service: AdUnitsService, private principal: Principal) {
  }

  ngOnInit(): void {
    if (this.adUnitIds && this.adUnitIds.length) {
      this.service.listAdUnits(<AdUnitsListFilter>{ids: this.adUnitIds}).subscribe(page => {
        const adUnits = page.content;
        adUnits.forEach(au => {
          if (this.adUnitIds.includes(au.id)) {
            this.onItemSelected(au);
          }
        });
      });
    }
  }

  onItemSelected($event: AdUnit) {
    if ($event) {
      this.items.push($event);
      this.adUnitSelectionChange.emit(this.items);
      this.ac.resetInput();
      this.ac.updateFilteredUnits(this.items);
    }
  }

  remove(adUnit: AdUnit) {
    this.items.splice(this.items.indexOf(adUnit), 1);
    this.adUnitSelectionChange.emit(this.items);
    this.ac.updateFilteredUnits(this.items);
  }

  adUnitListAcquired(adUnits: AdUnit[]) {
    if (this.adUnitIds) {
      adUnits.forEach(au => {
        if (this.adUnitIds.includes(au.id)) {
          this.onItemSelected(au);
        }
      });
    }
  }

}
