import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Principal } from '../core/auth/principal.service';
import { LoginService } from '../core/login/login.service';
import { Network } from '../shared/domain/network.model';
import { RolePermission, RolePermissionService } from '../core/auth/rbac/role-permission.service';
import { Publisher } from '../shared/domain/publisher.model';
import { PublishersService } from '../shared/services/publishers.service';
import { SnackBarService } from '../core/services/snackbar.service';
import { UserRole } from '../core/auth/user-role.model';
import { PartnersService } from '../shared/services/partners.service';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'cs-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  @ViewChild('sidenav', {static: true}) matSidenav: MatSidenav;

  screenWidth: number;
  mobileTopBar = document.getElementsByClassName('mobile-top-bar');
  scrollOffset: number;
  RolePermission = RolePermission;
  publishers: Publisher[];
  partnerNames: string[];

  constructor(public principal: Principal,
    private httpClient: HttpClient,
    private loginService: LoginService,
    private publisherService: PublishersService,
    private sbs: SnackBarService,
    private permissions: RolePermissionService,
    private partnersService: PartnersService,
    private snackBarService: SnackBarService) {

    if (this.principal.user.partnerNames && this.principal.user.partnerNames.length) {
      this.partnerNames = this.principal.user.partnerNames;
    }
  }


  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.setMatSideNavOpenedState();

    if (this.principal.user.publisherIds && this.principal.user.publisherIds.length) {
      this.publisherService.getPublisherByIds(this.principal.user.publisherIds).subscribe(publishers => {
        this.publishers = publishers;
      }, () => {
        this.sbs.error('Error while fetching publishers');
      });
    }
    this.checkPartnerAdsTxtMissingRecords();
  }

  public logout() {
    this.loginService.logout(true);
  }

  @HostListener('window:resize')
  resizeScreen() {
    this.screenWidth = window.innerWidth;
    this.setMatSideNavOpenedState();
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    // console.log($event);
    if ($event.path && $event.path.length) {
      this.scrollOffset = $event.path[1].scrollY;
    } else {
      this.scrollOffset = 0;
    }
  }

  openPubDashboard(id) {
    window.open(`/#/p/${id}`, '_blank').focus();
  }

  checkRoutePermission() {
    return this.permissions.hasPermissions([RolePermission.ADS_TXT_RECORDS]) ? '/ads-txt' : '/ads-txt/domains';
  }

  checkPartnerAdsTxtMissingRecords() {
    if (this.principal.user.roles.includes(UserRole.CS_PARTNER)) {
      this.partnersService.checkPartnerAdsTxtMissingRecords().subscribe(
        (response: { valid: number, invalid: number }) => {
          if (response.invalid && response.invalid > 0) {
            this.snackBarService.open('Missing ads.txt records found. Please go to the \'Ads.txt\' section for more details. ', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              panelClass: 'warning' // todo Toni
            }, 'Close', '/ads-txt/domains');
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  setMatSideNavOpenedState() {
    if (this.screenWidth && this.screenWidth > 768) {
      this.matSidenav.opened = true;
    } else {
      this.matSidenav.opened = false;
    }
  }
}

