<div class="date-selector" [ngClass]="innerClass">
  <div class="d-flex justify-content-between justify-content-md-center align-items-center h-100">

    <button [disabled]="!isPrevDayEnabled()"
            (click)="chooser.goToPrevDay()"
            mat-mini-fab color="primary" class="mat-elevation-z0">
      <i class="fal fa-chevron-left"></i>
    </button>

    <div class="date-preview">
      <button (click)="UI.dateChooserOpen = !UI.dateChooserOpen"
              mat-flat-button color="primary">
        <span class="date-range-dates">
	        <span [hidden]="dateFrom !== dateTo">
	          {{dateFrom | date: 'MM/dd/yyyy'}}
	        </span>
	        <span [hidden]="dateFrom === dateTo">
	          {{dateFrom | date: 'MM/dd/yyyy'}} - {{dateTo | date: 'MM/dd/yyyy'}}
	        </span>
	      </span>
        <span class="date-range-icon">
        <i class="fal fa-calendar-alt"></i>
      </span>
      </button>
    </div>

    <button [disabled]="!isNextDayEnabled()"
            (click)="chooser?.goToNextDay()"
            mat-mini-fab color="primary" class="mat-elevation-z0">
      <i class="fal fa-chevron-right"></i>
    </button>
  </div>

  <cs-date-range-chooser
    #chooser
    [dateFrom]="dateFrom"
    [dateTo]="dateTo"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [allowedDateRangeTypes]="allowedDateRangeTypes"
    [maxRangeBetweenDates]="maxRangeBetweenDates"
    (dateRangeChanged)="onDateRangeChanged($event)"
    [hidden]="!UI.dateChooserOpen"
    class="d-block"
  ></cs-date-range-chooser>

</div>
