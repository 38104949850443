import {Component} from '@angular/core';

@Component({
  selector: 'cs-root',
  template: `
    <div class="app-wrapper mat-app-background">
      <router-outlet></router-outlet>
      <cs-loader #loader></cs-loader>
    </div>`
})
export class AppComponent {
}
