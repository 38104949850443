import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CsDropdownComponent} from '../table/cs-dropdown.component';
import {ReportMetric} from '../../domain/rb/report-metric.enum';
import {ReportMetricMultiselectSearchComponent} from './report-metric-multiselect-search.component';

@Component({
  selector: 'cs-report-metric-multiselect-search-dropdown',
  template: `
    <cs-dropdown #dropdown [header]="'Metrics'"
                 (toggleDropdown)="toggle($event)"
                 [tooltip]="'Metrics'">
      <cs-dropdown-trigger>
        <button mat-button color="primary" *ngIf="showLabel">
	        <i class="far fa-list"></i>
        </button>
        <button mat-icon-button color="primary" *ngIf="!showLabel">
	        <i class="far fa-list"></i>
        </button>
      </cs-dropdown-trigger>
      <cs-report-metric-multiselect-search
        *ngIf="opened"
        [selectedMetrics]="_selectedMetrics"
        [availableMetrics]="availableMetrics"
        (selectionChange)="temporaryMetrics = $event"
        #multiselectSearch class="d-block">
      </cs-report-metric-multiselect-search>
      <div class="dropdown-actions">
        <button mat-button color="primary" (click)="apply()">Apply</button>
        <button mat-button color="primary" (click)="dropdown.close()">Cancel</button>
      </div>
    </cs-dropdown>
  `
})
export class ReportMetricMultiselectSearchDropdownComponent implements OnInit {

  @ViewChild('dropdown', { static: true }) dropdown: CsDropdownComponent;
  @ViewChild('multiselectSearch') multiselectSearch: ReportMetricMultiselectSearchComponent;

  @Input()
  showLabel = false;

  @Input()
  availableMetrics: ReportMetric[];

  @Input()
  set selectedMetrics(selectedMetrics: ReportMetric[]) {
    this._selectedMetrics = selectedMetrics;
  }

  @Output()
  selectionChange = new EventEmitter<ReportMetric[]>();

  _selectedMetrics: ReportMetric[];
  temporaryMetrics: ReportMetric[];

  opened = false;

  constructor() { }

  ngOnInit() {
  }

  toggle(open) {
    this.opened = open;
  }

  apply() {
    this._selectedMetrics = this.temporaryMetrics;
    this.selectionChange.emit(this.temporaryMetrics);
    this.dropdown.close();
  }
}
