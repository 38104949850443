import {KeyLabel} from '../key-label.model';

export enum ReportType {
  STATISTICAL = 'STATISTICAL',
  FINANCIAL = 'FINANCIAL',
  DEMAND_PARTNER = 'DEMAND_PARTNER',
  EXCHANGE = 'EXCHANGE',
  COMBINED = 'COMBINED'
}


export const ReportTypeLabels = {
  STATISTICAL: 'Statistical',
  FINANCIAL: 'Financial',
  DEMAND_PARTNER: 'Demand partner',
  EXCHANGE: 'Exchange',
  COMBINED: 'Combined',
};

//todo support others
export const ReportTypeKL = () =>
  ([ReportType.COMBINED, ReportType.FINANCIAL, ReportType.EXCHANGE].map(
      key => new KeyLabel<ReportType>(ReportType[key], ReportTypeLabels[key]))
  );

