import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PublisherAutocompleteComponent} from './publisher-autocomplete.component';
import {PublishersService} from '../../services/publishers.service';
import {Publisher} from '../../domain/publisher.model';

@Component({
  selector: 'cs-publisher-autocomplete-chips',
  template: `
    <div class="cs-publisher-autocomplete-chips-wrapper">
      <cs-publisher-autocomplete #ac [isRequired]="isRequired" (publisherSelected)="onItemSelected($event)" [formFieldClass]="formFieldClass">
      </cs-publisher-autocomplete>
      <mat-chip-list #chipList [ngClass]="stacked ? 'mat-chip-list-stacked' : ''">
        <mat-basic-chip *ngFor="let item of items"
                        [removable]="true"
                        (removed)="remove(item)">
          {{item.name}}
          <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
        </mat-basic-chip>
      </mat-chip-list>
    </div>
  `,
})
export class PublisherAutocompleteChipsComponent implements OnInit {

  @ViewChild(PublisherAutocompleteComponent, { static: true }) ac: PublisherAutocompleteComponent;

  @Input()
  public placeholder: string;

  @Input()
  public stacked: false;

  @Input()
  public publisherIds: string[];

  @Input()
  public formFieldClass: string;

  @Input()
  public isRequired: boolean;

  @Output()
  publisherSelectionChange = new EventEmitter<Publisher[]>();

  items: Publisher[] = [];

  constructor(private service: PublishersService) {
  }

  ngOnInit(): void {
    if (this.publisherIds && this.publisherIds.length) {
      this.service.getPublisherByIds(this.publisherIds).subscribe(publishers => {
        publishers.forEach(au => {
          if (this.publisherIds.includes(au.id)) {
            this.onItemSelected(au);
          }
        });
      });
    }
  }

  onItemSelected($event: Publisher) {
    if ($event) {
      this.items.push($event);
      this.publisherSelectionChange.emit(this.items);
      this.ac.resetInput();
      this.ac.updateFilteredPublishers(this.items);
    }
  }

  remove(p: Publisher) {
    this.items.splice(this.items.indexOf(p), 1);
    this.publisherSelectionChange.emit(this.items);
    this.ac.updateFilteredPublishers(this.items);
  }

}
