import {Component, Input, OnInit} from '@angular/core';
import {KeyLabel} from '../../domain/key-label.model';
import {ReportMetric, ReportMetricDetails, ReportMetricLabels, ReportMetricsAll} from '../../domain/rb/report-metric.enum';
import {ReportPredicate} from '../../domain/rb/report-predicate.model';
import {OperatorLabels, OperatorsAll} from '../../domain/rb/operator.enum';
import {RolePermission, RolePermissionService} from '../../../core/auth/rbac/role-permission.service';

@Component({
  selector: 'cs-report-builder-predicates',
  templateUrl: './report-builder-predicates.component.html'
})
export class ReportBuilderPredicatesComponent implements OnInit {

  @Input()
  metrics: ReportMetricDetails[];

  @Input()
  predicates: ReportPredicate[] = [];

  operators: KeyLabel[] = <KeyLabel[]> OperatorsAll;
  OperatorLabels = OperatorLabels;
  ReportMetricLabels = ReportMetricLabels;

  predicate = new ReportPredicate();

  constructor(private rolePermissionService: RolePermissionService) {
  }

  ngOnInit(): void {
    this.metrics = this.metrics || ReportMetricsAll().filter(m => {
      if (ReportMetric.NET_PROFIT === m.key) {
        return this.rolePermissionService.hasPermission(RolePermission.DASHBOARD_TABLE_ACTIONS) && NumericMetrics.includes(m.key);
      }
      return NumericMetrics.includes(m.key);
    });
  }

  addPredicate() {
    this.predicates.push(this.predicate);
    this.predicate = new ReportPredicate();
  }

  removePredicate(p: ReportPredicate) {
    this.predicates.splice(this.predicates.indexOf(p), 1);
  }

}

export const NumericMetrics = [
  ReportMetric.UNIT_IMPRESSIONS,
  ReportMetric.PIXEL_IMPRESSIONS,
  ReportMetric.DFP_DOWNLOADED_IMPRESSIONS,
  ReportMetric.FILLED_IMPRESSIONS,
  ReportMetric.AD_REQUESTS,
  ReportMetric.FILLED,
  ReportMetric.REFRESH_RATE,
  ReportMetric.AD_IMPRESSION_RATIO,
  ReportMetric.TOTAL_RPM,
  ReportMetric.ECPM,
  ReportMetric.MARGIN,
  ReportMetric.REVENUE,
  ReportMetric.PUBLISHER_IO_REVENUE,
  ReportMetric.PUBLISHER_SHARE_REVENUE,
  ReportMetric.PUBLISHER_REVENUE,
  ReportMetric.PROFIT,
  ReportMetric.PASSBACK_REVENUE,
  ReportMetric.IN_VIEW_DFP,
  ReportMetric.IN_VIEW_ADX,
  ReportMetric.DISPLAY_DURATION,
  ReportMetric.DISPLAY_DURATION_RELATIVE,
  ReportMetric.PLAYS,
  ReportMetric.CLICKS,
  ReportMetric.WIDGET_REQUESTS,
  ReportMetric.BIDDER_COST,
  ReportMetric.NET_PROFIT,
  ReportMetric.PARTNER_AD_SERVING_FEE,
  ReportMetric.PARTNER_NET_PROFIT,
  ReportMetric.PARTNER_PROFIT,
];
