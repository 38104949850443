import {Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import * as Highcharts from 'highcharts';
import {Principal} from '../../../../core/auth/principal.service';
import {ReportRequestPresets} from '../../../domain/rb/report-request-presets.model';
import {ReportMetric} from '../../../domain/rb/report-metric.enum';
import {MomentDateUtils} from '../../../utils/moment-date-utils';
import {ReportDimension} from '../../../domain/rb/report-dimension.enum';
import {Utils} from '../../../utils/utils';
import {DashboardService} from '../../../services/dashboard.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'cs-demand-partners-revenue-chart',
  template: `
    <div class="card-message" [hidden]="!loading">
      <mat-spinner [color]="'primary'"
                   [mode]="'indeterminate'"
                   [diameter]="30">
      </mat-spinner>
    </div>
    <div class="card-message"
         [hidden]="loading || chartOptions">
      No data
    </div>
    <div class="p-4" [hidden]="loading || !chartOptions">
      <div #chart class="w-100"></div>
    </div>
  `
})
export class DemandPartnersRevenueChartComponent implements OnDestroy {

  @ViewChild('chart', {static: true}) chartEl: ElementRef;
  Highcharts = Highcharts;
  chartOptions;
  subscription: Subscription;
  loading: boolean;

  chartSettings = ReportRequestPresets.DAILY_COMBINED;

  constructor(private service: DashboardService,
              private principal: Principal) {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  loadChartData() {
    this.loading = true;
    this.chartSettings.metrics = [ReportMetric.DEMAND_PARTNER, ReportMetric.TOTAL_REVENUE, ReportMetric.DAY];
    this.chartSettings.dimensions = [ReportDimension.DEMAND_PARTNER, ReportDimension.DAY];
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.service.report(this.chartSettings).subscribe(next => {
      this.loading = false;
      next.report.length ? this.renderChart(next.report) : this.chartOptions = null;
    }, (error) => {
      this.loading = false;
    });
  }

  renderChart(dataReport) {
    dataReport.sort(Utils.sortComparatorByAttribute('day'));
      const allDays = dataReport.reduce(function (r, a) {
        r[a.day] = r[a.day] || [];
        r[a.day].push(a);
        return r;
      }, Object.create(null));
    const reportDataDays: any[] = Object.values(allDays);
    const reportData: {demandPartner: string, displayName: string, data: number[]}[] = [];
    dataReport.map(dr => {
      const index = reportData.findIndex(rp => rp.demandPartner === dr.demandPartner);
      if (index === -1) {
        reportData.push({demandPartner: dr.demandPartner, displayName: dr.demandPartnerDisplayName,
          data: Array.from({length: reportDataDays.length}, (v, k) => 0)});
      }
    });
    dataReport.map(details => {
      const index = reportData.findIndex(rp => rp.demandPartner === details.demandPartner);
      if (index > -1) {
        const dayIndex = reportDataDays.findIndex(d => d[0].day === details.day);
        if (dayIndex > -1) {
          reportData[index].data[dayIndex] = details.totalRevenue ? details.totalRevenue : 0;
        }
      }
    });

    this.chartOptions = {
      chart: {
        type: 'spline',
        zoomType: 'xy',
        height: 472
      },

      title: {
        text: ''
      },

      yAxis: [{
        title: {
          text: 'Total Revenue'
        }
      }
      ],

      tooltip: {
        shared: true,
        animation: false,
        formatter: function () {
          let s = this.points[0].x + '<br/><br/>';
          const sortedPoints = this.points.sort(function(a, b) {
            return ((a.y < b.y) ? -1 : ((a.y > b.y) ? 1 : 0));
          });
          sortedPoints.reverse();
          sortedPoints.forEach(sp => {
            s += `<br/><span style="color: ${sp.color};">\u25CF</span>${sp.series.name} : <strong>$${sp.y}</strong>`;
          });

          return s;
        }
      },

      xAxis: {
        crosshair: true,
        categories: reportDataDays.map(rd => MomentDateUtils.format(rd[0].day, 'MM/DD/YYYY')),
        labels: {
          step: reportDataDays.length > 150 ? 10 : reportDataDays.length > 50 ? 5 : reportDataDays.length > 20 ? 2 : 1,
          rotation: -45
        }
      },

      series: reportData.map((details, index) => {
        return {
          name: details.displayName,
          data: details.data,
          tooltip: {
            valueDecimals: 2,
            valuePrefix: '$'
          },
          lineWidth: 1.5,
          marker: {
            enabled: false
          }
        };
      })
    };

    Highcharts.chart(this.chartEl.nativeElement, this.chartOptions);
  }

  setDateRange(dateFrom: string, dateTo: string) {
    this.chartSettings.filters.dateFrom = dateFrom;
    this.chartSettings.filters.dateTo = dateTo;
    this.loadChartData();
  }
}
