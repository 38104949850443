import {AdUnit} from '../domain/ad-unit.model';
import {environment} from '../../../environments/environment';
import {AdsTxtRecord} from '../domain/adstxt-record.model';

const Papa = require('papaparse/papaparse.min.js');


export class Utils {
  public static generateGuid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  }

  public static isNullOrUndefined(value) {
    return value === undefined || value === null;
  }

  public static isNullUndefOrEmpty(value) {
    return value === undefined || value === null || value.toString().trim() === '';
  }

  public static randomInt() {
    return Math.random();
  }

  public static isMobile() {
    return /Android|iPhone/i.test(window.navigator.userAgent);
  }

  public static sortComparatorByAttribute(key: string) {
    return (a, b) => {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    };
  }

  public static exportCsv(csvData, csvExportName?, headerData?, dateFormatted?) {
    const csv = csvData ? Papa.unparse(csvData) : null;
    const header = headerData ? Papa.unparse(headerData) : null;
    if (csv) {
      const blobParts = [header || '', csv];
      const dateString = new Date().toISOString();
      csvExportName = csvExportName ? `${csvExportName}-${dateFormatted ? dateFormatted : dateString}.csv` :
        `csv-export-${dateFormatted ? dateFormatted : dateString}.csv`;
      Utils.exportBlob(blobParts, csvExportName);
    }
  }

  public static exportBlobCsv(csv, name?) {
    name = name || `csv-export-${new Date().toISOString()}.csv`;
    Utils.exportBlob([csv], name);
  }

  public static exportBlob(blobParts: any[], name?, type = 'text/plain') {
    const blob = new Blob(blobParts, {type: type});

    if (!name) {
      const dateString = new Date().toISOString();
      name = 'export-' + dateString + '.txt';
    }
    if (window.navigator.msSaveOrOpenBlob)  // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
      window.navigator.msSaveBlob(blob, name);
    else {
      const a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = name;
      document.body.appendChild(a);
      setTimeout(() => {
        a.click();
        document.body.removeChild(a);
      }, 0);
    }
  }

  /**
   * @param obj
   * @param key like 'publisher.widget.type'
   * @return value of object.publisher.widget.type
   */
  static getNestedValue(obj, key) {
    if (key) {
      return key.split('.').reduce((result, key2) => result ? result[key2] : null, obj);
    }
  }

  static getUrlParamsFrom(obj) {
    return Object.keys(obj).reduce((seed, current) => {
      if (seed) {
        seed += '&';
      }
      if (obj[current]) {
        seed += `${current}=${obj[current]}`;
      }
      return seed;
    }, '');
  }

  static pct(a: number, base: number) {
    return (a * 100 / (base || 1));
  }

  static generateAdUnitTag(adUnit: AdUnit) {
    return `<script type="text/javascript" id="${adUnit.widgetId}" src="${environment.cdnUrl}/${adUnit.widgetId}/index.js" charset="utf-8" async></script>`;
  }

  static exportAdsTxt(data: AdsTxtRecord[], withoutComment?: boolean) {
    let fileText = '';
    const date = new Date();

    data.forEach(unitData => {
      fileText = fileText + unitData.domain + ', ';
      fileText = fileText + unitData.publisherAccountId + ', ';
      fileText = fileText + unitData.accountType;
      if (unitData.certAuthority !== undefined) {
        fileText = fileText + ', ' + unitData.certAuthority;
      }
      if (unitData.comment !== undefined && withoutComment !== true) {
        fileText = fileText + ' #' + unitData.comment;
      }
      fileText += '\r\n';
    });

    const dateString = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
    const fileName = 'adstxt-' + dateString + '.txt';
    Utils.exportBlobCsv(fileText, fileName);
  }

  static sortAdsTxtDiffRecords(adsTxt: AdsTxtRecord[]) {
    if (adsTxt) {
      const ylUrlTemp = adsTxt.find(ads => ads.domain === 'yieldlift.com');
      if (ylUrlTemp) {
        adsTxt.splice(adsTxt.findIndex(ads => ads.domain === 'yieldlift.com'), 1);
        adsTxt.unshift(ylUrlTemp);
      }
    }

    return adsTxt;
  }

}
