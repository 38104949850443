<div mat-dialog-title class="mb-0">
  <span>{{ data?.domain?.url }} {{found()}} / {{total()}}</span>
</div>

<div mat-dialog-content class="p-0 mt-0">

  <div class="data-loader" *ngIf="loading">
    <mat-spinner [color]="'primary'"
                 [mode]="'indeterminate'"
                 [diameter]="30">
    </mat-spinner>
  </div>

  <div class="data-alert" [hidden]="loading || (dataSource.data && dataSource.data.length)">
    No data
  </div>

  <mat-table #table [dataSource]="dataSource" [hidden]="loading || !dataSource.data || !dataSource.data.length">
    <ng-container matColumnDef="demandPartnerName">
      <mat-header-cell *matHeaderCellDef>Dp name</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label">Dp name</span>
        <span>{{element?.demandPartnerName}}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index">
        <span class="mobile-label">Status</span>
        <span class="w-100">
          <mat-form-field [appearance]="'legacy'" class="w-100">
            <mat-select [(ngModel)]="dpComplianceApproval[i].status" [disabled]="data?.disabled">
              <mat-option *ngFor="let status of statusKL" [value]="status.key">{{status.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="adstxtStatus">
      <mat-header-cell *matHeaderCellDef>Ads.txt status</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label">Ads.txt status</span>
        <span *ngIf="calculateAdsTxtCondensed(element)"><i class="far fa-check text-success"></i></span>
        <span *ngIf="!calculateAdsTxtCondensed(element)"><i class="far fa-times text-danger"></i></span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="updatedOn">
      <mat-header-cell *matHeaderCellDef>Updated on</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label">Updated on</span>
        <span>
          {{element?.updatedOn | date: 'MM/dd/yyyy'}}
          <small class="d-block">{{element.updatedOn | date: 'hh:mm a'}}</small>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="submitted">
      <mat-header-cell *matHeaderCellDef>Submitted</mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index">
        <span class="mobile-label">Submitted</span>
        <span>
          <mat-checkbox [(ngModel)]="dpComplianceApproval[i].submitted" color="primary"
                        [matTooltip]="dpComplianceApproval[i].submittedDate | date: 'MM/dd/yyyy hh:mm a'"  [disabled]="data?.disabled"></mat-checkbox>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="comment">
      <mat-header-cell *matHeaderCellDef>Comment</mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index">
        <span class="mobile-label">Comment</span>
        <span class="w-100">
          <mat-form-field class="w-100" appearance="legacy">
            <input matInput [(ngModel)]="dpComplianceApproval[i].comment"  [disabled]="data?.disabled">
          </mat-form-field>
        </span>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns"></mat-row>
  </mat-table>
</div>

<div mat-dialog-actions>
  <button mat-button class="mr-3" [mat-dialog-close]="false">
    Close
  </button>
  <button mat-button class="iconed" color="primary" *ngIf="!data?.disabled"
          (click)="saveChanges()">
    <i class="fas fa-save"></i>
    <span>Save</span>
  </button>
</div>
