import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Component, HostBinding, Inject} from '@angular/core';

@Component({
  selector: 'cs-delete-dialog',
  template: `
    <h1 mat-dialog-title class="mb-auto">
      <i class="fas fa-exclamation-triangle mr-3"></i>
      {{data && data.title || 'Please confirm your action'}}
    </h1>
    <div mat-dialog-content class="mt-auto">
      <mat-list class="mat-dialog-data-list">
        <h3 *ngIf="data?.subtitle" mat-subheader>{{data?.subtitle}}</h3>
        <mat-list-item>{{data.item}}</mat-list-item>
      </mat-list>
    </div>
    <div mat-dialog-actions class="d-flex justify-content-end">
      <button mat-raised-button mat-dialog-close cdkFocusInitial>{{data?.cancelButtonText || 'CANCEL'}}</button>
      <button mat-raised-button color="{{data?.confirmButtonColor || 'warn'}}" [mat-dialog-close]="true">{{data?.confirmButtonText || 'Yes, delete!'}}</button>
    </div>
  `,
})
export class DeleteDialogComponent {
  @HostBinding('class.mat-dialog-warn') applyClazz = true;

  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {item: any, subtitle: string, title: string, confirmButtonColor: string, confirmButtonText: string, cancelButtonText: string}) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
