import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {Publisher} from '../../domain/publisher.model';
import {PublisherListFilter, PublishersService} from '../../services/publishers.service';
import {SortUtils} from '../../utils/sort-utils';
import {Principal} from '../../../core/auth/principal.service';

@Component({
  selector: 'cs-publisher-autocomplete',
  template: `
    <mat-form-field appearance="outline" [floatLabel]="floatLabel || 'always'" class="w-100" [ngClass]="formFieldClass">
      <mat-label *ngIf="floatLabel != 'never'">
        Choose publisher {{isRequired && filteredPublisherIds.length ? '*' : ''}}
      </mat-label>
      <input matInput
             #name
             [required]="isRequired && !filteredPublisherIds.length"
             aria-label="Publisher"
             [matAutocomplete]="auto"
             [formControl]="filterCtrl"
             placeholder="{{floatLabel != 'never' ? 'Search' : 'Choose Publisher'}}">
      <i class="fas fa-spinner fa-spin  text-primary" matSuffix [hidden]="!serverSearching"></i>
      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="optionSelected($event)"
                        [displayWith]="display">
        <mat-option *ngFor="let item of filteredItems | async" [value]="item">
          <!--<cm-publisher-status-circle class="mr-1" [status]="item.status"></cm-publisher-status-circle>-->
          {{item.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>`,
})
export class PublisherAutocompleteComponent implements OnInit {
  @ViewChild('name', {static: true}) nameFilter: ElementRef;

  @Input()
  public placeholder: string;

  @Input()
  public publisherId: string;

  @Input()
  public floatLabel: string;

  @Input()
  public isRequired: boolean;

  @Input()
  public formFieldClass: string;

  @Output()
  publisherSelected = new EventEmitter<Publisher>();

  serverSearching = false;

  publishers: Publisher[] = [];
  filteredItems: Subject<Publisher[]>;
  filterCtrl = new FormControl();
  selectedPublisher;
  filteredPublisherIds: string[] = [];

  constructor(private service: PublishersService, private principal: Principal) {
    //noinspection TypeScriptUnresolvedFunction
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(next => {
        this.filteredItems.next([]);
        if (!next && this.selectedPublisher) {
          this.selectedPublisher = undefined;
          this.emit();
        }

        if (next && typeof next !== 'object') {
          this.serverSearching = true;
          this.filterCtrl.disable();
          service.listPublishers(<PublisherListFilter>{
            page: 0,
            size: 10 +  this.filteredPublisherIds.length,
            name: next
          }).subscribe(resp => {
            let pubs = resp.content;
            pubs = pubs.filter(item => !this.filteredPublisherIds.includes(item.id));
            this.filteredItems.next(pubs.sort(SortUtils.propertyComparatorString('name')).splice(0, 10));
            this.filterCtrl.enable();
            this.nameFilter.nativeElement.focus();
            this.serverSearching = false;
          }, () => {
            this.filterCtrl.enable();
            this.nameFilter.nativeElement.focus();
            this.serverSearching = false;
          });
        }
      });
  }

  resetInput() {
    this.selectedPublisher = null;
    this.filterCtrl.setValue(null);
  }

  ngOnInit(): void {
    if (this.publisherId) {
      this.service.getPublisherById(this.publisherId).subscribe(next => {
        if (next) {
          this.selectedPublisher = next;
          this.filterCtrl.setValue(next);
          this.emit();
        }
      });
    }
  }

  display(p?: Publisher) {
    return p ? p.name : undefined;
  }

  optionSelected($event) {
    this.selectedPublisher = $event.option.value;
    this.filteredItems.next([]);
    this.emit();
  }

  updateFilteredPublishers(items) {
    this.filteredPublisherIds = items.map(item => item.id);
  }

  private emit() {
    this.publisherSelected.emit(this.selectedPublisher);
  }
}
