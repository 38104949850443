<mat-toolbar>
	<mat-toolbar-row class="h-auto">
		<div class="row align-items-center no-gutters w-100">
			<div class="col-6 col-lg">
				<div class="toolbar-logo">
					<img src="./assets/images/yieldlift-site-logo-icon-2.png" height="" width="40" title="YieldLift" />
				</div>
			</div>
			<div class="col-12 col-lg-auto order-2 order-lg-0">
				<nav mat-tab-nav-bar mat-align-tabs="center">
					<a mat-tab-link
					   *ngFor="let link of navLinks"
					   [routerLink]="link.path"
					   routerLinkActive #rla="routerLinkActive"
					   [active]="rla.isActive"
					   [disabled]="link.disabled"
						 matTooltip="{{link.tooltip}}">
						{{link.label}}
					</a>
				</nav>
			</div>
			<div class="col-6 col-lg">
				<div class="d-flex justify-content-end align-items-center">
					<div class="toolbar-user-info text-muted">
						<span class="toolbar-user-name">{{principal.user?.name}}</span>
						<small class="toolbar-user-company">{{principal.publisher?.name}}</small>
					</div>
					<button mat-mini-fab color="primary" [matMenuTriggerFor]="menu" class="mat-elevation-z0">
						<i class="fal fa-user" aria-hidden="true"></i>
					</button>
				</div>
				<mat-menu #menu="matMenu" [overlapTrigger]="true">
					<!--<button mat-menu-item disabled>-->
						<!--<i class="far fa-user"></i>-->
						<!--<span>Profile <small>(soon)</small></span>-->
					<!--</button>-->
          <button mat-menu-item *ngIf="principal.user?.roles.includes(userRole.CS_ADMIN)" (click)="changeUrl(null, true)">
            <span>Main Dashboard</span>
          </button>
          <mat-divider *ngIf="publishers"></mat-divider>
          <button mat-menu-item *ngFor="let publisher of publishers" (click)="changeUrl(publisher.id)">
            <span>{{publisher.name}}</span>
          </button>
          <mat-divider *ngIf="principal.user?.roles.includes(userRole.CS_ADMIN) || publishers"></mat-divider>
          <button mat-menu-item (click)="logout()">
            <i class="far fa-sign-out"></i>
            <span>Sign out</span>
          </button>
				</mat-menu>
			</div>
		</div>
	</mat-toolbar-row>

	<mat-toolbar-row class="">
		<ng-content></ng-content>
	</mat-toolbar-row>
</mat-toolbar>
