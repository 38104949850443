import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ReportRequest} from '../domain/rb/report-request.model';
import {Observable} from 'rxjs';
import {ReportResponse} from '../domain/rb/report-response.model';
import {DiscrepanciesRequest} from '../domain/discrepancies-request.model';
import {DiscrepanciesData} from '../domain/discrepancies-data.model';
import {UserReportConfig} from '../../modules/reports/saved/user-report-config.model';
import {ScheduledUserReport} from '../../modules/reports/saved/scheduled-user-report.model';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {

  constructor(private httpClient: HttpClient) {
  }

  report(request: ReportRequest): Observable<ReportResponse> {
    return this.httpClient.post<ReportResponse>(`${environment.serviceUrl}/report`, request);
  }

  discrepancies(request: DiscrepanciesRequest): Observable<DiscrepanciesData[]> {
    return this.httpClient.post<DiscrepanciesData[]>(`${environment.serviceUrl}/discrepancies`, request);
  }

  getUserConfigs(): Observable<UserReportConfig[]> {
    return this.httpClient.get<UserReportConfig[]>(`${environment.serviceUrl}/reports/user-report-configs`);
  }

  createUserConfig(config: UserReportConfig): Observable<UserReportConfig> {
    return this.httpClient.post<UserReportConfig>(`${environment.serviceUrl}/reports/user-report-configs`, config);
  }

  updateUserConfig(config: UserReportConfig): Observable<UserReportConfig> {
    return this.httpClient.put<UserReportConfig>(`${environment.serviceUrl}/reports/user-report-configs/${config.id}`, config);
  }

  deleteUserConfig(config: UserReportConfig): Observable<UserReportConfig> {
    return this.httpClient.delete<UserReportConfig>(`${environment.serviceUrl}/reports/user-report-configs/${config.id}`);
  }

  getSharedConfigs(): Observable<UserReportConfig[]> {
    return this.httpClient.get<UserReportConfig[]>(`${environment.serviceUrl}/reports/user-report-configs/shared`);
  }

  scheduledUserConfigsGet(): Observable<ScheduledUserReport[]> {
    return this.httpClient.get<ScheduledUserReport[]>(`${environment.serviceUrl}/reports/scheduled-user-reports`);
  }

  scheduledUserConfigsPost(c: ScheduledUserReport, config: UserReportConfig): Observable<ScheduledUserReport> {
    return this.httpClient.post<ScheduledUserReport>(`${environment.serviceUrl}/reports/scheduled-user-reports/${config.id}`, c);
  }

  scheduledUserConfigsPut(c: ScheduledUserReport): Observable<ScheduledUserReport> {
    return this.httpClient.put<ScheduledUserReport>(`${environment.serviceUrl}/reports/scheduled-user-reports/${c.id}`, c);
  }

  scheduledUserConfigsDelete(c: ScheduledUserReport): Observable<ScheduledUserReport> {
    return this.httpClient.delete<ScheduledUserReport>(`${environment.serviceUrl}/reports/scheduled-user-reports/${c.id}`);
  }

}

