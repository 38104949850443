import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild} from '@angular/core';
import {ReportMetric, ReportMetricDetails, ReportMetricsAll} from '../../../domain/rb/report-metric.enum';
import {DateRangeHolder} from '../../../domain/rb/date-range-holder.model';
import {ReportRequestPresets} from '../../../domain/rb/report-request-presets.model';
import {Principal} from '../../../../core/auth/principal.service';
import {SummedMasterReport} from '../../../domain/rb/summed-master-report.model';
import * as Highcharts from 'highcharts';
import {Utils} from '../../../utils/utils';
import {MomentDateUtils} from '../../../utils/moment-date-utils';
import {ReportDimension} from '../../../domain/rb/report-dimension.enum';
import {DashboardService} from '../../../services/dashboard.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'cs-daily-metrics-chart',
  template: `
    <div class="card-message"
         [hidden]="chartOptions">
      No data
    </div>
    <div class="p-4" [hidden]="!chartOptions">
      <div #chart class="w-100"></div>
    </div>
  `
})
export class DailyMetricsChartComponent implements OnChanges, OnDestroy {
  @ViewChild('chart', {static: true}) chartEl: ElementRef;
  Highcharts = Highcharts;
  chartOptions;
  subscription: Subscription;
  @Input()
  metrics: ReportMetric[];

  @Input()
  dateRange: DateRangeHolder;

  @Input()
  publisherIds: string[];

  @Output() loading = new EventEmitter();

  reportMetricDetails: ReportMetricDetails[] = [];

  constructor(private service: DashboardService,
              private principal: Principal) { }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnChanges() {
    this.reportMetricDetails = ReportMetricsAll().filter(m => this.metrics.includes(m.key));
    // this.loadChartData();
  }

  loadChartData() {
    const report = ReportRequestPresets.DAILY_COMBINED;
    report.metrics = this.metrics;
    report.dimensions = [ReportDimension.DAY];
    Object.assign(report.filters, {
      dateFrom: this.dateRange.dateFrom,
      dateTo: this.dateRange.dateTo,
      publisherIds: this.publisherIds
    });
    this.loading.emit(true);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.service.report(report).subscribe(next => {
      next.report.length ? this.renderChart(next.report) : this.chartOptions = null;
      this.loading.emit(false);
    }, (error) => {
      this.loading.emit(false);
    });
  }

  private renderChart(dataReport: SummedMasterReport[]) {
    dataReport.sort(Utils.sortComparatorByAttribute('day'));
    this.chartOptions = {
      chart: {
        type: 'spline',
        zoomType: 'xy',
        height: 472,
        animation: false
      },

      title: {
        text: ''
      },

      yAxis: this.reportMetricDetails.map(details => {
        return {
          title: {
            text: details.label,
          },
          opposite: false
        };
      }),

      tooltip: {
        shared: true,
        animation: false
      },

      xAxis: {
        crosshair: true,
        categories: dataReport.map(d => MomentDateUtils.format(d.day, 'MM/DD/YYYY')),
        labels: {
          step: dataReport.length > 150 ? 10 : dataReport.length > 50 ? 5 : dataReport.length > 20 ? 2 : 1,
          rotation: -45
        }
      },

      series: this.reportMetricDetails.map((details, index) => {
        const settings = {
          name: details.label,
          yAxis: index,
          lineWidth: 1.5,
          marker: {
            enabled: false
          }
        };
        switch (details.key) {
          case ReportMetric.FILLED:
          case ReportMetric.MARGIN:
            Object.assign(settings, {
              data: dataReport.map(item => (item[details.field] || 0) * 100),
            });
            break;
          default:
            Object.assign(settings, {
              data: dataReport.map(item => item[details.field]),
            });
        }
        switch (details.key) {
          case ReportMetric.REFRESH_RATE:
            Object.assign(settings, {
              tooltip: {
                valueDecimals: 2
              },
            });
            break;
          case ReportMetric.FILLED:
          case ReportMetric.MARGIN:
            Object.assign(settings, {
              tooltip: {
                valueDecimals: 2,
                valueSuffix: '%'
              },
            });
            break;
          case ReportMetric.PROFIT:
          case ReportMetric.REVENUE:
          case ReportMetric.TOTAL_REVENUE:
          case ReportMetric.TOTAL_RPM:
            Object.assign(settings, {
              tooltip: {
                valueDecimals: 2,
                valuePrefix: '$'
              },
            });
            break;
        }
        return settings;
      })
    };

    Highcharts.chart(this.chartEl.nativeElement, this.chartOptions);
  }

}
