import {KeyLabel} from '../key-label.model';
export enum Operator {
  GT = 'GT',
  GE = 'GE',
  LT = 'LT',
  LE = 'LE',
  EQ = 'EQ'
}

export const OperatorsAll = Object.freeze([
  Object.freeze(new KeyLabel<Operator>(Operator.GT, '>')),
  Object.freeze(new KeyLabel<Operator>(Operator.GE, '>=')),
  Object.freeze(new KeyLabel<Operator>(Operator.LT, '<')),
  Object.freeze(new KeyLabel<Operator>(Operator.LE, '<=')),
  Object.freeze(new KeyLabel<Operator>(Operator.EQ, '=')),
]);

export const OperatorLabels = Object.freeze(OperatorsAll.reduce((prev, curr) => {
  prev[curr.key] = curr.label;
  return prev;
}, {}));
