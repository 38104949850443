import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../auth/user.model';
import {GlobalSpinnerService} from '../services/global-spinner.service';
import {SnackBarService} from '../services/snackbar.service';
import {environment} from '../../../environments/environment';
import {PasswordResetData, UsersService} from '../../shared/services/users.service';

@Component({
  selector: 'cs-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public user = new User();
  public regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})');
  public password = new PasswordResetData();

  constructor(private httpClient: HttpClient,
              private route: ActivatedRoute,
              private router: Router,
              private service: UsersService,
              private spinnerService: GlobalSpinnerService,
              private snackBarService: SnackBarService) {
  }


  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.password.confirmationId = params['cid'];
        console.log(this.password);
      });
  }

  public reset() {
    this.spinnerService.showLoader();
    this.service.confirmPasswordReset(this.password).subscribe(next => {
      this.snackBarService.success('Password reset successful');
      this.spinnerService.hideLoader();
      this.router.navigate(['/login']);
    }, () => {
      this.snackBarService.error('Invalid password submitted, please try again');
      this.spinnerService.hideLoader();
    });
  }
}
