<div class="app-select">

  <div class="col p-0 text-right">
    <button  type="button" mat-raised-button color="primary" class="iconed large mr-3 mt-3" (click)="logout()">
      <i class="far fa-sign-out"></i>
      <span>Sign out</span>
    </button>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <div class="login-header text-center">
          <!--<img src="./assets/images/yieldlift-site-logo.png" height="75" alt="YieldLift network"
               title="YieldLift network"/>-->
          <!--<h2>Ad Unit Manager</h2>-->
        </div>
      </div>
    </div>
    <div class="row justify-content-center" [hidden]="loading">

      <div class="col-12 mb-5 col-md-6 col-lg-5"
           [hidden]="!principal.user?.roles.includes('CS_ADMIN')">
        <div class="app-select-group">
          <h3 class="app-select-group-title">Main Dashboard</h3>
          <mat-card class="mb-3">
            <mat-card-header (click)="mainDashboard()">
              <div mat-card-avatar class="">
                <i class="fal fa-user-tie"></i>
              </div>
              <mat-card-title class="text-uppercase">YieldLift</mat-card-title>
              <div class="mat-card-header-action">
                <i class="fas fa-chevron-right" aria-hidden="true"></i>
              </div>
            </mat-card-header>
          </mat-card>
        </div>
      </div>

      <div class="col-12 mb-5 col-md-6 col-lg-5"
           [hidden]="!principal.user?.publisherIds || !principal.user?.publisherIds.length">
        <div class="app-select-group">
          <h3 class="app-select-group-title">Publisher Dashboards</h3>
          <div *ngIf="publishers?.length">
            <mat-card *ngFor="let p of publishers" class="mb-3">
              <mat-card-header (click)="selectPublisher(p.id)">
                <div mat-card-avatar class="">
                  <i class="fal fa-user"></i>
                </div>
                <mat-card-title class="text-uppercase">{{p.name}}</mat-card-title>
                <!--<mat-card-subtitle>Network: OpsCo</mat-card-subtitle>-->
                <div class="mat-card-header-action">
                  <i class="fas fa-chevron-right" aria-hidden="true"></i>
                </div>
              </mat-card-header>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
