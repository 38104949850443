import {Publisher} from '../../shared/domain/publisher.model';
import {Network} from '../../shared/domain/network.model';
import {Partner} from '../../shared/domain/partner.model';

export class User {
  id: string;
  accessToken?: string;
  username?: string;
  email: string;
  name: string;
  publisherIds: string[];
  partnerIds: string[];
  publishers: Publisher[];
  ext_publisherNames: string;
  roles: string[];
  rights: string[];
  password: string;
  status: string;
  createdOn: string;
  lastUpdatedOn: string;
  partnerNames?: string[];
  partner?: Partner
}
