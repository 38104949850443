import {Observable} from 'rxjs';
import { Injectable } from "@angular/core";
@Injectable()
export class GlobalSpinnerService {

  private counter = 0;

  constructor() {
  }

  hideLoader() {
    if (this.counter > 0) {
      this.counter--;
    }
    if (this.counter === 0) {
      this.getElement().classList.remove('show');
    }
  }

  showLoader() {
    this.counter++;
    this.getElement().classList.add('show');
  }

  wrap<T>(o: Observable<T>): Observable<T> {
    this.showLoader();
    o.subscribe(() => ({}), () => this.hideLoader(), () => this.hideLoader());
    return o;
  }

  private getElement() {
    return document.querySelector('.cm-global-spinner-background');
  }
}
