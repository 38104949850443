<cs-cs-widget [menuVisible]="menuVisible">
  <cs-widget-title>
    {{selectedChartType}}
  </cs-widget-title>
  <cs-widget-actions>
    <div class="widget-actions-group d-flex align-items-center">
      <cs-report-metric-multiselect-search-dropdown
        *ngIf="selectedChartType === chartTypes.DAILY_STATS"
        [selectedMetrics]="dailySettings.selectedMetrics"
        (selectionChange)="metricsSelectionChange($event)"
        [availableMetrics]="dailySettings.availableMetricsDaily">
      </cs-report-metric-multiselect-search-dropdown>
    </div>
  </cs-widget-actions>
  <cs-widget-content class="mat-card-content mat-card-content--full">
    <mat-divider></mat-divider>
    <div class="card-message" *ngIf="loading">
      <mat-spinner [color]="'primary'" [mode]="'indeterminate'" [diameter]="30"></mat-spinner>
    </div>
    <cs-daily-metrics-chart #dailyChart
                            *ngIf="selectedChartType === chartTypes.DAILY_STATS"
                            [hidden]="loading"
                            [metrics]="dailySettings.selectedMetrics"
                            [dateRange]="dailySettings.dateRange"
                            [publisherIds]="selectedPublisherIds"
                            (loading)='dailySettings.loading($event)'>
    </cs-daily-metrics-chart>
  </cs-widget-content>
</cs-cs-widget>
