import {Injectable} from '@angular/core';
import {CanLoad, Route} from '@angular/router';
import {Observable} from 'rxjs';
import {Principal} from '../principal.service';
import {AuthService} from '../auth.service';

@Injectable()
export class RoleCanLoad implements CanLoad {

  constructor(private principal: Principal,
              private authService: AuthService) {
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    const roles = route.data['roles'];
    console.log('RoleCanLoad:: %o', roles);
    if (roles) {
      return new Observable(subscriber => {
        this.authService.identity()
          .subscribe(
            id => {
              if (id) {
                subscriber.next(this.principal.hasAnyAuthority(roles));
                subscriber.complete();
              } else {
                subscriber.next(false);
                subscriber.complete();
              }
            },
            () => {
              subscriber.next(false);
              subscriber.complete();
            }
          );
      });

    }
    return true;
  }

}
