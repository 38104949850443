import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {HttpUtils} from '../utils/http-utils';
import {ConfiantPropertyListItem} from '../domain/confiant-property-list-item.model';
import {ConfiantProperty} from '../domain/confiant-property.model';

@Injectable()
export class ConfiantService {

  constructor(private http: HttpClient) {
  }

  confiantPropertyList(includeSpec = false): Observable<ConfiantPropertyListItem[]> {
    return this.http.get<ConfiantPropertyListItem[]>(`${environment.serviceUrl}/confiant/property`, {
      params: HttpUtils.getHttpParams({
        includeSpec: includeSpec
      })
    });
  }

  confiantPropertyCreate(name: string): Observable<ConfiantProperty> {
    return this.http.post<ConfiantProperty>(`${environment.serviceUrl}/confiant/property`, name);
  }

  confiantBannedDomainsGet(id: string): Observable<string[]> {
    return this.http.get<string[]>(`${environment.serviceUrl}/confiant/property/${id}/banned-domains`);
  }

  confiantBannedDomainsAdd(id: string, domains: string[]): Observable<string[]> {
    return this.http.put<string[]>(`${environment.serviceUrl}/confiant/property/${id}/add-banned-domains`, domains);
  }
  confiantBannedDomainsRemove(id: string, domains: string[]): Observable<string[]> {
    return this.http.put<string[]>(`${environment.serviceUrl}/confiant/property/${id}/delete-banned-domains`, domains);
  }

}
