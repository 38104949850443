import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Utils } from '../../utils/utils';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'cs-search-control',
  templateUrl: './search-control.component.html',
  styleUrls: ['./search-control.component.scss']
})
export class SearchControlComponent implements OnInit {

  @Input() set searchText(value: string) {
    if (!Utils.isNullOrUndefined(value) && value !== this.searchFormControl.value) {
      this.searchFormControl.setValue(value, { emitEvent: false });
    }
  }

  @Output() searchChange = new EventEmitter<string>();

  @Input() searchValue: string;

  @Input()
  public floatLabel: string;

  @Input()
  public placeholder: string;

  searchFormControl = new FormControl();

  constructor() { }

  ngOnInit(): void {
    this.setSearchControl();
  }

  setSearchControl() {
    this.searchFormControl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(data => {
        this.searchChange.next(data);
      });
  }
  reset() {
    this.searchFormControl.patchValue('');
  }
}
