import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {Publisher, PublisherActivityStatus, PublisherAdsTxtList, PublisherStatus} from '../domain/publisher.model';
import {Page} from '../domain/page.model';
import {HttpUtils} from '../utils/http-utils';
import {PageSortRequest} from '../domain/page-sort-request.model';
import {PublisherDomain, PublisherDomainManagement, PublisherDomainStatus} from '../domain/publisher-domain.model';
import {AdsTxtDiff} from '../domain/adstxt-diff';
import {Principal} from '../../core/auth/principal.service';
import {ReportMetric} from '../domain/rb/report-metric.enum';
import {AdsTxtDomainRecordPair, AdsTxtStatusPerDpAndUrl} from '../domain/adstxt-record.model';
import {AdsTxtByDpFilter} from './ads-txt.service';

@Injectable({
  providedIn: 'root',
})
export class PublishersService {

  constructor(private httpClient: HttpClient,
              private principal: Principal) {
  }

  listPublishers(filter: PublisherListFilter): Observable<Page<Publisher>> {
    return this.httpClient.get<Page<Publisher>>(`${environment.serviceUrl}/publishers`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  getPublisherById(id: string): Observable<Publisher> {
    return this.httpClient.get<Publisher>(`${environment.serviceUrl}/publishers/${id}`);
  }

  getPublisherByIds(ids: string[]): Observable<Publisher[]> {
    return this.httpClient.get<Publisher[]>(`${environment.serviceUrl}/publishers/get-by-ids`, {
      params: HttpUtils.getHttpParams({
        publisherIds: ids
      })
    });
  }

  createPublisher(pub: Publisher): Observable<Publisher> {
    return this.httpClient.post<Publisher>(`${environment.serviceUrl}/publishers`, pub);
  }

  updatePublisherById(pub: Publisher): Observable<Publisher> {
    return this.httpClient.put<Publisher>(`${environment.serviceUrl}/publishers/${pub.id}`, pub);
  }

  updatePublisherConfiant(pub: Publisher): Observable<Publisher> {
    return this.httpClient.put<Publisher>(`${environment.serviceUrl}/publishers/${pub.id}/confiant`, {
      confiantEnabled: pub.confiantEnabled,
      confiantPropertyId: pub.confiantPropertyId,
      confiantWrapperId : pub.confiantWrapperId
    });
  }

  publisherDomainsPaging(filter: PublisherDomainListFilter): Observable<Page<PublisherDomain>> {
    return this.httpClient.get<Page<PublisherDomain>>(`${environment.serviceUrl}/publishers/${filter.publisherId}/domains/paging`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  domainsPaging(filter: PublisherDomainListFilter): Observable<Page<PublisherDomain>> {
    return this.httpClient.get<Page<PublisherDomain>>(`${environment.serviceUrl}/publishers/domains/paging`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  publisherDomainsAll(publisherId: string): Observable<PublisherDomain[]> {
    return this.httpClient.get<PublisherDomain[]>(`${environment.serviceUrl}/publishers/${publisherId}/domains`);
  }

  publisherDomainCreate(publisherId: string, domains: PublisherDomain[]): Observable<PublisherDomain[]> {
    return this.httpClient.post<PublisherDomain[]>(`${environment.serviceUrl}/publishers/${publisherId}/domains`, domains);
  }

  publisherDomainUpdate(d: PublisherDomain): Observable<PublisherDomain> {
    return this.httpClient.put<PublisherDomain>(`${environment.serviceUrl}/publishers/${d.id.publisherId}/domains`, {
      publisherId: d.id.publisherId,
      url: d.id.url,
      confiantEnabled: d.confiantEnabled
    });
  }

  publisherDomainAdstxtScan(d: PublisherDomain[]): Observable<void> {
    return this.httpClient.post<void>(`${environment.serviceUrl}/publishers/${d[0].id.publisherId}/adstxt-scan`, {
      urls: d.map(x => x.id.url)
    });
  }

  publisherDomainManagementUpdate(d: PublisherDomain[], m: PublisherDomainManagement): Observable<PublisherDomain[]> {
    return this.httpClient.put<PublisherDomain[]>(`${environment.serviceUrl}/publishers/${d[0].id.publisherId}/domain-management`, {
      urls: d.map(x => x.id.url),
      management: m
    });
  }

  savePublisherCompanyDetails(publisher: Publisher): Observable<Publisher> {
    return this.httpClient.put<Publisher>(`${environment.serviceUrl}/publishers/${publisher.id}/company`, publisher);
  }

  getPublisherAdstxtPerDomain(pub: Publisher): Observable<AdsTxtDiff[]> {
    return this.httpClient.get<AdsTxtDiff[]>(`${environment.serviceUrl}/publishers/${pub.id}/adstxt`);
  }

  domainsAdstxtDiff(domain: PublisherDomain): Observable<AdsTxtDiff> {
    return this.httpClient.get<AdsTxtDiff>(`${environment.serviceUrl}/publishers/${domain.id.publisherId}/${domain.id.url}/adstxtdiff`);
  }

  publisherDomainChangeStatus(status: string, publisherId: string, url: string) {
    return this.httpClient.put<PublisherDomain>(`${environment.serviceUrl}/publishers/${publisherId}/domains/${status}`, {
      publisherId: publisherId,
      url: url
    });
  }

  getTopPublishers(dateFrom: string, dateTo: string): Observable<{key: string, value: ReportMetric.REVENUE}[]> {
    return this.httpClient.get<{key: string, value: ReportMetric.REVENUE}[]>(`${environment.serviceUrl}/dashboard/top-publishers`, {
      params: HttpUtils.getHttpParams({
        dateFrom: dateFrom,
        dateTo: dateTo
      })
    });
  }

  getLastAdstxtScanTime(): Observable<string> {
    return this.httpClient.get<string>(`${environment.serviceUrl}/publishers/domains/adstxt-scan-time`);
  }

  getMissingAdstxtPerDp(filter: AdsTxtByDpFilter): Observable<AdsTxtDomainRecordPair[]> {
    return this.httpClient.get<AdsTxtDomainRecordPair[]>(`${environment.serviceUrl}/publishers/domains/adstxt-missing-per-dp`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  getAdstxtStatusPerDpAndUrl(demandPartner: string, urls: string[]): Observable<AdsTxtStatusPerDpAndUrl[]> {
    return this.httpClient.get<AdsTxtStatusPerDpAndUrl[]>(`${environment.serviceUrl}/publishers/domains/adstxt-status-per-dp-and-url`, {
      params: HttpUtils.getHttpParams({
        demandPartner: demandPartner,
        urls: urls
      })
    });
  }

}

export class PublisherListFilter extends PageSortRequest {
  page?: number;
  size?: number;
  name?: string;
  status?: PublisherStatus | PublisherStatus[];
  activityStatus?: PublisherActivityStatus;
  mcmActive?: Boolean;
  partnerIds: string[];
  adsTxtList?: PublisherAdsTxtList;
  management?: PublisherDomainManagement;
  tier?: number;
}

export class PublisherDomainListFilter extends PageSortRequest {
  publisherId?: string;
  url: string;
  status?: PublisherDomainStatus[] | PublisherDomainStatus;
}
