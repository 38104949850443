import {Injectable} from '@angular/core';
import {CurrencyPipe, DatePipe, DecimalPipe} from '@angular/common';
import {CsTableColumn} from './cs-table-column.model';
import {SummedMasterReport} from '../../domain/rb/summed-master-report.model';
import {ReportMetric, ReportMetricFormat, ReportMetricsAll} from '../../domain/rb/report-metric.enum';
import { repeat } from 'rxjs/operators';

@Injectable()
export class CsTableColumnCommonService {

  private metrics: { [key: string]: CsTableColumn } = {};

  constructor(private decimalPipe: DecimalPipe,
              private currencyPipe: CurrencyPipe,
              private datePipe: DatePipe) {
    ReportMetricsAll().forEach(m => {
      this.metrics[m.key] = <CsTableColumn>{
        key: m.field,
        header: m.label,
        sortable: true,
        description: m.description,
        format: m.format,
        cell: (row: SummedMasterReport, field?: string) => {
          const value = row[field || m.field];
          if (value === null || typeof value === 'undefined') {
            switch (m.key) {
              case ReportMetric.NET_PROFIT:
              case ReportMetric.PROFIT:
              case ReportMetric.PARTNER_AD_SERVING_FEE:
              case ReportMetric.PARTNER_PROFIT:
              case ReportMetric.PARTNER_NET_PROFIT: {
                return this.currencyPipe.transform(0, 'USD', 'symbol', '1.2-2');
              }
              case ReportMetric.PUBLISHER_CODE:
                return 'N/A';
              default: {
                return '-';
              }
            }
          }
          switch (m.format) {
            case ReportMetricFormat.PERCENTAGE: {
              return decimalPipe.transform(value * 100, '1.2-2') + '%';
            }
            case ReportMetricFormat.PERCENTAGE_PLAIN: {
              return decimalPipe.transform(value, '1.2-2') + '%';
            }
            case ReportMetricFormat.DECIMAL: {
              return decimalPipe.transform(value, '1.2-2');
            }
            case ReportMetricFormat.NUMBER: {
              return decimalPipe.transform(value, '1.0-0');
            }
            case ReportMetricFormat.DATE_TIME: {
              return this.datePipe.transform(value, 'yyyy-MM-dd HH:mm');
            }
            case ReportMetricFormat.CURRENCY: {
              return this.currencyPipe.transform(value, 'USD', 'symbol', '1.2-2');
            }
            default:
              switch (m.key) {
                case ReportMetric.AD_UNIT:
                  return `${value} ${row.bidder ? ' <span class="badge ml-2">B</span>' : ''} ${row.s2s ? ' <span class="badge ml-2">s2s</span>' : ''}`;
                case ReportMetric.PUBLISHER:
                  return `${value} ${row.partnerName ? ' <span class="badge ml-2">P</span>' : ''}`;
                case ReportMetric.S2S:
                  return row.s2s ? 'Yes' : 'No';
                case ReportMetric.BIDDER:
                  return row.bidder ? 'Yes' : 'No';
                case ReportMetric.ADHESION:
                    return row.adhesion ? 'Yes' : 'No';
                case ReportMetric.PUBLISHER_CODE:
                    return row.publisherCode.length ? row.publisherCode : 'N/A';
                default:
                  return value;
              }
          }
        }
      };
    });
  }

  metric(metric: ReportMetric) {
    return this.metrics[metric];
  }

  getAllForReportMetrics(): CsTableColumn[] {
    return Object.keys(this.metrics).map(key => this.metrics[key]);
  }
}
