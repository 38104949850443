import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {HttpUtils} from '../utils/http-utils';
import {Observable} from 'rxjs';
import {Partner, PartnerListFilter} from '../domain/partner.model';
import {Page} from '../domain/page.model';
import {User} from '../../core/auth/user.model';

@Injectable({
  providedIn: 'root',
})
export class PartnersService {

  constructor(private httpClient: HttpClient) {
  }

  get(filter: PartnerListFilter): Observable<Page<Partner>> {
    return this.httpClient.get<Page<Partner>>(`${environment.serviceUrl}/partners`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  getPartnerById(id: string): Observable<Partner> {
    return this.httpClient.get<Partner>(`${environment.serviceUrl}/partners/${id}`);
  }

  checkPartnerAdsTxtMissingRecords() {
    return this.httpClient.get(`${environment.serviceUrl}/partners/adstxtmissing`);
  }

  createPartner(data): Observable<Partner> {
    return this.httpClient.post<Partner>(`${environment.serviceUrl}/partners`, data);
  }

  updatePartner(id, data): Observable<Partner> {
    return this.httpClient.put<Partner>(`${environment.serviceUrl}/partners/${id}`, data);
  }

  getPartnerUsers(id: string): Observable<User[]> {
    return this.httpClient.get<User[]>(`${environment.serviceUrl}/partners/${id}/users`);
  }
}
