import {Injectable} from '@angular/core';
import {Principal} from '../principal.service';
import {UserRole} from '../user-role.model';

export enum RolePermission {
  DASHBOARD_MODULE = 'DASHBOARD_MODULE',
  DASHBOARD_TABLE_ACTIONS = 'DASHBOARD_TABLE_ACTIONS',
  REPORTING_MODULE = 'REPORTING_MODULE',
  REPORTING_ERROR_MODULE = 'REPORTING_ERROR_MODULE',
  ANALYTICS_MODULE = 'ANALYTICS_MODULE',
  PUBLISHERS_MODULE = 'PUBLISHERS_MODULE',
  AD_UNITS_MODULE = 'AD_UNITS_MODULE',
  DEMAND_PARTNERS_MODULE = 'DEMAND_PARTNERS_MODULE',
  WIDGET_TYPES_MODULE = 'WIDGET_TYPES_MODULE',
  ADS_TXT_MODULE = 'ADS_TXT_MODULE',
  ADS_TXT_RECORDS = 'ADS_TXT_RECORDS',
  DISCREPANCIES_MODULE = 'DISCREPANCIES_MODULE',
  FINANCES_MODULE = 'FINANCES_MODULE',
  CHANGELOG_MODULE = 'CHANGELOG_MODULE',
  USERS_MODULE = 'USERS_MODULE',
  PARTNERS_MODULE = 'PARTNERS_MODULE',
  SCHEDULED_REPORTS_MODULE = 'SCHEDULED_REPORTS_MODULE',
}

const RolesWithPermissions = {

  [UserRole.CS_ADMIN]: Object.keys(RolePermission), // all
  [UserRole.CS_PARTNER]: [
    RolePermission.DASHBOARD_MODULE,
    RolePermission.REPORTING_MODULE,
    RolePermission.ADS_TXT_MODULE
  ]
};

@Injectable()
export class RolePermissionService {

  constructor(private principal: Principal) {
  }

  hasPermissions(permissions: RolePermission[]) {
    const roles = this.principal.getUser().roles;
    if (permissions && permissions.length) {
      if (roles) {
        return roles.some(role => {
          const permissionsForRole = <RolePermission[]>RolesWithPermissions[role];
          return permissionsForRole && permissionsForRole.some(p => permissions.includes(p));
        });
      }
    }
    return false;
  }

  hasPermission(per: RolePermission) {
    return this.hasPermissions([per]);
  }
}

