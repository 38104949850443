import {AfterViewInit, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ClipboardService} from 'ngx-clipboard';
import {SnackBarService} from '../../../../core/services/snackbar.service';
import {AdUnit} from '../../../domain/ad-unit.model';
import {MatTableDataSource} from '@angular/material/table';
import {GlobalSpinnerService} from '../../../../core/services/global-spinner.service';
import {PublishersService} from '../../../services/publishers.service';
import {DomainAdsTxtDetailsPreviewDialogComponent} from '../domain-ads-txt-details-preview/domain-ads-txt-details-preview-dialog.component';
import {PublisherDomainsComplianceModel} from '../../../domain/publisher-compliance.model';
import {
  DpUpdateComplianceStatusDialogComponent
} from '../../../../modules/demand-partners/dp-compliance/dp-compliance-by-publisher/dp-update-compliance-status-dialog/dp-update-compliance-status-dialog.component';
import {AdsTxtStatusPerDpAndUrl} from '../../../domain/adstxt-record.model';
import {DpDomainComplianceStatus} from '../../../domain/demand-partner-compliance.model';

@Component({
  selector: 'cs-ad-unit-domain-ads-txt-preview-dialog',
  templateUrl: './ad-unit-domain-ads-txt-preview-dialog.component.html',
  styleUrls: ['./ad-unit-domain-ads-txt-preview-dialog.component.scss']
})
export class AdUnitDomainAdsTxtPreviewDialogComponent implements AfterViewInit {

  adUnit: AdUnit;
  demandPartnerName: string;
  dataSource = new MatTableDataSource<AdsTxtStatusPerDpAndUrl>();
  columns = ['domain', 'adsTxtMissingRecords', 'adsTxtCondensed'];

  constructor(public dialogRef: MatDialogRef<AdUnitDomainAdsTxtPreviewDialogComponent>,
              public snackBarService: SnackBarService,
              private clipboardService: ClipboardService,
              private sbs: SnackBarService,
              private gss: GlobalSpinnerService,
              private publisherService: PublishersService,
              private matDialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: {adUnit: AdUnit, demandPartnerName: string }) {
    this.adUnit = data.adUnit;
    this.demandPartnerName = data.demandPartnerName;
  }

  loadResults() {
    this.gss.showLoader();
    this.publisherService.getAdstxtStatusPerDpAndUrl(this.demandPartnerName,
                                                     this.adUnit.publisherDomains.map(d => d.id.url)).subscribe(next => {
      this.dataSource.data = next;
      this.gss.hideLoader();
    }, () => {
      this.gss.hideLoader();
    });
  }

  ngAfterViewInit() {
    this.dialogRef.updateSize('500px');
    this.loadResults();
  }

  showAdsTxtDetails(url: string) {
    const publisherDomain = this.adUnit.publisherDomains.filter(pd => pd.id.url === url)[0];
    this.gss.showLoader();
    this.publisherService.domainsAdstxtDiff(publisherDomain).subscribe(next => {
      this.gss.hideLoader();
      this.matDialog.open(DomainAdsTxtDetailsPreviewDialogComponent, <MatDialogConfig>{
        data: {
          domain: publisherDomain,
          demandPartnerName: this.demandPartnerName,
          result: next
        }
      });
    }, () => {
      this.gss.hideLoader();
    });
  }

  updateComplianceStatus(url: string) {
    const filter = new PublisherDomainsComplianceModel();
    filter.url = url;
    this.matDialog.open(DpUpdateComplianceStatusDialogComponent, <MatDialogConfig>{
      data: {domain: filter, disabled: false}
    });
  }

  determineClass(adsTxtStatus: AdsTxtStatusPerDpAndUrl) {
    if (adsTxtStatus) {
      switch (adsTxtStatus.complianceStatus) {
        case DpDomainComplianceStatus.APPROVED:
          return 'text-success';
        case DpDomainComplianceStatus.REJECTED:
          return 'text-danger';
      }
      if (adsTxtStatus.submitted) {
        return 'yellow';
      }
    }
    return '';
  }
}
