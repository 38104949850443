import {KeyLabel} from './key-label.model';
import {PageSortRequest} from './page-sort-request.model';

export class DemandPartnerComplianceModel {
  demandPartner: string;
  approved: number;
  pendingNotSubmitted: number;
  pendingSubmitted: number;
  rejected: number;
  total: number;
  updatedOn: Date;
}

export class DpDomainComplianceModel {
  id: number;
  url: string;
  demandPartnerName: string;
  status: DpDomainComplianceStatus;
  submitted: boolean;
  submittedDate: Date;
  whitelisted: boolean;
  unitsAffected: number;
  createdBy: string;
  publisher: string;
  publisherId: string;
  comment: string;
  createdOn: Date;
  updatedOn: Date;
}

export enum DpDomainComplianceStatus {
  N_A = 'N_A',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED'
}

export const DpDomainComplianceStatusLabels = {
  N_A: 'N/A',
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected'
};

export const DpDomainComplianceStatusKL = () =>
  (Object.keys(DpDomainComplianceStatus).map(key => new KeyLabel(<DpDomainComplianceStatus>key, DpDomainComplianceStatusLabels[key])));

export class DpDomainComplianceFilter extends PageSortRequest {
  demandPartnerName: string;
  url: string;
}
