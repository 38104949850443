import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {User} from '../auth/user.model';
import {AuthService} from '../auth/auth.service';

@Injectable()
export class LoginService {

  constructor(private authService: AuthService,
              private router: Router) {
  }

  isLoggedIn() {
    return this.authService.isAuthenticated();
  }

  login(credentials: User) {
    return new Promise((resolve, reject) => {
      this.authService.login(credentials).subscribe((data) => {
        resolve(data);
      }, (err) => {
        this.logout();
        reject(err);
      });
    });
  }

  logout(navigateToLogin = false) {
    this.authService.logout().subscribe();
    if (navigateToLogin) {
      this.router.navigate(['/login']);
    }
  }

  logoutWithReturnUrl(returnUrl) {
    this.authService.logout().subscribe();
    this.router.navigate(['/login'], {
      queryParams: {
        returnUrl: returnUrl
      }
    });
  }
}
