import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {AdUnit, AdUnitActivityStatus, AdUnitStatus} from '../domain/ad-unit.model';
import {Page} from '../domain/page.model';
import {HttpUtils} from '../utils/http-utils';
import {CreateAdUnitRequest} from '../domain/create-ad-unit-request.model';
import {UnitBuildConfig} from '../../modules/ad-units/components/builder/unit-config.model';
import {PageSortRequest} from '../domain/page-sort-request.model';
import {UnitConfig} from '../domain/unit-config.model';
import {ReportMetric} from '../domain/rb/report-metric.enum';
import {Principal} from '../../core/auth/principal.service';
import {AdUnitTag} from '../domain/ad-unit-tag';
import {AdUnitDpDetails} from '../domain/ad-unit-dp-details.model';
import {AdUnitDemandPartner} from '../domain/ad-unit-demand-partner.model';

@Injectable({
  providedIn: 'root',
})
export class AdUnitsService {

  constructor(private httpClient: HttpClient,
              private principal: Principal) {
  }

  listAdUnits(filter: AdUnitsListFilter): Observable<Page<AdUnit>> {
    return this.httpClient.get<Page<AdUnit>>(`${environment.serviceUrl}/ad-units/detailed`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  getAllAdUnits(filter: AdUnitsListFilter): Observable<[AdUnit]> {
    return this.httpClient.get<[AdUnit]>(`${environment.serviceUrl}/ad-units/detailed-nonpaged`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  getAdUnitById(id: string): Observable<AdUnit> {
    return this.httpClient.get<AdUnit>(`${environment.serviceUrl}/ad-units/${id}`);
  }

  createAdUnit(u: CreateAdUnitRequest): Observable<AdUnit> {
    return this.httpClient.post<AdUnit>(`${environment.serviceUrl}/ad-units`, u);
  }

  updateAdUnitById(pub: AdUnit): Observable<AdUnit> {
    return this.httpClient.put<AdUnit>(`${environment.serviceUrl}/ad-units/${pub.id}`, pub);
  }

  // build(config: UnitBuildConfig): Observable<UnitBuilderResponse> {
  //   return this.httpClient.post<UnitBuilderResponse>(`${environment.serviceUrl}/ad-units/build`, config);
  // }

  buildUnits(ids: string[]): Observable<UnitBuilderResponse> {
    return this.httpClient.post<UnitBuilderResponse>(`${environment.serviceUrl}/ad-units/build`, ids);
  }

  buildUnit(id: string): Observable<UnitBuilderResponse> {
    return this.httpClient.put<UnitBuilderResponse>(`${environment.serviceUrl}/ad-units/${id}/build`, null);
  }

  adUnitConfig(id: string): Observable<UnitConfig> {
    return this.httpClient.get<UnitConfig>(`${environment.serviceUrl}/ad-units/${id}/config`);
  }

  adUnitConfigUpdate(id: string, config: UnitConfig) {
    return this.httpClient.put<UnitConfig>(`${environment.serviceUrl}/ad-units/${id}/config`, config);
  }

  getTopAdUnits(dateFrom: string, dateTo: string): Observable<{key: string, value: ReportMetric.REVENUE}[]> {
    return this.httpClient.get<{key: string, value: ReportMetric.REVENUE}[]>(`${environment.serviceUrl}/dashboard/top-ad-units`, {
      params: HttpUtils.getHttpParams({
        dateFrom: dateFrom,
        dateTo: dateTo
      })
    });
  }

  getAdUnitConfig(id: string): Observable<UnitConfig> {
    return this.httpClient.get<UnitConfig>(`${environment.serviceUrl}/ad-units/${id}/config/full`);
  }

  adUnitGeneralUpdate(id, generalData): Observable<AdUnit> {
    return this.httpClient.put<AdUnit>(`${environment.serviceUrl}/ad-units/${id}/general`, generalData);
  }

  adUnitFinanceUpdate(id, pubShares): Observable<AdUnit> {
    return this.httpClient.put<AdUnit>(`${environment.serviceUrl}/ad-units/${id}/finance`, {pubShares});
  }

  adUnitTagCpmUpdate(id, tagId, tagCpms): Observable<AdUnit> {
    return this.httpClient.put<AdUnit>(`${environment.serviceUrl}/ad-units/${id}/tags/${tagId}/cpms`, {tagCpms});
  }

  adUnitTagsUpdate(id, tags): Observable<AdUnit> {
    return this.httpClient.put<AdUnit>(`${environment.serviceUrl}/ad-units/${id}/tags`, {tags});
  }

  adUnitDpsUpdate(id, demandPartners): Observable<AdUnit> {
    return this.httpClient.put<AdUnit>(`${environment.serviceUrl}/ad-units/${id}/dps`, {demandPartners});
  }

  adUnitDpsAutoCreate(id, demandPartner): Observable<CreationAutomationDto> {
    const body = new CreationAutomationDto();
    body.id = id;
    body.demandPartner = demandPartner;
    return this.httpClient.post<CreationAutomationDto>(`${environment.serviceUrl}/ad-units/${id}/auto-create-audp`, body);
  }

  getAllAdUnitTags(): Observable<AdUnitTag[]> {
    return this.httpClient.get<AdUnitTag[]>(`${environment.serviceUrl}/ad-units/tags`);
  }

  adUnitBidAdjustment(id, bids) {
    return this.httpClient.put(`${environment.serviceUrl}/ad-units/${id}/bid-adjustment`, bids);
  }

  getAdUnitDpDetails(id) {
    return this.httpClient.get<AdUnitDpDetails[]>(`${environment.serviceUrl}/ad-units/${id}/dp-details`);
  }
}

export class UnitBuilderResponse {
  success: boolean;
  error: string;
  config: string;
  adUnit: AdUnit;
}

export class AdUnitsListFilter extends PageSortRequest {
  name?: string;
  bidder?: boolean;
  sticky?: boolean;
  // hasConfig?: boolean;
  includeDps?: boolean;
  publisherId: string;
  ids?: string[];
  status?: AdUnitStatus[];
  activityStatus?: AdUnitActivityStatus;
  mcmActive?: Boolean;
  tagSequencer?: Boolean;
  s2s?: Boolean;
  bidCache?: Boolean;
  ibv?: Boolean;
}

export class CreationAutomationDto {
  id?: string;
  demandPartner?: AdUnitDemandPartner;
  message?: string;
}
