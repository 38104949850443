import {AfterViewInit, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ClipboardService} from 'ngx-clipboard';
import {SnackBarService} from '../../../../core/services/snackbar.service';
import {AdUnit} from '../../../domain/ad-unit.model';
import {MatTableDataSource} from '@angular/material/table';
import {PublisherDomain} from '../../../domain/publisher-domain.model';
import {GlobalSpinnerService} from '../../../../core/services/global-spinner.service';
import {PublishersService} from '../../../services/publishers.service';
import {DomainAdsTxtDetailsPreviewDialogComponent} from '../domain-ads-txt-details-preview/domain-ads-txt-details-preview-dialog.component';

@Component({
  selector: 'cs-domain-ads-txt-preview-dialog',
  templateUrl: './domain-ads-txt-preview-dialog.component.html',
  styleUrls: ['./domain-ads-txt-preview-dialog.component.scss']
})
export class DomainAdsTxtPreviewDialogComponent implements AfterViewInit {

  adUnit: AdUnit;
  dataSource = new MatTableDataSource<PublisherDomain>();
  columns = ['domain', 'adsTxtMissingRecords', 'adsTxtCondensed'];

  constructor(public dialogRef: MatDialogRef<DomainAdsTxtPreviewDialogComponent>,
              public snackBarService: SnackBarService,
              private clipboardService: ClipboardService,
              private sbs: SnackBarService,
              private gss: GlobalSpinnerService,
              private publisherService: PublishersService,
              private matDialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: {adUnit: AdUnit }) {
    this.adUnit = data.adUnit;
    this.dataSource.data = this.adUnit.publisherDomains;
  }

  ngAfterViewInit() {
    this.dialogRef.updateSize('500px');
  }

  showAdsTxtDetails(publisherDomain: PublisherDomain) {
    this.gss.showLoader();
    this.publisherService.domainsAdstxtDiff(publisherDomain).subscribe(next => {
      this.gss.hideLoader();
      this.matDialog.open(DomainAdsTxtDetailsPreviewDialogComponent, <MatDialogConfig>{
        data: {
          domain: publisherDomain,
          result: next
        }
      });
    }, () => {
      this.gss.hideLoader();
    });
  }

}
