import {PageSortRequest} from './page-sort-request.model';

export class PublisherComplianceOverviewModel {
  publisher: PublisherComplianceModel;
  domains: PublisherDomainsComplianceModel[];
}

export class PublisherComplianceModel {
  id: string;
  name: string;
  contactName: string;
}

export class PublisherDomainsComplianceModel {
  approved: number;
  createdOn: Date;
  pending: number;
  publisherId: string;
  rejected: number;
  total: number;
  updatedOn: Date;
  url: string;
}

export class PublisherComplianceOverviewFilter extends PageSortRequest {
  url?: string;
  publisherId?: string;
}
