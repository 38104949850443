import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {HttpUtils} from '../utils/http-utils';
import {DemandPartner} from '../domain/demand-partner.model';
import {Observable} from 'rxjs';
import {DpDomainApproval, DpDomainApprovalListFilter} from '../domain/demand-partner-approval.model';
import {Page} from '../domain/page.model';
import {DpDomainWhitelist} from '../domain/demand-partner-whitelist.model';
import {PageSortRequest} from '../domain/page-sort-request.model';
import {DemandPartnerMatchRateFilter, DemandPartnerMatchRateModel} from '../domain/demand-partner-match-rate.model';

@Injectable({
  providedIn: 'root',
})
export class DemandPartnersService {

  constructor(private httpClient: HttpClient) {
  }

  get(params= ''): Observable<DemandPartner[]> {
    return this.httpClient.get<DemandPartner[]>(`${environment.serviceUrl}/dp?` + params);
  }

  missingData(startDate: string, endDate: string): Observable<MissingDpDailyStatsReport> {
    return this.httpClient.get<MissingDpDailyStatsReport>(`${environment.serviceUrl}/dp/missing-dp-data`, {
      params: HttpUtils.getHttpParams({startDate, endDate})
    });
  }

  dpImport(demandPartner: string, date: string) {
    return this.httpClient.get(`${environment.serviceUrl}/dp/import`, {
      params: HttpUtils.getHttpParams({demandPartner, date})
    });
  }

  dpImportCsv(demandPartner: string, file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.httpClient.post(`${environment.serviceUrl}/dp/upload-csv`, formData, {
      params: HttpUtils.getHttpParams({demandPartner})
    });
  }

  dfpImportPerSiteData(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post(`${environment.serviceUrl}/dfp/by-domain/upload-csv`, formData);
  }

  dfpImportEbda(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post(`${environment.serviceUrl}/dfp/ebda/upload-csv`, formData);
  }

  missingDataExport(startDate: string, endDate: string) {
    return this.httpClient.get(`${environment.serviceUrl}/dp/missing-dp-data-csv`, {
      responseType: 'text',
      params: HttpUtils.getHttpParams({startDate, endDate})
    });
  }

  dpApproval(filter: DpDomainApprovalListFilter): Observable<Page<DpDomainApproval>> {
    return this.httpClient.get<Page<DpDomainApproval>>(`${environment.serviceUrl}/dp/approval`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  dpApprovalImportCsv(file: File) {
  // todo ask for url
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.httpClient.put(`${environment.serviceUrl}/dp/approval/upload-csv`, formData);
  }

  dpWhitelist(filter: PageSortRequest) {
    return this.httpClient.get<Page<DpDomainWhitelist>>(`${environment.serviceUrl}/dp/whitelist`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  getUrls(url: string): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.serviceUrl}/dp/whitelist/autocomplete`, {
      params: HttpUtils.getHttpParams({url: url})
    });
  }

  getTagProviders(): Observable<DemandPartner[]> {
    return this.httpClient.get<DemandPartner[]>(`${environment.serviceUrl}/tag-providers`);
  }

  matchRate(filter: DemandPartnerMatchRateFilter): Observable<DemandPartnerMatchRateModel[]> {
    return this.httpClient.post<DemandPartnerMatchRateModel[]>(`${environment.serviceUrl}/dp/match-rate`, filter);
  }
}

export class MissingDpDailyStatsReport {
  demandPartnerRows: string[];
  dateColumns: [];
  foundData: boolean[][];
}
