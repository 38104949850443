<div class="auth login d-flex">
  <div class="container">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">

        <div class="login-header text-center">
        </div>

        <div class="login-content">
          <mat-card class="p-5 mb-5">
            <mat-card-title class="mb-4 pb-3 text-center">
              <i class="fal fa-user-circle fa-3x mb-3 d-block text-primary" aria-hidden="true"></i>
              <strong>Reset password</strong>
            </mat-card-title>
            <mat-card-content>
              <form #passwordForm="ngForm" class="d-flex flex-column">
                <mat-form-field class="mb-4"  floatLabel="always" appearance="outline">
                  <input type="password"
                         matInput
                         name="newPassword"
                         [pattern]="regex"
                         placeholder="New password"
                         required
                         [(ngModel)]="password.newPassword"
                         (keyup.enter)="password.repeatPassword === password.newPassword
                         && passwordForm.valid && reset()"
                         class="mb10">
                  <mat-hint align="end">Min. 8 characters: number, special character, lowercase, uppercase and letters</mat-hint>
                </mat-form-field>
                <mat-form-field style="margin-bottom: -1rem;" floatLabel="always" appearance="outline">
                  <input type="password"
                         matInput
                         name="repeatPassword"
                         [pattern]="regex"
                         placeholder="Repeat password"
                         required
                         [(ngModel)]="password.repeatPassword"
                         (keyup.enter)="password.repeatPassword === password.newPassword
                         && passwordForm.valid && reset()"
                         class="mb10">
                </mat-form-field>
              </form>
            </mat-card-content>
            <mat-card-footer class="text-center">
              <button type="button" mat-flat-button color="primary" class="iconed large" (click)="reset()"
                      [disabled]="!passwordForm.valid">
                <i class="far fa-envelope" aria-hidden="true"></i>
                <span>Reset my password</span>
              </button>
            </mat-card-footer>
          </mat-card>



          <small class="d-block text-center mt-4">
            <a mat-button color="primary" [routerLink]="'/login'">Back to sign in</a>
          </small>

        </div>

      </div>
    </div>
  </div>
</div>
