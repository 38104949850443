import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {ReportRequest} from '../domain/rb/report-request.model';
import {ReportResponse} from '../domain/rb/report-response.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {

  constructor(private httpClient: HttpClient) {
  }

  report(request: ReportRequest): Observable<ReportResponse> {
    return this.httpClient.post<ReportResponse>(`${environment.serviceUrl}/dashboard/report`, request);
  }
}
