import {KeyLabel} from '../key-label.model';
export enum ReportDimension {
  TOTAL = 'TOTAL',
  DAY = 'DAY',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  HOUR = 'HOUR',
  WIDGET = 'WIDGET',
  AD_UNIT = 'AD_UNIT',
  WIDGET_TYPE = 'WIDGET_TYPE',
  WIDGET_SIZE = 'WIDGET_SIZE',
  CONTENT_TYPE = 'CONTENT_TYPE',
  TAXONOMY = 'TAXONOMY',
  DEVICE_PLACEMENT = 'DEVICE_PLACEMENT',
  SITE = 'SITE',
  PUBLISHER = 'PUBLISHER',
  DEMAND_PARTNER = 'DEMAND_PARTNER',
  COMBINED_DIMENSIONS = 'COMBINED_DIMENSIONS',
  EX_DOMAIN = 'EX_DOMAIN',
  PARTNER = 'PARTNER',
}

export const ReportDimensionLabels = {
  TOTAL: 'Total',
  DAY: 'Day',
  MONTH: 'Month',
  YEAR: 'Year',
  HOUR: 'Hour',
  WIDGET: 'Widget',
  AD_UNIT: 'Ad unit',
  WIDGET_TYPE: 'Widget type',
  WIDGET_SIZE: 'Widget size',
  CONTENT_TYPE: 'Content type',
  TAXONOMY: 'Taxonomy',
  DEVICE_PLACEMENT: 'Device placement',
  SITE: 'Site',
  PUBLISHER: 'Publisher',
  DEMAND_PARTNER: 'Demand partner',
  COMBINED_DIMENSIONS: 'Combined dimensions',
  EX_DOMAIN: 'Exchange Domain',
  PARTNER: 'Partner'
};

export const ReportDimensionKL = () =>
  (Object.keys(ReportDimension).map(
      key => new KeyLabel<ReportDimension>(ReportDimension[key], ReportDimensionLabels[key]))
  );

export const ReportDimensionsMap = (): { [key: string]: KeyLabel<ReportDimension> } => ReportDimensionKL().reduce((prev, curr) => {
  prev[curr.key] = curr;
  return prev;
}, {});
