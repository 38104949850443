import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {AdUnit} from '../../domain/ad-unit.model';
import {AdUnitsListFilter, AdUnitsService} from '../../services/ad-units.service';
import {SortUtils} from '../../utils/sort-utils';
import {Principal} from '../../../core/auth/principal.service';

@Component({
  selector: 'cs-ad-unit-autocomplete',
  template: `
    <mat-form-field appearance="outline" [floatLabel]="floatLabel || 'always'" class="w-100" [ngClass]="formFieldClass">
      <mat-label *ngIf="floatLabel != 'never'">Choose ad unit</mat-label>
      <input matInput placeholder="{{placeholder || 'Choose ad unit'}}"
             #name
             aria-label="Ad unit"
             name="Ad unit"
             [matAutocomplete]="auto"
             [formControl]="filterCtrl">
      <i class="fas fa-spinner fa-spin text-primary" matSuffix [hidden]="!serverSearching"></i>
      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="optionSelected($event)"
                        [displayWith]="display">
        <mat-option *ngFor="let item of filteredItems | async" [value]="item">
          {{item.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  `,
})
export class AdUnitAutocompleteComponent implements OnInit {

  @Input()
  public placeholder: string;

  @Input()
  public adUnitId: string;

  @Input()
  public floatLabel: string;

  @Input()
  public formFieldClass: string;

  @Output()
  adUnitSelected = new EventEmitter<AdUnit>();

  @ViewChild('name', {static: true}) nameFilter: ElementRef;

  filteredItems: Subject<AdUnit[]>;
  filterCtrl = new FormControl();
  selectedItem;
  filteredUnitsIds: string[] = [];

  serverSearching = false;

  constructor(private service: AdUnitsService, private principal: Principal) {
    this.filteredItems = new Subject();
    //noinspection TypeScriptUnresolvedFunction
    this.filterCtrl.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(next => {
        this.filteredItems.next([]);
        if (!next && this.selectedItem) {
          this.selectedItem = undefined;
          this.emit();
        }

        if (next && typeof next !== 'object') {
          this.serverSearching = true;
          this.filterCtrl.disable();
          service.listAdUnits(<AdUnitsListFilter>{
            name: next,
            page: 0,
            size: 10 + this.filteredUnitsIds.length
          }).subscribe(page => {
            let units = page.content;
            units = units.filter(item => !this.filteredUnitsIds.includes(item.id));
            this.filteredItems.next(units.sort(SortUtils.propertyComparatorString('name')).splice(0, 10));
            this.filterCtrl.enable();
            this.nameFilter.nativeElement.focus();
            this.serverSearching = false;
          }, () => {
            this.filterCtrl.enable();
            this.nameFilter.nativeElement.focus();
            this.serverSearching = false;
          });
        }
      });
  }


  ngOnInit(): void {
    if (this.adUnitId) {
      this.serverSearching = true;
      this.service.listAdUnits(<AdUnitsListFilter>{ids: [this.adUnitId]}).subscribe(page => {
        const units = page.content;
        if (units && units.length > 0) {
          this.selectedItem = units[0];
          this.filterCtrl.setValue(units[0]);
          // this.emit();
        }
        this.serverSearching = false;
      }, () => {
        this.serverSearching = false;
      });
    }
  }

  display(p?: AdUnit) {
    return p ? p.name : undefined;
  }

  optionSelected($event) {
    this.selectedItem = $event.option.value;
    this.filteredItems.next([]);
    this.emit();
  }

  resetInput() {
    this.selectedItem = null;
    this.filterCtrl.setValue(null);
  }

  updateFilteredUnits(items) {
    this.filteredUnitsIds = items.map(item => item.id);
  }

  private emit() {
    this.adUnitSelected.emit(this.selectedItem);
  }
}
