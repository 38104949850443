import {ReportRequest} from './report-request.model';
import {ReportGrouping} from './report-grouping.enum';
import {ReportType} from './report-type.enum';
import {ReportDataSource} from './report-data-source.enum';
import {ReportDimension} from './report-dimension.enum';
import {ReportMetric} from './report-metric.enum';

const AD_UNITS_BY_DAY_COMBINED = <ReportRequest>{
  reportGrouping: ReportGrouping.COMBINED,
  reportType: ReportType.COMBINED,
  dataSource: ReportDataSource.DATE_PERIOD_DEPENDENT,
  dimensions: [
    ReportDimension.AD_UNIT,
    ReportDimension.DAY,
  ],
  metrics: [
    ReportMetric.AD_UNIT,
    ReportMetric.DAY,
    ReportMetric.UNIT_IMPRESSIONS,
    ReportMetric.AD_REQUESTS,
    ReportMetric.FILLED,
    ReportMetric.REFRESH_RATE,
    ReportMetric.TOTAL_RPM,
    ReportMetric.MARGIN,
    ReportMetric.REVENUE,
    ReportMetric.PROFIT,
  ],
  filters: {
    dateFrom: null,
    dateTo: null,
    adUnitIds: [],
    publisherIds: [],
    demandPartnerIds: [],
    activityStatuses: [],
    siteIds: [],
    domainIds: [],
    widgets: [],
  },
  predicates: [],
};

const DAILY_COMBINED = <ReportRequest>{
  reportGrouping: ReportGrouping.COMBINED,
  reportType: ReportType.COMBINED,
  dataSource: ReportDataSource.DATE_PERIOD_DEPENDENT,
  dimensions: [
    ReportDimension.DAY,
  ],
  metrics: [
    ReportMetric.DAY,
    ReportMetric.UNIT_IMPRESSIONS,
    ReportMetric.AD_REQUESTS,
    ReportMetric.FILLED,
    ReportMetric.REVENUE,
    ReportMetric.PROFIT,
  ],
  filters: {
    dateFrom: null,
    dateTo: null,
    adUnitIds: [],
    publisherIds: [],
    demandPartnerIds: [],
    activityStatuses: [],
    siteIds: [],
    domainIds: [],
    widgets: [],
  },
  predicates: [],
};

export const ReportRequestPresets = {
  AD_UNITS_BY_DAY_COMBINED: AD_UNITS_BY_DAY_COMBINED,
  DAILY_COMBINED: DAILY_COMBINED
};
