import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {WidgetType} from '../domain/widget-type.model';
import {environment} from '../../../environments/environment';

@Injectable()
export class WidgetTypesService {

  constructor(private http: HttpClient) {
  }

  getAll(): Observable<WidgetType[]> {
    return this.http.get<WidgetType[]>(`${environment.serviceUrl}/widget-types`);
  }

  createWidgetType(widgetType: WidgetType): Observable<WidgetType> {
    return this.http.post<WidgetType>(`${environment.serviceUrl}/widget-types`, widgetType);
  }

  updateWidgetType(widgetType: WidgetType): Observable<WidgetType> {
    return this.http.put<WidgetType>(`${environment.serviceUrl}/widget-types/${widgetType.id}`, widgetType);
  }
}
