import {Routes} from '@angular/router';
import {LoginComponent} from '../core/login/login.component';
import {LogoutComponent} from '../core/login/logout.component';
import {MainComponent} from './main.component';
import {AdminGuard} from '../core/guards/admin-guard.service';
import {PublisherGuard} from '../core/guards/publisher-guard.service';
import {MaskSelectComponent} from './mask-select.component';
import {ForgotPasswordComponent} from '../core/components/forgot-password.component';
import {ResetPasswordComponent} from '../core/components/reset-password.component';

export const appRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'p/:id',
    canLoad: [PublisherGuard],
    loadChildren: () => import('./publisher/publisher.module').then(m => m.PublisherModule)
  },
  {
    path: 'select',
    component: MaskSelectComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AdminGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: 'discrepancies',
        loadChildren: () => import('./discrepancies/discrepancies.module').then(m => m.DiscrepanciesModule),
      },
      {
        path: 'publishers',
        loadChildren: () => import('./publishers/publishers.module').then(m => m.PublishersModule),
      },
      {
        path: 'ad-units',
        loadChildren: () => import('./ad-units/ad-units.module').then(m => m.AdUnitsModule),
      },
      {
        path: 'demand-partners',
        loadChildren: () => import('./demand-partners/demand-partners.module').then(m => m.DemandPartnersModule),
      },
      {
        path: 'dp-units',
        loadChildren: () => import('./dp-units/dp-units.module').then(m => m.DpUnitsModule),
      },
      {
        path: 'ads-txt',
        loadChildren: () => import('./ads-txt/ads-txt.module').then(m => m.AdsTxtModule),
      },
      {
        path: 'widget-types',
        loadChildren: () => import('./widget-types/widget-types.module').then(m => m.WidgetTypesModule),
      },
      {
        path: 'users',
        canActivateChild: [AdminGuard],
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
      },
      {
        path: 'finances',
        loadChildren: () => import('./finances/finances.module').then(m => m.FinancesModule),
      },
      {
        path: 'analytics',
        loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule),
      },
      {
        path: 'changelog',
        loadChildren: () => import('./change-log/change-log.module').then(m => m.ChangeLogModule)
      },
      {
        path: 'partners',
        loadChildren: () => import('./partners/partners.module').then(m => m.PartnersModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];
