export const environment = {
  production: true,
  serviceUrl: 'https://app-api-stage.yieldlift.com',
  cdnUrl: 'https://cdn1.opstag.com',
  unitBuilder: {
    prebidScript: 'prebid.js',
    prebidScriptS2S: 'prebid_bidphysics_s2s.js',
    maxRefresh: 150,
    exchangeAdapter: 'bidphysics'
  }
};
