import * as moment from 'moment';
import {DateRangeType} from '../domain/rb/date-range-type.enum';
import {DateRangeHolder} from '../domain/rb/date-range-holder.model';

export class MomentDateUtils {
  static reformat(dateString, fromFormat, toFormat) {
    if (!dateString) {
      return;
    }
    return moment(dateString, fromFormat).format(toFormat || 'YYYY-MM-DD');
  }

  static format(date, format = 'YYYY-MM-DD') {
    if (!date) {
      return;
    }
    return moment(date).format(format);
  }

  static today(format = 'YYYY-MM-DD') {
    return moment().format(format || 'YYYY-MM-DD');
  }

  static yesterday(format = 'YYYY-MM-DD') {
    return moment().add(-1, 'days').format(format || 'YYYY-MM-DD');
  }

  static sevenDaysAgo(format = 'YYYY-MM-DD') {
    return moment().add(-7, 'days').format(format);
  }

  static nDaysFromToday(amount: number, format = 'YYYY-MM-DD') {
    return moment().add(amount, 'days').format(format);
  }

  static nDaysFromDate(date: string, days: number, format = 'YYYY-MM-DD') {
    return moment(date).add(days, 'days').format(format);
  }

  static firstDayLastMonth(format) {
    return moment().subtract(1, 'months').startOf('month').format(format || 'YYYY-MM-DD');
  }

  static lastDayLastMonth(format) {
    return moment().subtract(1, 'months').endOf('month').format(format || 'YYYY-MM-DD');
  }

  static firstDayLastMonths(monthsBackwards = 1, format) {
    return moment().subtract(monthsBackwards, 'months').startOf('month').format(format || 'YYYY-MM-DD');
  }

  static lastDayLastMonths(monthBackwards = 1, format) {
    return moment().subtract(monthBackwards, 'months').endOf('month').format(format || 'YYYY-MM-DD');
  }

  static getMoment(args?) {
    return moment(args);
  }

  public static getFirstDayOfTheWeek() {
    return moment().startOf('week');
  }

  public static getFirstDayOfTheMonth() {
    return moment().startOf('month');
  }

  private static getFirstDayOfTheYear(addYears = 0, format = 'YYYY-MM-DD') {
    return moment().add(addYears, 'years').startOf('year').format(format);
  }

  static getDatesFromRange(dateRangeType: DateRangeType, format = 'YYYY-MM-DD'): DateRangeHolder {
    if (!dateRangeType) {
      return;
    }
    let dateFrom;
    let dateTo;
    switch (dateRangeType) {
      case DateRangeType.TODAY:
        dateFrom = MomentDateUtils.today(format);
        dateTo = MomentDateUtils.today(format);
        break;
      case DateRangeType.YESTERDAY:
        dateFrom = MomentDateUtils.yesterday(format);
        dateTo = MomentDateUtils.yesterday(format);
        break;
      case DateRangeType.THIS_WEEK:
        dateFrom = MomentDateUtils.format(MomentDateUtils.getFirstDayOfTheWeek(), format);
        dateTo = MomentDateUtils.format(new Date(), format);
        break;
      case DateRangeType.THIS_MONTH:
        dateFrom = MomentDateUtils.format(MomentDateUtils.getFirstDayOfTheMonth(), format);
        dateTo = MomentDateUtils.format(new Date(), format);
        break;
      case DateRangeType.LAST_MONTH:
        dateFrom = MomentDateUtils.firstDayLastMonth(format);
        dateTo = MomentDateUtils.lastDayLastMonth(format);
        break;
      case DateRangeType.LAST_3_MONTHS:
        dateFrom = MomentDateUtils.firstDayLastMonths(3, format);
        dateTo = MomentDateUtils.lastDayLastMonth(format);
        break;
      case DateRangeType.LAST_6_MONTHS:
        dateFrom = MomentDateUtils.firstDayLastMonths(6, format);
        dateTo = MomentDateUtils.lastDayLastMonth(format);
        break;
      case DateRangeType.THIS_YEAR:
        dateFrom = MomentDateUtils.format(MomentDateUtils.getFirstDayOfTheYear(), format);
        dateTo = MomentDateUtils.format(new Date(), format);
        break;
      case DateRangeType.LAST_YEAR:
        dateFrom = MomentDateUtils.format(MomentDateUtils.getFirstDayOfTheYear(-1), format);
        dateTo = MomentDateUtils.format(MomentDateUtils.getFirstDayOfTheYear(), format);
        break;
      case DateRangeType.LAST_7_DAYS:
        dateFrom = MomentDateUtils.sevenDaysAgo(format);
        dateTo = MomentDateUtils.yesterday(format);
        break;
      case DateRangeType.LAST_30_DAYS:
        dateFrom = MomentDateUtils.format(moment().add(-30, 'days'), format);
        dateTo = MomentDateUtils.format(new Date(), format);
        break;
    }
    return dateFrom ? new DateRangeHolder(dateFrom, dateTo, dateRangeType) : null;
  }

  static moment(date?) {
    return moment(date);
  }


  static getYearsStartingFrom(startYear: number) {
    let now = moment();
    const years = [];
    let year = parseInt(now.format('YYYY'), 10);
    years.unshift(year);
    do {
      now = now.add(-1, 'year');
      year = parseInt(now.format('YYYY'), 10);
      years.unshift(year);
    } while (year > startYear);
    return years;
  }

  static getDurationInDays(dateFrom: string, dateTo: string): number {
    const duration = moment.duration(moment(dateTo).diff(moment(dateFrom)));

    return Math.abs(duration.asDays());
  }
}
