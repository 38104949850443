import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {AdsTxtRecord} from '../../shared/domain/adstxt-record.model';
import {HttpUtils} from '../../shared/utils/http-utils';
import { Page } from '../domain/page.model';
import {PageSortRequest} from '../domain/page-sort-request.model';
import {DpDomainApprovalStatus} from '../domain/demand-partner-approval.model';
import {PublisherDomainStatus} from '../domain/publisher-domain.model';

@Injectable({
  providedIn: 'root',
})
export class AdsTxtService {

  constructor(private httpClient: HttpClient) {
  }

  statusExport(pubId: string[], dp: string[]): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/adstxts/status/export`, Object.assign({},{
      params: HttpUtils.getHttpParams({
        publisherId: pubId,
        dp: dp
      })
    }));
  }
  statusExportAll(pubId: string[], dp: string[]): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/adstxts/status/export-all`, Object.assign({}, {
      params: HttpUtils.getHttpParams({
        publisherId: pubId,
        dp: dp
      })
    }));
  }

  recordsGet(filter): Observable<Page<AdsTxtRecord>> {
    return this.httpClient.get<Page<AdsTxtRecord>>(`${environment.serviceUrl}/adstxts`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  getAllRecords(): Observable<AdsTxtRecord[]> {
    return this.httpClient.get<AdsTxtRecord[]>(`${environment.serviceUrl}/adstxts/export`);
  }

  recordDelete(r: AdsTxtRecord) {
    return this.httpClient.delete<AdsTxtRecord>(`${environment.serviceUrl}/adstxts/${r.id}`);
  }

  recordPost(r: AdsTxtRecord) {
    return this.httpClient.post<AdsTxtRecord>(`${environment.serviceUrl}/adstxts`, r);
  }

  recordPut(r: AdsTxtRecord) {
    return this.httpClient.put<AdsTxtRecord>(`${environment.serviceUrl}/adstxts/${r.id}`, r);
  }
}

export class AdsTxtRecordsFilter extends PageSortRequest {
  name?: string;
  accountType?: string;
  demandPartnerName?: string;
}

export class AdsTxtByDpFilter extends PageSortRequest {
  demandPartner?: string;
  urls?: string[];
  url?: string;
  statuses?: DpDomainApprovalStatus[];
  publisherDomainStatuses?: PublisherDomainStatus[];
}
