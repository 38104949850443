<div class="auth login d-flex">
  <div class="container">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">

        <div class="login-header text-center">
        </div>

        <div class="login-content">
          <mat-card class="p-5 mb-5">
            <mat-card-title class="mb-4 pb-3 text-center">
              <i class="fal fa-user-circle fa-3x mb-3 d-block text-primary" aria-hidden="true"></i>
              <strong>Forgot password</strong>
            </mat-card-title>
            <mat-card-content>
              <div>
                <form #f="ngForm" class="d-flex flex-column">
                  <mat-form-field floatLabel="always" class="mb-2" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input #email
                           matInput
                           name="email" value=""
                           cdkFocusInitial
                           required
                           email
                           [(ngModel)]="user.email"
                           (keyup.enter)="f.valid &&  recover()">
                  </mat-form-field>
                </form>
              </div>
            </mat-card-content>
            <mat-card-footer class="text-center">
              <button type="button" mat-flat-button color="primary" class="iconed large" (click)="recover()"
                      [disabled]="!f.valid">
                <i class="far fa-envelope" aria-hidden="true"></i>
                <span>Recover my password</span>
              </button>
            </mat-card-footer>
          </mat-card>



          <small class="d-block text-center mt-4" >
              <!--<a mat-button color="primary" [routerLink]="'/login'">Back to sign in</a>-->
          </small>

        </div>

      </div>
    </div>
  </div>
</div>
