import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {Page} from '../domain/page.model';
import {HttpUtils} from '../utils/http-utils';
import {ChangeLog, ChangeLogFilter} from '../domain/changelog.model';

@Injectable({
  providedIn: 'root',
})
export class ChangeLogService {

  constructor(private httpClient: HttpClient) {
  }

  list(filter: ChangeLogFilter): Observable<Page<ChangeLog>> {
    return this.httpClient.get<Page<ChangeLog>>(`${environment.serviceUrl}/change-log`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  getChangeLogs(filter: ChangeLogFilter): Observable<ChangeLog[]> {
    return this.httpClient.get<ChangeLog[]>(`${environment.serviceUrl}/change-log/export`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  listEmails(filter: ChangeLogFilter): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.serviceUrl}/change-log/emails`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }
}
