import {Component, Input, OnInit} from '@angular/core';
import {LoginService} from '../../../core/login/login.service';
import {Principal} from '../../../core/auth/principal.service';
import {PublishersService} from '../../services/publishers.service';
import {Publisher} from '../../domain/publisher.model';
import {SnackBarService} from '../../../core/services/snackbar.service';
import {UserRole} from '../../../core/auth/user-role.model';

@Component({
  selector: 'cs-toolbar-publisher',
  templateUrl: './toolbar-publisher.component.html',
  styleUrls: ['./toolbar-publisher.component.scss'],
})
export class ToolbarPublisherComponent implements OnInit {

  @Input()
  public pageTitle: string;
  @Input()
  public titleNext: string;

  publishers: Publisher[];
  userRole = UserRole;

  constructor(private loginService: LoginService,
              public principal: Principal,
              private publisherService: PublishersService,
              private sbs: SnackBarService) {
  }

  navLinks = [
    {path: `/p/${this.principal.publisher.id}/dashboard`, label: 'Dashboard', disabled: false, tooltip: ''},
    {path: `/p/${this.principal.publisher.id}/ad-units`, label: 'Ad Units', disabled: false, tooltip: ''},
    {path: `/p/${this.principal.publisher.id}/domains`, label: 'Domains', disabled: false, tooltip: ''},
    {path: `/p/${this.principal.publisher.id}/adstxt`, label: 'Ads.txt', disabled: false, tooltip: ''}
  ];

  ngOnInit(): void {
    if (this.principal.user.publisherIds && this.principal.user.publisherIds.length) {
      this.publisherService.getPublisherByIds(this.principal.user.publisherIds).subscribe(publishers => {
        if (publishers.length > 1 || this.principal.user.roles.includes(UserRole.CS_ADMIN)) {
          this.publishers = publishers.filter(p => p.id !== this.principal.publisher.id);
        }
      }, () => {
        this.sbs.error('Error while fetching publishers');
      });
    }
  }

  public logout() {
    this.loginService.logout(true);
  }

  changeUrl(id: string, main?: boolean) {
    if (main) {
      window.open(`/#/`, '_blank').focus();
    } else {
      window.open(`/#/p/${id}`, '_self').location.reload();
    }
  }

}
