<h3 class="mat-dialog-title">
  {{adUnit.bidder ? 'Prebid config parameters' : 'Unit tag'}}
</h3>
<mat-dialog-content class="mt-n2">
  <textarea [value]="getUnitTag()" readonly class="mat-input-element textarea-note w-100 mb-3" disabled>
  </textarea>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-center">
  <button mat-raised-button color="primary" (click)="downloadFile()" class="iconed  mb-3 mb-sm-0">
    <i class="fas fa-download"></i>
    <span>Download file</span>
  </button>
  <button mat-raised-button color="primary" (click)="copyTagToClipboard()" class="iconed">
    <i class="fas fa-clipboard"></i>
    <span>Copy to clipboard</span>
  </button>
  <button mat-raised-button color="" class="mr-1" [mat-dialog-close]="false">Close</button>
</mat-dialog-actions>
