import {KeyValue} from '../key-value.model';

export enum DateRangeType {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  THIS_WEEK = 'THIS_WEEK',
  LAST_7_DAYS = 'LAST_7_DAYS',
  THIS_MONTH = 'THIS_MONTH',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_MONTH = 'LAST_MONTH',
  LAST_3_MONTHS = 'LAST_3_MONTHS',
  LAST_6_MONTHS = 'LAST_6_MONTHS',
  THIS_YEAR = 'THIS_YEAR',
  LAST_YEAR = 'LAST_YEAR',
}

export const DateRangeTypeLabels = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  THIS_WEEK: 'This week',
  LAST_7_DAYS: 'Last 7 days',
  THIS_MONTH: 'This month',
  LAST_30_DAYS: 'Last 30 days',
  LAST_MONTH: 'Last month',
  LAST_3_MONTHS: 'Last 3 months',
  LAST_6_MONTHS: 'Last 6 months',
  THIS_YEAR: 'This year',
  LAST_YEAR: 'Last year',
};

export const DateRangeTypeLabelKV = () =>
  (Object.keys(DateRangeTypeLabels).map(key => new KeyValue(<DateRangeType>key, DateRangeTypeLabels[key])));
