<h3 class="mat-dialog-title">
  {{publisherDomain.id.url}}
  <div class="row">
    <div class="col">
      <small>Last crawled datetime: {{adsTxtUpdatedOn | date : 'MM/dd/YYYY HH:mm'}}</small>
    </div>
    <form #f="ngForm">
      <div class="col-md-6 col-4 ml-4">
        <mat-form-field appearance="outline" floatLabel="always" class="header-form-field">
          <mat-label>Filter</mat-label>
          <mat-select placeholder="Filter" name="filter" [(ngModel)]="filter" (selectionChange)="filterData()">
            <mat-option *ngFor="let value of filterValues" [value]="value">
              {{ value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </div>
</h3>

<mat-dialog-content class="mt-n2">
  <mat-table #table [dataSource]="dataSource" matSort matSortActive="domain" matSortDirection="asc">
    <ng-container matColumnDef="domain">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Domain</mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="found(element)">
        <span class="mobile-label">Domain</span>
        {{element.domain}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="publisherAccountId">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Account ID</mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="found(element)">
        <span class="mobile-label">Account ID:</span>
        {{element.publisherAccountId }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="accountType">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Account type</mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="found(element)">
        <span class="mobile-label">Account type:</span>
        {{element.accountType }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="certAuthority">
      <mat-header-cell *matHeaderCellDef>Certification authority</mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="found(element)">
        <span class="mobile-label">Certification authority:</span>
        {{element.certAuthority }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="comment">
      <mat-header-cell *matHeaderCellDef>Comment</mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell--has-sort" [ngClass]="found(element)">
        <span class="mobile-label">Comment:</span>
        {{element.comment}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="demandPartnerName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Demand partner</mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="found(element)">
        <span class="mobile-label">Demand partner name:</span>
        {{demandPartnerDbName[element.demandPartnerName]}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="condensed">
      <mat-header-cell *matHeaderCellDef>Condensed</mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell--has-sort" [ngClass]="found(element)">
        <span class="mobile-label">Condensed:</span>
        <span *ngIf="element.condensed"><i class="far fa-check text-success"></i></span>
        <span *ngIf="!element.condensed"><i class="far fa-times text-muted"></i></span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="priority">
      <mat-header-cell *matHeaderCellDef>Priority</mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell--has-sort" [ngClass]="found(element)">
        <span class="mobile-label">Priority:</span>
        {{element.priority}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="ebda">
      <mat-header-cell *matHeaderCellDef>EBDA</mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell--has-sort" [ngClass]="found(element)">
        <span class="mobile-label">Ebda:</span>
        <span *ngIf="element.ebda"><i class="far fa-check text-success"></i></span>
        <span *ngIf="!element.ebda"><i class="far fa-times text-muted"></i></span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="primary">
      <mat-header-cell *matHeaderCellDef>Primary</mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell--has-sort" [ngClass]="found(element)">
        <span class="mobile-label">Primary:</span>
        <span *ngIf="element.primary"><i class="far fa-check text-success"></i></span>
        <span *ngIf="!element.primary"><i class="far fa-times text-muted"></i></span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="display">
      <mat-header-cell *matHeaderCellDef>Display</mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell--has-sort" [ngClass]="found(element)">
        <span class="mobile-label">Display:</span>
        <span *ngIf="element.display"><i class="far fa-check text-success"></i></span>
        <span *ngIf="!element.display"><i class="far fa-times text-muted"></i></span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="video">
      <mat-header-cell *matHeaderCellDef>Video</mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell--has-sort" [ngClass]="found(element)">
        <span class="mobile-label">Video:</span>
        <span *ngIf="element.video"><i class="far fa-check text-success"></i></span>
        <span *ngIf="!element.video"><i class="far fa-times text-muted"></i></span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="found(element)">
        <span class="mobile-label"></span>
        <button mat-icon-button color="primary"
                (click)="copyRecord(element)">
          <i class="far fa-copy"></i>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columns sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns"></mat-row>
  </mat-table>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-center">
  <button mat-raised-button color="primary" (click)="copyMissing()" class="iconed">
    <i class="fas fa-clipboard"></i>
    <span>Copy Missing records to clipboard</span>
  </button>
  <button mat-raised-button color="primary" (click)="copyAll()" class="iconed">
    <i class="fas fa-clipboard"></i>
    <span>Copy All records to clipboard</span>
  </button>
  <button mat-raised-button color="" class="mr-1" [mat-dialog-close]="false">Close</button>
</mat-dialog-actions>
