import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DemandPartnerAutocompleteComponent} from './demand-partner-autocomplete.component';
import {DemandPartner} from '../../domain/demand-partner.model';
import {DemandPartnersService} from '../../services/demand-partners.service';

@Component({
  selector: 'cs-demand-partners-autocomplete-chips',
  template: `<div class="cs-publisher-autocomplete-chips-wrapper">
    <cs-demand-partner-autocomplete #dp (demandPartnerSelected)="onItemSelected($event)" [formFieldClass]="formFieldClass" [params]="params">
    </cs-demand-partner-autocomplete>
    <mat-chip-list #chipList [ngClass]="stacked ? 'mat-chip-list-stacked' : ''">
      <mat-basic-chip *ngFor="let item of items"
                      [removable]="true"
                      (removed)="remove(item)">
        {{item.displayName}}
        <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
      </mat-basic-chip>
    </mat-chip-list>
  </div>`
})
export class DemandPartnerAutocompleteChipsComponent implements OnInit {
  @ViewChild(DemandPartnerAutocompleteComponent, { static: true }) dp: DemandPartnerAutocompleteComponent;

  @Input()
  public placeholder: string;

  @Input()
  public stacked: false;

  @Input()
  public formFieldClass: string;

  @Input()
  public params: string;

  @Input()
  public demandPartnerIds: string[];

  @Output()
  demandPartnerSelectionChange = new EventEmitter<DemandPartner[]>();

  items: DemandPartner[] = [];

  constructor(private service: DemandPartnersService) { }

  ngOnInit() {
    if (this.demandPartnerIds && this.demandPartnerIds.length) {
      this.service.get(this.params).subscribe(demandPartners => {
        demandPartners.forEach(dp => {
          if (this.demandPartnerIds.includes(dp.name)) {
            this.onItemSelected(dp);
          }
        });
      });
    }
  }

  onItemSelected(partner: DemandPartner) {
    if (partner && !this.items.find(partnerIds => partnerIds.name === partner.name)) {
      this.items.push(partner);
      this.demandPartnerSelectionChange.emit(this.items);
      this.dp.updateFilteredPartners(this.items);
    }
    this.dp.resetInput();
  }

  remove(partner: DemandPartner) {
    this.items.splice(this.items.indexOf(partner), 1);
    this.demandPartnerSelectionChange.emit(this.items);
    this.dp.updateFilteredPartners(this.items);
  }

}
