import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {Page} from '../domain/page.model';
import {HttpUtils} from '../utils/http-utils';
import {DemandPartnerComplianceModel, DpDomainComplianceFilter, DpDomainComplianceModel} from '../domain/demand-partner-compliance.model';
import {PublisherComplianceOverviewFilter, PublisherComplianceOverviewModel} from '../domain/publisher-compliance.model';

@Injectable({
  providedIn: 'root',
})
export class ComplianceService {

  constructor(private httpClient: HttpClient) {
  }

  dpList(): Observable<DemandPartnerComplianceModel[]> {
    return this.httpClient.get<DemandPartnerComplianceModel[]>(`${environment.serviceUrl}/compliance/dp`);
  }

  dpDomainList(filter: DpDomainComplianceFilter): Observable<Page<DpDomainComplianceModel>> {
    return this.httpClient.get<Page<DpDomainComplianceModel>>(`${environment.serviceUrl}/compliance/dp/domains`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  updateApproval(id: number, data): Observable<DpDomainComplianceModel> {
    return this.httpClient.put<DpDomainComplianceModel>(`${environment.serviceUrl}/compliance/approval/${id}`, data);
  }

  exportDpCompliance(demandPartnerName: string) {
    return this.httpClient.get(`${environment.serviceUrl}/compliance/dp/export`, {
      responseType: 'text',
      params: HttpUtils.getHttpParams({demandPartnerName})
    });
  }

  importDpCompliance(demandPartnerName: string, file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.httpClient.post(`${environment.serviceUrl}/compliance/dp/import`, formData, {
      params: HttpUtils.getHttpParams({demandPartnerName})
    });
  }

  publisherList(filter: PublisherComplianceOverviewFilter): Observable<Page<PublisherComplianceOverviewModel>> {
    return this.httpClient.get<Page<PublisherComplianceOverviewModel>>(`${environment.serviceUrl}/compliance/pub`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  complianceApproval(url): Observable<DpDomainComplianceModel[]> {
    return this.httpClient.get<DpDomainComplianceModel[]>(`${environment.serviceUrl}/compliance/url`, {
      params: HttpUtils.getHttpParams({url})
    });
  }

  updateApprovals(data: DpDomainComplianceModel[]) {
    return this.httpClient.post(`${environment.serviceUrl}/compliance/approval/batch`, data);
  }

}
