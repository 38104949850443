import {NgModule} from '@angular/core';
import {DateRangeChooserComponent} from './date-selector/date-range-chooser.component';
import {DateSelectorComponent} from './date-selector/date-selector.component';
import {CoreModule} from '../../core/core.module';
import {ToolbarComponent} from './toolbar/toolbar.component';
import {CsTableColumnCommonService} from './table/cs-table-column-common.service';
import {CurrencyPipe, DatePipe, DecimalPipe} from '@angular/common';
import {PublisherAutocompleteComponent} from './utils/publisher-autocomplete.component';
import {ToolbarPublisherComponent} from './toolbar/toolbar-publisher.component';
import {DeleteDialogComponent} from './dialogs/delete-dialog.component';
import {CsCardFiltersComponent} from './cards/cm-card-filters.component';
import {AppVersionComponent} from './app-version/app-version.component';
import {MatTableColVisComponent} from './table/mat-table-col-vis.component';
import {PublisherAutocompleteChipsComponent} from './utils/publisher-autocomplete-chips.component';
import {AdUnitAutocompleteChipsComponent} from './utils/ad-unit-autocomplete-chips.component';
import {AdUnitAutocompleteComponent} from './utils/ad-unit-autocomplete.component';
import {ReportBuilderPredicatesComponent} from './utils/report-builder-predicates.component';
import { UnitNameSplit } from './unit-name-split.pipe';
import {CustomUploadMenuButtonComponent} from './custom-upload-menu-button.component';
import {DemandPartnerAutocompleteComponent} from './utils/demand-partner-autocomplete.component';
import {TooltipIconComponent} from './tooltip-icon.component';
import {DemandPartnerAutocompleteChipsComponent} from './utils/demand-partner-autocomplete-chips.component';
import {UrlApprovalAutocompleteComponent} from './utils/url-approval-autocomplete.component';
import {WidgetDailyMetricsChartComponent} from './charts/widget-daily-metrics-chart.component';
import {PublisherMultiselectSearchDialogComponent} from './utils/publisher-multiselect-search-dialog.component';
import {PublisherMultiselectSearchComponent} from './utils/publisher-multiselect-search.component';
import {
  CsWidgetActionsComponent,
  CsWidgetComponent,
  CsWidgetContentComponent,
  CsWidgetFooterComponent,
  CsWidgetHeaderCenterComponent,
  CsWidgetTitleComponent
} from './widgets/cs-widget.component';
import {PublisherMultiselectSearchDropdownComponent} from './utils/publisher-multiselect-search-dropdown.component';
import {CsDropdownComponent, CsDropdownTriggerComponent} from './table/cs-dropdown.component';
import {ReportMetricMultiselectSearchDropdownComponent} from './utils/report-metric-multiselect-search-dropdown.component';
import {ReportMetricMultiselectSearchComponent} from './utils/report-metric-multiselect-search.component';
import {WidgetDailyMetricsChartSettingsDialogComponent} from './charts/widget-daily-metrics-chart-settings-dialog.component';
import {ReportMetricSingleselectDropdownComponent} from './utils/report-metric-singleselect-dropdown.component';
import {DateRangeDropdownComponent} from './date-selector/date-range-dropdown.component';
import {CsButtonDropdownComponent} from './table/cs-button-dropdown.component';
import {DateChooserDropdownComponent} from './date-selector/date-chooser-dropdown';
import {DailyMetricsChartComponent} from './charts/daily-metrics-chart/daily-metrics-chart.component';
import {HighchartsChartModule} from 'highcharts-angular';
import {DemandPartnersRevenueChartComponent} from './charts/demand-partners-revenue-chart/demand-partners-revenue-chart.component';
import {UnitTagPreviewDialogComponent} from './dialogs/unit-tag-preview/unit-tag-preview-dialog.component';
import {PartnerAutocompleteComponent} from './utils/partner-autocomplete.component';
import {SearchControlComponent} from './search-control/search-control.component';
import {InformationDialogComponent} from './dialogs/information-dialog.component';
import {ConfirmDialogComponent} from './dialogs/confirm-dialog.component';
import {DomainAdsTxtPreviewDialogComponent} from './dialogs/domain-ads-txt-preview/domain-ads-txt-preview-dialog.component';
import {
  DomainAdsTxtDetailsPreviewDialogComponent
} from './dialogs/domain-ads-txt-details-preview/domain-ads-txt-details-preview-dialog.component';
import {
  AdUnitDpAdsTxtDetailsPreviewDialogComponent
} from './dialogs/ad-unit-dp-ads-txt-details-preview/ad-unit-dp-ads-txt-details-preview-dialog.component';
import {
  AdUnitDomainAdsTxtPreviewDialogComponent
} from './dialogs/ad-unit-domain-ads-txt-preview/ad-unit-domain-ads-txt-preview-dialog.component';
import { IsEllipsisActiveDirective } from './directives/is-ellipsis-active.directive';
import {ReportNameTemplateComponent} from './report-name-template/report-name-template.component';

const exports = [
  DateRangeChooserComponent,
  DateSelectorComponent,
  ToolbarComponent,
  PublisherAutocompleteComponent,
  PublisherAutocompleteChipsComponent,
  AdUnitAutocompleteChipsComponent,
  AdUnitAutocompleteComponent,
  ReportBuilderPredicatesComponent,
  ToolbarPublisherComponent,
  CustomUploadMenuButtonComponent,
  DeleteDialogComponent,
  ConfirmDialogComponent,
  CsCardFiltersComponent,
  AppVersionComponent,
  MatTableColVisComponent,
  UnitNameSplit,
  TooltipIconComponent,
  UnitNameSplit,
  DemandPartnerAutocompleteComponent,
  DemandPartnerAutocompleteChipsComponent,
  UrlApprovalAutocompleteComponent,
  WidgetDailyMetricsChartComponent,
  PublisherMultiselectSearchDialogComponent,
  PublisherMultiselectSearchComponent,
  PublisherMultiselectSearchDropdownComponent,
  CsWidgetComponent,
  CsWidgetContentComponent,
  CsWidgetActionsComponent,
  CsWidgetFooterComponent,
  CsWidgetTitleComponent,
  CsWidgetHeaderCenterComponent,
  CsDropdownComponent,
  CsDropdownTriggerComponent,
  ReportMetricMultiselectSearchDropdownComponent,
  ReportMetricMultiselectSearchComponent,
  WidgetDailyMetricsChartSettingsDialogComponent,
  ReportMetricSingleselectDropdownComponent,
  DateRangeDropdownComponent,
  CsButtonDropdownComponent,
  DateChooserDropdownComponent,
  DailyMetricsChartComponent,
  DemandPartnersRevenueChartComponent,
  UnitTagPreviewDialogComponent,
  PartnerAutocompleteComponent,
  SearchControlComponent,
  InformationDialogComponent,
  DomainAdsTxtPreviewDialogComponent,
  DomainAdsTxtDetailsPreviewDialogComponent,
  AdUnitDpAdsTxtDetailsPreviewDialogComponent,
  AdUnitDomainAdsTxtPreviewDialogComponent,
  IsEllipsisActiveDirective,
  ReportNameTemplateComponent
];

@NgModule({
  declarations: exports,
  imports: [
    CoreModule,
    HighchartsChartModule,
  ],
  providers: [DatePipe, DecimalPipe, CurrencyPipe, CsTableColumnCommonService],
  entryComponents: [
    DeleteDialogComponent,
    ConfirmDialogComponent,
    WidgetDailyMetricsChartSettingsDialogComponent,
    UnitTagPreviewDialogComponent,
    DomainAdsTxtPreviewDialogComponent,
    DomainAdsTxtDetailsPreviewDialogComponent,
    AdUnitDpAdsTxtDetailsPreviewDialogComponent,
    AdUnitDomainAdsTxtPreviewDialogComponent
  ],
  exports: exports
})
export class SharedComponentsModule {
}
