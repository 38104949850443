import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../auth/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>
    | Promise<boolean>
    | boolean {
    console.log('auth guard :: canActivate');
    return new Observable(subscriber => {
      this.authService.identity(true)
        .subscribe(
          user => {
            subscriber.next(true);
            subscriber.complete();
          },
          (error) => {
            console.log(false)
            console.error(error);
            subscriber.next(false);
            subscriber.complete();
          }
        );
    });
  }
}
