import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {DateRangeType} from '../../domain/rb/date-range-type.enum';
import {DateRangeHolder} from '../../domain/rb/date-range-holder.model';

@Component({
  selector: 'cs-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
})
export class DateSelectorComponent implements OnInit {
  @Input()
  public dateFrom;
  @Input()
  public dateTo;
  @Input()
  public maxDate;
  @Input()
  public minDate;
  @Input()
  public allowedDateRangeTypes: DateRangeType[] = <DateRangeType[]>Object.keys(DateRangeType);
  @Input()
  public maxRangeBetweenDates: number;
  @Input()
  public dateChooserHover: boolean;
  @Input()
  public innerClass: string;

  @Output()
  dateRangeChanged = new EventEmitter<DateRangeHolder>();
  dateRangeType: DateRangeType;

  public UI = {
    dateChooserOpen: false
  };

  constructor(private myElement: ElementRef) {
  }

  ngOnInit(): void {
  }

  onDateRangeChanged(event: DateRangeHolder) {
    this.dateFrom = event.dateFrom;
    this.dateTo = event.dateTo;
    this.dateRangeChanged.emit(event);
    this.hide();
  }

  isPrevDayEnabled() {
    return !this.minDate || this.minDate < this.dateFrom;
  }

  isNextDayEnabled() {
    return !this.maxDate || this.maxDate > this.dateTo;
  }

  private hide() {
    this.UI.dateChooserOpen = false;
  }

  @HostListener('document:click', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    const overlayElements = document.getElementsByClassName('cdk-overlay-container');
    const matCalendar = document.getElementsByClassName('mat-calendar');
    const datepickerCell = (<Element>e.target).className === 'mat-calendar-body-cell-content';

    const insideOverlay = overlayElements.length && overlayElements[0].contains(<Node>e.target);
    const insidePopup = matCalendar.length && matCalendar[0].contains(<Node>e.target);
    const insideWidget = this.myElement.nativeElement.contains(e.target);

    if (!insideWidget && !insideOverlay && !insidePopup && !datepickerCell) {
      this.hide();
    }
  }
}
