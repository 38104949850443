import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {AfterViewChecked, Component, HostBinding, Inject} from '@angular/core';

@Component({
  selector: 'cs-information-dialog',
  template: `
    <h1 mat-dialog-title>
      {{data && data.headerText || 'Message'}}
    </h1>
    <div mat-dialog-content class="mb-4">
        {{data?.message}}
    </div>
    <div mat-dialog-actions class="d-flex justify-content-end">
      <button mat-raised-button mat-dialog-close cdkFocusInitial>Close</button>
    </div>
  `,
})
export class InformationDialogComponent implements AfterViewChecked {
  constructor(public dialogRef: MatDialogRef<InformationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngAfterViewChecked() {
    this.dialogRef.updateSize('500px');
  }


}
